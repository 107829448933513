import React from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import PRoute from './Routes/PRoute';
import './App.css';
import './index.css';

const App = () => {
  return (
    <Router>
      <PRoute/>
    </Router>
  );
}

export default App;
