import React from "react";
import Discuss from "../Components/Discuss";
import Footer from "../Components/Footer";
import Header from "../Components/Header";

const Ourwork = () => {
  return (
    <div className="tw-overflow-hidden ourwork">
      <Header />
      <div className="bgaboutus tw-flex tw-flex-wrap md:tw-flex-nowrap">
        <div className="tw-flex-auto tw-w-full md:tw-w-5/12 tw-mt-24 md:tw-mt-48 md:tw-ml-12 tw-ml-3">
          <h4 className="txt-blue poppins tw-font-extrabold tw-text-3xl md:tw-text-6xl tracking-wide">
            OUR WORK
          </h4>
          <h5 className="tw-text-slate-100 poppins tw-text-md md:tw-text-2xl tw-font-normal tw-mt-5">
            Our work spealks more than we do. dont miss to see our work
            portfolio below
          </h5>
          <button className="tw-text-base md:tw-text-lg tw-border-2 tw-border-white tw-text-white tw-rounded-full tw-py-2 md:tw-py-4 tw-px-4 md:tw-px-12 tw-mt-8 md:tw-mt-24 tw-cursor-pointer">
            KNOW MORE
          </button>
        </div>
        <div className="tw-flex-auto tw-w-full md:tw-w-7/12 boxemployees"></div>
      </div>
      <div className="tw-flex tw-h-fit">
        <div className=" tw-w-4/12  tw-relative">
          <span className="yellowneuron"></span>
        </div>
        <div className="tw-w-8/12">
          <div
            className="tw-flex-auto tw-self-center tw-text-center md:tw-text-end tw-text-white tw-poppins textOurservices text-outlineB
       tw-font-extrabold tw-poppins tw-mt-6 tw-tracking-wide"
          >
            Our Recent Work
          </div>
          <div
            className=" tw-text-end tw-text-slate-600
      tw-poppins tw-mt-4 tw-font-semibold tw-text-lg md:tw-text-4xl tw-mr-4 md:tw-mr-28 2xl:tw-mr-72 2xl:tw-font-bold"
          >
            DONE. DELIVERED. APPLAUD
          </div>
          <div className="tw-flex tw-justify-between tw-mr-2 md:tw-mr-0 tw-mt-12 md:tw-mt-24">
            <div className="  tw-text-slate-600 poppins tw-mt-4 tw-font-normal tw-text-lg md:tw-text-3xl  2xl:tw-font-medium">
              Categories
            </div>
            <div className="  tw-text-slate-600 poppins tw-mt-4 tw-font-normal tw-text-lg md:tw-text-3xl  2xl:tw-font-medium">
              Total Projects : 101
            </div>
          </div>
          <div className="tw-flex tw-mt-12 ">
            <div className="tw-w-4/12 tw-mr-5">
              <button className="poppins  tw-text-slate-600  tw-text-sm md:tw-text-2xl tw-w-full   tw-py-2 md:tw-py-3 tw-px-2 md:tw-px-12 tw-bg-slate-300  tw-rounded-2xl hover:tw-bg-yellow-200">
                {" "}
                Travel
              </button>
            </div>
            <div className="tw-w-4/12 tw-mr-5">
              <button className="poppins  tw-text-slate-600  tw-text-sm md:tw-text-2xl tw-w-full   tw-py-2 md:tw-py-3 tw-px-2 md:tw-px-12 tw-bg-slate-300  tw-rounded-2xl hover:tw-bg-yellow-200">
                {" "}
                Finance
              </button>
            </div>
            <div className="tw-w-4/12 tw-mr-5">
              <button className="poppins  tw-text-slate-600  tw-text-sm md:tw-text-2xl tw-w-full   tw-py-2 md:tw-py-3 tw-px-2 md:tw-px-12 tw-bg-slate-300  tw-rounded-2xl hover:tw-bg-yellow-200">
                {" "}
                Ecommerce
              </button>
            </div>
            <div className="tw-w-4/12 ">
              <button className="poppins  tw-text-slate-600  tw-text-2xl tw-w-full   tw-py-3 tw-px-12 tw-bg-slate-300  tw-rounded-2xl hover:tw-bg-yellow-200">
                {" "}
                UI / UX
              </button>
            </div>
          </div>
        </div>
      </div>
      <section className="tw-mt-5 carouselh tw-flex tw-bg-slate-200">
        <div className="tw-w-5/12 tw-bg-slate-400 tw-rounded-lg tw-m-5">
          <div class="">
            <div
              id="carouselExampleControls"
              class="carousel slide"
              data-bs-ride="carousel"
            >
              <div class="carousel-inner">
                <div class="carousel-item active"></div>
                <div class="carousel-item "></div>
              </div>
              <button
                class="carousel-control-prev  tw-z-20"
                type="button"
                data-bs-target="#carouselExampleControls"
                data-bs-slide="prev"
              >
                <span class="carousel-control-prev-icon "></span>
                {/* <span class="carousel-control-prev-icon" aria-hidden="true"></span> */}
                <span class="visually-hidden">Previous</span>
              </button>
              <button
                class="carousel-control-next  tw-z-20"
                type="button"
                data-bs-target="#carouselExampleControls"
                data-bs-slide="next"
              >
                <span class="carousel-control-next-icon"></span>
                {/* <span class="carousel-control-next-icon" aria-hidden="true"></span> */}
                <span class="visually-hidden">Next</span>
              </button>
            </div>
          </div>
        </div>
        <div className="tw-w-7/12  md:tw-mt-24 tw-ml-6">
          <p className="tw-text-base md:tw-text-2xl tw-font-normal tw-mb-2 md:tw-mb-4">
            Product : Travel B2C Engine{" "}
          </p>
          <p className="tw-text-base md:tw-text-2xl tw-font-normal tw-mb-2 md:tw-mb-4">
            Website : https://www.roadtomakkah.com
          </p>
          <p className="tw-text-base md:tw-text-2xl tw-font-normal tw-mb-2 md:tw-mb-4">
            Technologies : React , PWA, Dotnet Core,
          </p>
          <p className="tw-text-base md:tw-text-2xl tw-font-normal tw-mb-2 md:tw-mb-4">
            Integrations : Sabre, Hotelbeds, bookalimo, Riskified, Stripe
          </p>
        </div>
      </section>

      <Discuss/>
      <Footer />
    </div>
  );
};

export default Ourwork;
