import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../Pages/Home";
import Homie from "../Pages/Homie";
import AboutUS from "../Pages/AboutUs";
import Itconsulting from "../Pages/Itconsulting";
import Ourwork from "../Pages/Ourwork";
import PrivacyPolicy from "../Pages/PrivacyPolicy";
import Tandc from "../Pages/Tandc";
import Itservices from "../Pages/Itservices";
import Itsolutions from "../Pages/Itsolutions";
import Itsupport from "../Pages/Itsupport";
import Careers from "../Pages/Careers";
import Traveltechnology from "../Pages/TravelTechnology";

const PRoute = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Homie />} />
        <Route path="/Aboutus" element={<AboutUS />} />
        <Route path="/Itconsulting" element={<Itconsulting />} />
        <Route path="/Itservices" element={<Itservices />} />
        <Route path="/Itsolutions" element={<Itsolutions />} />
        <Route path="/Itsupport" element={<Itsupport />} />
        <Route path="/Ourwork" element={<Ourwork />} />
        <Route path="/Careers" element={<Careers />} />
        <Route path="/Traveltechnology" element={<Traveltechnology />} />
        <Route path="/h" element={<Home />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/tandc" element={<Tandc />} />
      </Routes>
    </>
  );
};

export default PRoute;
