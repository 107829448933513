import React  from "react";
import Footer from "../Components/Footer";
import Header from "../Components/Header";

const Tandc = () => {
  return (
    <div className="tw-overflow-hidden ourwork">
      <Header />
      <div className="tw-mt-36 tw-mx-5">
        <p className="tw-text-bold tw-text-3xl tw-mb-5 tw-uppercase">
          Terms of Service
        </p>
        <p className="rubik tw-mb-4">
          Jnana Software Technologies is an infrastructural management Bangalore
          Based IT Company providing both on premise and off premise solutions,
          and is operated from our registered office based in Building #6, 1st
          floor, Bhoopasandra Main Rd, Sanjaynagar, Bengaluru, Karnataka 560094.
          We cater to all kinds of Software Solutions, Infrastructural solutions
          including server hardware, shared hosting, virtual server, dedicated
          server, application hosting, mailing solutions, etc. We cater to a
          variety of MSME’s worldwide.
        </p>
        <p className="tw-text-bold tw-text-3xl tw-mb-5 tw-uppercase">
          Terms and Conditions
        </p>
        <p className="rubik tw-mb-2">
          Jnana Software Technologies is an infrastructural management Bangalore
          Based IT Company providing both on premise and off premise solutions,
          and is operated from our registered office based in Building #6, 1st
          floor, Bhoopasandra Main Rd, Sanjaynagar, Bengaluru, Karnataka 560094.
          We cater to all kinds of Software Solutions, Infrastructural solutions
          including server hardware, shared hosting, virtual server, dedicated
          server, application hosting, mailing solutions, etc. We cater to a
          variety of MSME’s worldwide.
        </p>
        <p className="rubik tw-mb-2">
          The following terms constitute an agreement between you, the “Client”/
          “account holder” and Jnana Software Technologies Private Limited.
          (“Jnana Software Technologies”). These Terms of Service govern your
          use of the services provided by Jnana Software Technologies (the
          “Services”).
        </p>
        <p className="rubik tw-mb-2">
          By using the services, you signify that you agree to these terms of
          service, including your agreement to transact with us electronically,
          consent to the information retention and disclosure practices stated
          in the AUP and your consent to resolve in Bangalore, India any dispute
          that you may have with us, our suppliers, relating to this Agreement
          or the Services. Please note that we offer the Services “AS IS” and
          without any warranties.
        </p>
        <p className="rubik tw-mb-4">
          These Terms of Service govern your relationship with us and our
          suppliers. We may change these Terms of Service at any time, as we
          deem appropriate, without prior notice. If we make changes that we
          believe will have a material impact on your use of the Services, we
          will let you know by sending you an email at the current email address
          available with us if any and by posting notice thereof on our website.
          If you disagree with the changes to these Terms of Service, you are
          requested to discontinue your use of the Services. Your ongoing use of
          any Services after the changes take effect shall be construed to
          signify your agreement to the new terms. We encourage you to review
          these Terms of Service regularly.
        </p>

        <p className="tw-text-bold tw-text-3xl tw-mb-5">
          PROHIBITED CONTENT & CONSENT
        </p>
        <p className="tw-text-semibold rubik tw-mb-2">
          1. Belongs to another person and to which the Client does not have any
          right to;
        </p>
        <p className="tw-text-semibold rubik tw-mb-2">
          2. is grossly harmful, harassing, blasphemous, defamatory, obscene,
          pornographic, pedophilic, libelous, invasive of another’s privacy,
          hateful, or racially, ethnically objectionable, disparaging, relating
          or encouraging money laundering or gambling, or otherwise unlawful in
          any manner whatsoever;
        </p>
        <p className="tw-text-semibold rubik tw-mb-2">
          3. Harm minors in any way;
        </p>
        <p className="tw-text-semibold rubik tw-mb-2">
          4. Contains fraudulent information or makes fraudulent offers of items
          or involve the sale or attempted sale of counterfeit or stolen items
          or Illegal/Prohibited items or items whose sales and/or marketing is
          prohibited by applicable law, or otherwise promote other illegal
          activities;
        </p>
        <p className="tw-text-semibold rubik tw-mb-2">
          5. Infringes any patent, trademark, copyright or other proprietary
          rights of any party;
        </p>
        <p className="tw-text-semibold rubik tw-mb-2">
          6. violates any law for the time being in force;
        </p>
        <p className="tw-text-semibold rubik tw-mb-2">
          7. is a part of a scheme to defraud other User(s) of the Site or for
          any other unlawful purpose;
        </p>
        <p className="tw-text-semibold rubik tw-mb-2">
          8. communicates any information which is grossly offensive or menacing
          in nature;
        </p>
        <p className="tw-text-semibold rubik tw-mb-2">
          9. Relates to sale of products or services that infringe or otherwise
          abet or encourage the infringement or violation of any third party’s
          copyright, patent, trademarks, trade secrets or other proprietary
          right or rights of publicity or privacy, or any other third-party
          rights;
        </p>
        <p className="tw-text-semibold rubik tw-mb-2">
          10. Impersonates another person;
        </p>
        <p className="tw-text-semibold rubik tw-mb-2">
          11. Contains software viruses or any other computer code, files or
          programs designed to interrupt, destroy or limit the functionality of
          any computer resource; and/or codes that have the effect of damaging,
          interfering with, intercepting or expropriating any software or
          hardware system, data or personal information;
        </p>
        <p className="tw-text-semibold rubik tw-mb-2">
          12. Contains any material that constitutes unauthorized advertising or
          harassment (including but not limited to spamming), invades anyone’s
          privacy or encourages conduct that would constitute a criminal
          offense, give rise to civil liability, or otherwise violate any law or
          regulation;
        </p>
        <p className="tw-text-semibold rubik tw-mb-2">
          13. Threatens the unity, integrity, defense, security or sovereignty
          of India, friendly relations with foreign states, or public order or
          causes incitement to the commission of any cognizable offence or
          prevents investigation of any offence or is insulting any other
          nation;
        </p>
        <p className="tw-text-semibold rubik tw-mb-2">
          14. Is in contravention of the Information Technology Act, 2000 and
          any other law for the time being in force;
        </p>
        <p className="tw-text-semibold rubik tw-mb-2">
          15. Opens links directly or indirectly to or include descriptions of
          goods or Services that are prohibited under the prevailing law; or
        </p>
        <p className="tw-text-semibold rubik tw-mb-4">
          16. Otherwise creates any liability or adverse publicity for Jnana
          Software Technologies.
        </p>
        <p className="tw-text-bold tw-text-3xl tw-mb-5">ORDER PROCESSING</p>
        <p className="tw-text-semibold rubik tw-mb-4">
          We keep a log of the IP from which the order while an order is made to
          Jnana Software Technologies. The Clients are requested to register
          with us from the client area so we can link all services opted for. We
          do not verify the details of the input from the client as it is only
          used for communicating with the customer or prospective customer.
        </p>
        <p className="tw-text-bold tw-text-3xl tw-mb-5">
          JNANA SOFTWARE TECHNOLOGIES RESPONSIBILITIES
        </p>
        <p className="tw-text-semibold rubik tw-mb-4">
          Jnana Software Technologies agrees to render services, which are paid
          for in advance by the Client, Client subject to complete compliance
          with the AUP and these Terms of Service. Jnana Software Technologies
          reserves the right to refuse the provision of Services to any
          potential Client and/or to deny renewal of Services to any existing
          Client, at its sole discretion.
        </p>
        <p className="tw-text-bold tw-text-3xl tw-mb-5">
          CHANGES TO TERMS OF SERVICE AND AUP
        </p>
        <p className="tw-text-semibold rubik tw-mb-4">
          Jnana Software Technologies ‚ Terms of Service and AUP are both
          subject to change at any time and without notice at the sole
          discretion of Jnana Software Technologies. A change in policy shall
          not be grounds for early contract termination or non-payment. Client
          recognizes that the nature of the service supplied and the initial
          rates and charges have been communicated to the Client. The Client is
          aware that from time to time rates may change based on availability of
          hardware, overall market conditions or other factors. Clients will be
          notified of any increases in rates or charges prior to the billing
          renewal date on which such increases will take effect.
        </p>
        <p className="tw-text-bold tw-text-3xl tw-mb-5">
          NON-PAYMENT/SERVICE INTERRUPTION
        </p>
        <p className="tw-text-semibold rubik tw-mb-4">
          NON-PAYMENT/SERVICE INTERRUPTION Services interrupted for non-payment
          may be subject to a penalty up-to Rs.1000/– as late fee. Data stored
          on a Client’s services will not be available to the Client until
          reconnection is established or alternative arrangements are made to
          the sole satisfaction of Jnana Software Technologies. Clients’
          accounts deactivated for non-payment or charge-back are subject to
          their data being destroyed seven (10) days from suspension/charge-back
          date. Jnana Software Technologies is not responsible for data
          integrity, in such circumstances. Jnana Software Technologies strongly
          recommends that the Client should keep up to date and off network
          backups to protect against data loss.
        </p>
        <p className="tw-text-bold tw-text-3xl tw-mb-5">REFUNDS/CHARGE-BACKS</p>
        <p className="tw-text-semibold rubik tw-mb-4">
          No Services rendered by Jnana Software Technologies are eligible for a
          refund. Prepayments and account credit are ineligible to be refunded,
          or transferred to alternate accounts. Any and all charge disputes must
          be reported directly to Jnana Software Technologies within thirty (30)
          days of the date which the charge originally occurred. If a charge
          which is deemed valid by Jnana Software Technologies , and validated
          by our Terms of Service or AUP, is disputed to a financial institution
          by performing a charge-back, then the Client agrees to pay an
          ‘Administrative Fee’ of Rs.15000/- in addition to original amount of
          funds which were reclaimed.
        </p>
        <p className="tw-text-bold tw-text-3xl tw-mb-5">SUSPENSIONS</p>
        <p className="tw-text-semibold rubik tw-mb-4">
          In the event a service is suspended for non-payment, it will be
          subject to termination seven (10) days from the time of suspension. At
          which time, a termination fee of up-to Rs.1000/– will be applied to
          the account which must be paid within 15 days to avoid full account
          suspension. Jnana Software Technologies assumes no liability for the
          integrity of the data stored on a suspended server.
        </p>
        <p className="tw-text-bold tw-text-3xl tw-mb-5">CANCELLATIONS</p>
        <p className="tw-text-semibold rubik tw-mb-4">
          Jnana Software Technologies requires a thirty (30) days’ notice of
          cancellation prior to the billing renewal date for the upcoming
          billing cycle, submitted via support ticket. A thirty (30) days’
          notice before the upcoming billing date is also required for any
          downgrades. All Client data will be destroyed immediately after the
          cancellation date. If the notice of cancellation is not provided
          within thirty (30) days, the server will still be canceled however a
          termination fee of Rs.1000/– will be applied to the account and must
          be paid within 15 days to avoid full account suspension.
        </p>
        <p className="tw-text-bold tw-text-3xl tw-mb-5">FRAUD</p>
        <p className="tw-text-semibold rubik tw-mb-4">
          In the event of allegations of fraud by third parties against Jnana
          Software Technologies or against the Client attributable to any
          account held or operated by a Client, being made known to Jnana
          Software Technologies, the fraudulent account, and all related
          accounts, are subject to immediate suspension or termination at the
          sole discretion of Jnana Software Technologies. All information
          available with Jnana Software Technologies about the fraudulent
          account/service shall be submitted to local authorities, financial
          institutions or any police or Government authority.
        </p>
        <p className="tw-text-bold tw-text-3xl tw-mb-5">
          DISCLAIMERS OF LIABILITY; INDEMNIFICATION
        </p>
        <p className="tw-text-semibold rubik tw-mb-2">
          We provide the services on “as is”, “with all faults” and “as
          available” basis and we and our suppliers make no express or implied
          warranties or guarantees about the services. To the extent permitted
          by law, we and our suppliers disclaim implied warranties that the
          services are merchantable, of satisfactory quality, accurate, fit for
          a particular purpose or need, or non-infringing. We and our suppliers
          do not guarantee that the results that may be obtained from the use of
          the services will be effective, reliable, and accurate or meet your
          requirements. We do not guarantee that you will be able to access or
          use the services (either directly or through third-party networks) at
          times or locations of you are choosing.
        </p>
        <p className="tw-text-semibold rubik tw-mb-2">
          Your sole and exclusive remedy for any dispute with us or our
          suppliers is the cancellation of your account. In no event shall our,
          our affiliates’ and our suppliers’ aggregate and cumulative liability
          to you for any and all claims relating to the use of the services
          exceed the total amount of fees, if any, that you paid during the
          period during which such claims arose. We, our affiliates, and our
          suppliers shall not be liable for any indirect, special, incidental,
          consequential or exemplary damages arising from your use of or
          inability to use the services. These exclusions apply to any claims
          for lost profits, lost data, loss of goodwill, work stoppage, computer
          failure or malfunction, or any other commercial damages or losses,
          even if we knew or should have known of the possibility of such
          damages.
        </p>

        <p className="tw-text-semibold rubik tw-mb-4">
          You agree to defend, indemnify, and hold harmless us, our employees,
          contractors, officers, directors, agents, affiliated companies, and
          suppliers, from all liabilities, period during which such claims
          arose. We, our affiliates, and our suppliers shall not be liable for
          any indirect, special, incidental, consequential or exemplary damages
          arising from your use of or inability to use the services. These
          exclusions apply to any claims for lost profits, lost data, loss of
          goodwill, work stoppage, computer failure or malfunction, or any other
          commercial damages or losses, even if we knew or should have known of
          the possibility of such damages.
        </p>
        <p className="tw-text-bold tw-text-3xl tw-mb-5">
          DISCLAIMERS OF LIABILITY; INDEMNIFICATION
        </p>
        <p className="tw-text-semibold rubik tw-mb-4">
          Jnana Software Technologies provides hardware support related to each
          direct Client’s service functioning. Jnana Software Technologies does
          not offer software support of any kind. Jnana Software Technologies
          does not provide software support/troubleshooting for the software
          items chosen from the order form. Jnana Software Technologies only
          ensures the correct default installation of any software item chosen
          from the order form, and in no way assumes liability for the
          configuration of any of the installed software. Jnana Software
          Technologies is not responsible for any downtime associated with the
          incorrect configuration of operating system kernels or any software,
          whether installed by Jnana Software Technologies or the Client. Jnana
          Software Technologies may provide enhanced software support (including
          kernel configuration) for an additional fee. Please contact sales or
          support for enhanced support pricing. Jnana Software Technologies will
          only provide support directly to Clients of Jnana Software
          Technologies.
        </p>
        <p className="tw-text-bold tw-text-3xl tw-mb-5">
          ABUSE/SPAM/BULK EMAIL POLICY
        </p>
        <p className="tw-text-semibold rubik tw-mb-2">
          Jnana Software Technologies reserves the right to deny mail delivery
          from any servers hosted on our network if they are believed to be
          involved in SPAM or SPIM activities. This includes spam support
          services such as DNS or spamvertised web sites. Our abuse department
          will locate abusive servers based on public blacklist monitors, abuse
          reporting from external networks, and other means. If our abuse
          department receives a report or otherwise becomes aware of
          abuse-related activities active on our network, we will identify the
          server the abuse was initially sent from (via IP and in some cases by
          domain) and create a ticket on behalf of the user who occupies the
          server with information regarding the abuse such as logs describing
          the abuse and an explanation of what abuse took place. The following
          actions will be taken on a case-by-case basis:
        </p>
        <p className="tw-text-semibold rubik tw-mb-2">
          1. An abuse ticket serves as notification that our abuse department
          has received reports of abuse originating from the server. We require
          a Client response to ALL abuse tickets within 48 hours of the ticket
          being opened. Typically, no service will be suspended or filtered
          within the first 48 hours of an abuse ticket being opened. If a
          response is not received within 48 hours, or the server is determined
          to be abusive (or likely to be abusive in the case of spam
          backlisting) after the ticket is opened, our abuse technicians may
          filter or disable ports or IPs assigned to the server temporarily to
          prevent further abuse until a response is received.
        </p>
        <p className="tw-text-semibold rubik tw-mb-2">
          2. If a response has not been received to an abuse ticket within 7
          days of being opened, the server is eligible for suspension and may be
          completely disabled until we receive a response.
        </p>
        <p className="tw-text-semibold rubik tw-mb-2">
          3. Habitual neglect of abuse occurring on a Client’s server may lead
          to service termination or longer-term port filters as some
          blacklisting can take several weeks to time out or be removed.
        </p>
        <p className="tw-text-semibold rubik tw-mb-2">
          Jnana Software Technologies reserves the right to refuse services to
          any Client whose account(s) have been fined or terminated for
          abuse–related activities. Compromised servers issued abuse
          notifications: If our abuse department suspects that abuse reports
          associated with a Client‚s server are a result of the server’s
          security being compromised, our abuse department will offer the
          following options:
        </p>
        <p className="tw-text-semibold rubik tw-mb-2">
          1. At the discretion of our abuse department, we will typically offer
          the ability to log in to the server and remedy the security compromise
          while leaving the abusive traffic filtered.
        </p>
        <p className="tw-text-semibold rubik tw-mb-2">
          2. Offer an Operating System reinstallation, setting the configuration
          back to the original state it was provisioned in.
        </p>
        <p className="tw-text-semibold rubik tw-mb-2">
          3. If Operating System reinstallation is not accepted as an option,
          Jnana Software Technologies may offer to manually retrieve/repair the
          files on the server, at a fee to be assessed and paid prior to any
          work being done.
        </p>
        <p className="tw-text-semibold rubik tw-mb-2">
          4. Habitual security compromises that result in abusive traffic being
          transmitted or received from the server may result in service
          termination.
        </p>

        <p className="tw-text-semibold rubik tw-mb-4">
          If a Client wishes to dispute any abuse reports, or any fine assessed
          in connection with abuse reports or blacklisting, the Client must
          provide Jnana Software Technologies with an explanation via WHMCS’s
          ticketing system within 10 days of the abuse ticket being opened.
        </p>
        <p className="tw-text-bold tw-text-3xl tw-mb-5">LEGAL COMPLIANCE</p>
        <p className="tw-text-semibold rubik tw-mb-2">
          The Client agrees that Jnana Software Technologies shall not be liable
          for the content posted, shared, stored or otherwise made available on
          the website by the Client through its account. Any claims of
          infringement arising from Client generated material/ information shall
          be directed to the Client/ account holder. The Client/ account holder
          additionally agrees to indemnify and hold harmless Jnana Software
          Technologies from any claims, dispute, settlement, loss or damage
          resulting from such claims whether by third parties or by servants,
          employees, associates, agents or other persons claiming from the
          Client.
        </p>
        <p className="tw-text-semibold rubik tw-mb-4">
          Jnana Software Technologies shall comply with all requirements laid
          down under the Copyright Act, 2012, the Information Technology Act,
          2000 or any other applicable legislation and requirements thereunder
          when in conflict with the interests of the Client and the same shall
          supersede provisions of the present Agreement. In compliance with
          legal obligations of Jnana Software Technologies , any request or
          instruction for information required to be shared with or provided to
          or any action required to be taken, including but not limited to
          removal of content, prevention of access or blacklisting of Client, by
          any government authority, court of competent jurisdiction or
          regulatory body shall be complied with. Client agrees not to make any
          claims against Jnana Software Technologies , its servants, agents,
          employees or any person claiming thereunder for any loss, injury,
          damage sustained by the Client.
        </p>
        <p className="tw-text-bold tw-text-3xl tw-mb-5">
          BANDWIDTH USAGE/OVERAGES
        </p>
        <p className="tw-text-semibold rubik tw-mb-4">
          Clients agree to pay any and all bandwidth overage charges accrued on
          their account from the previous billing cycle. Bandwidth overage
          charges are billed per gigabyte at a rate of Rs.20/– over the allotted
          bandwidth on the service. Jnana Software Technologies reserves the
          right to suspend any account which does not pay bandwidth overage
          charges within 15 days. Jnana Software Technologies is not responsible
          for spikes in bandwidth that are caused on a Client’s service for any
          reason. Client assumes liability for all bandwidth to and from their
          services. If issued an expected to exceed bandwidth notification,
          Clients are required to make payment arrangements within 24 hours of
          that notification to prevent possible service interruption until
          adequate arrangements are made. These arrangements may include being
          required to pro-actively upgrade bandwidth.
        </p>
        <p className="tw-text-bold tw-text-3xl tw-mb-5">PAYMENT VERIFICATION</p>
        <p className="tw-text-semibold rubik tw-mb-4">
          In certain cases, if requested by Jnana Software Technologies, Clients
          agree to submit to identity verification measures, designed for both
          the security of the Client as well as the security of Jnana Software
          Technologies. The identity verification measures may include the
          emailing up-to two forms of government issued identification to Jnana
          Software Technologies, as well as a front and back copy of the credit
          card used for payment, as well as a copy of a utility bill with the
          billing address. In certain circumstances additional identity
          verification may be required, in addition to the previously listed
          forms.
        </p>
        <p className="tw-text-bold tw-text-3xl tw-mb-5">Transaction Disputes</p>
        <p className="tw-text-semibold rubik tw-mb-2">
          Transactions may be disputed anytime within up to 120 (one hundred
          twenty) days, from the date of transaction by a buyer, as per the Card
          Payment Network Rules. Disputes resolved in favour of a buyer may
          result in reversal of payment to such buyer ("CHARGEBACK"). In the
          event of rejection/ suspension of payments to the seller, chargebacks,
          refunds and/or any other dispute relating to the transactions
          contemplated under these Terms of Use ("DISPUTED TRANSACTION"), on any
          grounds whatsoever, we will forthwith notify the seller of the same.
        </p>
        <p className="tw-text-semibold rubik tw-mb-2">
          On such notification the seller will conduct an internal review of
          such matter and will, within 5 (five) working days from receipt of
          notification, respond to us in writing either:
        </p>
        <p className="tw-text-semibold rubik tw-mb-2">
          Requesting us to refund, Refund Request the payment received by the
          seller in respect of such Disputed Transaction Refund Monies; or
        </p>
        <p className="tw-text-semibold rubik tw-mb-2">
          Providing us with a statement explaining how the Disputed Transaction
          is not warranted, together with all documentary evidence in support of
          contesting such Disputed Transaction.
        </p>
        <p className="tw-text-semibold rubik tw-mb-2">
          All refunds shall be made to the original method of payment. In the
          event that the seller provides a Refund Request to us or fails to
          contest such Disputed Transaction within the aforesaid 5 (five)
          working days or contests Disputed Transaction without providing
          supporting documentation to us, payment service providers, Card
          Payment Network and/ or issuing institution's satisfaction, we will be
          entitled to recover the Refund Monies from credits subsequently made
          to the escrow account with respect to payments made by the seller's
          buyers.
        </p>
        <p className="tw-text-semibold rubik tw-mb-4">
          In the event that we are unable to recover the Refund Monies as
          aforesaid, due to the amounts credited to the escrow account being
          lower than the Refund Monies, Razorpay shall be entitled to recover
          such Refund Monies (or any part thereof) from the User by (i) raising
          a debit note in respect of such monies; and/ or (ii) setting-off the
          remaining Refund Monies against the future payables to the seller and
          refund the same to the respective buyers. The seller will be liable to
          make payment of the Refund Monies or part thereof which has not been
          recovered by us forthwith. It is hereby agreed and acknowledged by the
          parties that the Fees charged by us in respect of the Disputed
          Transaction will not be refunded or repaid by us to the seller, buyer
          or any other person. Further, the Chargeback will be provided within 1
          (one) week of the transaction and maximum amount of the Chargeback
          payable by Razorpay to the buyer will be the value of the transaction
          only.
        </p>
        <p className="tw-text-bold tw-text-3xl tw-mb-5">
          Technical Issues & Delivery Policy
        </p>
        <p className="tw-text-semibold rubik tw-mb-4">
          In case of any technical issues, please raise a support ticket from
          your service dashboard or by emailing us at info@jnanasoft.com to let
          us know of the same. We endeavour to deliver Service to you within 15
          (fifteen) working days of bank approval, failing which you can
          terminate a transaction related to Service at any time and get a full
          refund.
        </p>
        <p className="tw-text-bold tw-text-3xl tw-mb-5">
          Governing Law, Settlement of Disputes and Jurisdiction
        </p>
        <p className="tw-text-semibold rubik tw-mb-2">
          Jnana Software Technologies keeps all Client information private and
          will not publicly disclose that they are providing services to the
          Client unless permission is received on a case-by-case basis. Jnana
          Software Technologies reserves the right to use Client quotes for
          promotional uses. Such quotes will be anonymous unless the Client
          agrees to disclosure of their name.
        </p>
        <p className="tw-text-semibold rubik tw-mb-4">
          All communications from Jnana Software Technologies and the Client are
          strictly confidential and for the intended use of the addressee only.
          Any disclosure, use or copying of the information by anyone other than
          the intended recipient is prohibited and you agree to be held liable
          for damages if you violate this agreement, forward the information,
          post it or allow it to be posted online in public or private forums or
          venues.
        </p>
        <p className="tw-text-bold tw-text-3xl tw-mb-5">
          ELECTRONIC NOTIFICATIONS
        </p>

        <p className="tw-text-semibold rubik tw-mb-4">
          Your affirmative act of using the Services constitutes your electronic
          signature to these Terms of Service and your consent to enter into
          agreements with us electronically. You agree that we may send to you
          in electronic form any privacy or other notices, disclosures, reports,
          documents, communications or other records regarding the Services
          (collectively, “Notices”). We can send you electronic Notices to the
          e–mail address that you provided to us during registration. Delivery
          of any Notice from us is effective when sent by us, regardless of
          whether you read the Notice when you receive it or whether you
          actually receive the delivery. You can withdraw your consent to
          receive Notices electronically by canceling or discontinuing your use
          of the applicable Services. In order to receive Notices
          electronically, you must have a personal computer with a modem
          connected to a communications source (telephone, wireless or
          broadband), and a Windows-based or a Macintosh-based operating system
          with an Internet browser. You will need a printer attached to your
          personal computer to print any Notices. You can retrieve an electronic
          copy and a printable version of this contract by clicking on the
          “Terms of Service” link on any web page that hosts any of the
          Services. All contracts completed electronically will be deemed for
          all legal purposes to be in writing and legally enforceable as a
          signed writing.
        </p>
        <p className="tw-text-bold tw-text-3xl tw-mb-5">ENTIRE AGREEMENT</p>

        <p className="tw-text-semibold rubik tw-mb-4">
          This contract and any supplemental terms, policies, rules and
          guidelines posted on our website constitute the entire agreement
          between you and us and supersede all previous or contemporaneous
          written or oral agreements. If any part of these Terms of Service is
          held invalid or unenforceable, that portion shall be construed in a
          manner consistent with applicable law to reflect, as nearly as
          possible, the original intentions of the parties, and the remaining
          portions shall remain in full force and effect.
        </p>
        <p className="tw-text-bold tw-text-3xl tw-mb-5">
          CHOICE OF LAW AND LOCATION FOR RESOLVING DISPUTES
        </p>

        <p className="tw-text-semibold rubik tw-mb-4">
          You agree that the laws of the Bangalore, India govern this contract
          and any claim or dispute that you may have against us or our
          suppliers, without regard to the conflict of laws rules thereunder,
          and that the United Nations Convention on Contracts for the
          International Sale of Goods shall have no applicability. You further
          agree that any disputes or claims that you may have against us or our
          suppliers will be resolved exclusively by a court located in
          Bangalore, India.
        </p>
        <p className="tw-text-bold tw-text-3xl tw-mb-5">
          PLEASE NOTE THAT BY AGREEING TO THESE TERMS OF USE, YOU ARE:
        </p>

        <p className="tw-text-semibold rubik tw-mb-2">
          1. Waiving claims that you might otherwise have against us based on
          the laws of other jurisdictions, including your own;
        </p>
        <p className="tw-text-semibold rubik tw-mb-2">
          2. Irrevocably consenting to the exclusive jurisdiction of, and venue
          in, Bangalore, India over any disputes or claims you have with us
          relating to or arising out of the services, the service or the
          agreement.
        </p>
        <p className="tw-text-semibold rubik tw-mb-2">
          3. Submitting yourself to the personal jurisdiction of courts located
          in Bangalore, India for the purpose of resolving any such disputes or
          claims.
        </p>
        <p className="tw-text-semibold rubik tw-mb-4">
          Terms and conditions apply.
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default Tandc;
