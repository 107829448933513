import React from "react";
import { NavLink } from "react-router-dom";
import logo from "../Images/Jnana_web_Footer_logo.png";
import iaf from "../Images/iafcerimg.png";
import ias from "../Images/iascerimg.JPG";
import qfs from "../Images/qfscerimg.jpeg";

const Footer = () => {
  return (
    <div>
      <footer class="tw-p-4 tw-bg-white tw-shadow md:px-6 md:tw-py-8 dark:tw-bg-gray-900 tw-relative footerbg">
        <div className="footerneuron"></div>
        {/* <div class="sm:tw-flex sm:tw-items-center sm:tw-justify-between"> */}
        <div class="tw-flex tw-flex-wrap tw-mt-6 tw-mx-8">
          <div class="tw-hidden md:tw-block tw-p-6 lg:tw-w-1/2 md:tw-w-1/2 tw-w-full ">
            <ul class=" tw-items-center tw-mb-6 tw-text-md sm:tw-mb-0 tw-text-slate-300 tw-absolute tw-z-10">
              <li className="">Ecommerce Applications</li>
              <li className="">Custom Software Developments</li>
              <li className="">UI/UX Designs</li>
              <li className="">
                ERP (Accounts, Sales, Purchase, Inventory, Poduction,Reports)
              </li>
              <li className="">
                Travel Ecommerce Application (B2C/B2B/CBT/Back office)
              </li>
              <li className="">Mobile Apps (Native, Responsive, PWA)</li>
              <li className="">Trading Applications</li>
              <li className="tw-mb-8">Resource Pool Project Basis</li>
              <li className="tw-mb-2">
                <a href="https://www.instagram.com/jnanasoft/">
                  <i
                    style={{ fontSize: 35, marginRight: 5 }}
                    class="lab la-instagram"
                  ></i>
                </a>
                <a href="https://www.facebook.com/JnanaSoftware">
                  <i
                    style={{ fontSize: 35, marginRight: 5 }}
                    class="lab la-facebook"
                  ></i>
                </a>
                <a className="https://www.linkedin.com/company/jnanasoft/">
                  <i
                    style={{ fontSize: 35, marginRight: 5 }}
                    class="lab la-linkedin-in"
                  ></i>
                </a>
                <a>
                  <i
                    style={{ fontSize: 35, marginRight: 5 }}
                    class="lab la-twitter"
                  ></i>
                </a>
              </li>
              <li className="tw-text-lg tw-font-medium tw-text-slate-200">
                <span className="tw-border-r-2 tw-pr-4 tw-mr-2">Phone no</span>{" "}
                +91 98451 17110
              </li>
              <li className="tw-text-lg tw-font-medium tw-text-slate-200">
                <span className="tw-border-r-2 tw-pr-4 tw-mr-2">Email</span>{" "}
                Info@jnanasoft.com
              </li>
            </ul>
          </div>
          <div class="tw-p-6 lg:tw-w-1/2 md:tw-w-1/2 tw-w-full ">
            <a
              href="https:"
              class="tw-flex tw-items-center tw-justify-end tw-mb-4 sm:tw-mb-0"
            >
              <img
                src={logo}
                class="tw-mr-3 tw-w-72 tw-h-38"
                alt="Flowbite Logo"
              />
            </a>
            <div className="tw-flex tw-items-center tw-justify-end tw-mb-4 sm:tw-mb-0 tw-mt-16 tw-text-md tw-font-normal tw-text-slate-300">
              <div className=" tw-flex tw-border-r-2 tw-self-baseline tw-pr-4">
                Address
              </div>
              <div className=" tw-w-50 tw-pl-4">
                <ul class=" tw-items-center tw-mb-6 sm:tw-mb-0 dark:tw-text-gray-400">
                  <li>
                    <a href="#" class="mr-4 md:mr-6 ">
                      {" "}
                      No.50, S.Y. No 101 ,
                    </a>
                  </li>
                  <li>
                    <a href="#" class="mr-4 md:mr-6">
                      3rd Main, FCI Main Road
                    </a>
                  </li>
                  <li>
                    <a href="#" class="mr-4 md:mr-6 ">
                      Vijinapura -560016
                    </a>
                  </li>
                  <li>
                    <a href="#" class="">
                      Bangalore, INDIA
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="tw-flex tw-flex-row tw-justify-between tw-mt-8">
              <div className="rubik tw-text-slate-300 tw-font-thin">
                <p className="tw-underline tw-underline-offset-4 tw-text-lg ">
                  Company
                </p>
                <p className="tw-mb-1">1.About us </p>
                <p className="tw-mb-1">2.Careers </p>
                <p className="tw-mb-1">3.Contact us </p>
              </div>
              <div className="rubik tw-text-slate-300 tw-font-thin tw-ml-8">
                <p className="tw-underline tw-underline-offset-4 tw-text-lg ">
                  Legal
                </p>
                <NavLink to="/privacypolicy" className="tw-mb-2 tw-block">
                  1.Privacy policy{" "}
                </NavLink>
                <NavLink to="/tandc" className="tw-mb-2 tw-block">
                  2.Terms and conditions
                </NavLink>
                <p className="tw-mb-1">3.Cookies & preferences </p>
              </div>
              <div className="rubik tw-text-slate-300 tw-font-thin tw-ml-8">
                <div className=" tw-hidden md:tw-flex tw-flex-row tw-justify-end tw-space-x-2 mb-4  ">
                  <img
                    src={ias}
                    className="tw-block"
                    width="50"
                    alt="img"
                    height="30"
                  ></img>
                  <img
                    src={iaf}
                    className="tw-block"
                    width="50"
                    alt="img"
                    height="30"
                  ></img>
                  <img
                    src={qfs}
                    className="tw-block"
                    width="50"
                    alt="img"
                    height="30"
                  ></img>
                </div>
                <p className=" tw-text-base ">
                  ISO Certified : 9001:2015 QMS 27001:2013 ISMS
                </p>
              </div>
            </div>
          </div>
        </div>

        <span class="tw-block tw-text-sm tw-text-slate-300 sm:tw-text-center tw-mt-1">
          COPYRIGHT@2022 -23 ALL RIGHTS RESERVED TO JNANA SOFTWARE TECHNOLOGIES
          PVT. LTD.
        </span>
      </footer>
    </div>
  );
};

export default Footer;
