import React from "react";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import DarkTextField from "../Components/DarkTextField";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import { ThemeProvider, createTheme } from "@mui/material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCartFlatbedSuitcase,
  faHotel,
  faPlane,
  faHandshake,
  faComputer,
  faPrint,
  faUsers,
  faBuilding,
  faChartSimple,
  faSquareH,
  faMobile,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faPlane,
  faCartFlatbedSuitcase,
  faHotel,
  faHandshake,
  faComputer,
  faPrint,
  faUsers,
  faBuilding,
  faChartSimple,
  faSquareH,
  faMobile
);

const darkTheme = createTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#...", // Replace with your primary color
    },
    secondary: {
      main: "#...", // Replace with your secondary color
    },
    // Add more customizations as needed
  },
});

const Traveltechnology = () => {
  return (
    <div className="tw-overflow-hidden">
      <Header />
      <div className="Traveltechnology">
        <div className="traveltech cut-background">
          <div className="tw-flex tw-flex-wrap lg:tw-flex-nowrap">
            <div className="tw-w-full lg:tw-w-1/2 tw-mt-48 tw-text-slate-200">
              <h4 className="tw-text-3xl tw-mx-5 md:tw-mx-auto tw-w-full md:tw-w-1/2 tw-font-medium poppins">
                "Unlock Your Wanderlust : Explore the World with
              </h4>
              <h4 className="tw-text-3xl tw-mx-5 md:tw-mx-auto tw-w-full md:tw-w-1/2 tw-font-medium poppins">
                Cutting-Edge Travel Technology!"
              </h4>
              <h4 className="tw-text-3xl tw-mx-5 md:tw-mx-auto tw-w-full md:tw-w-1/2 tw-font-medium tw-mt-16 poppins">
                Get Your travel technology product Today !
              </h4>
              <h4 className="lg:tw-text-4xl tw-text-3xl tw-text-center md:tw-text-left md:tw-mx-auto tw-w-full md:tw-w-1/2 tw-font-medium tw-mt-16 brushking">
                " VOYAGE EASE "
              </h4>
            </div>
            <div className="tw-w-full lg:tw-w-1/2 tw-mt-16 md:tw-mt-36 tw-text-slate-200 tw-mx-8 md:tw-mx-16">
              <h3 className="tw-text-3xl tw-mx-auto  poppins tw-w-fit">
                Almost there to get your Demo Account !
              </h3>
              <h3 className="tw-text-xl tw-mx-auto tw-mt-4 poppins tw-w-fit tw-text-slate-300">
                Fill the form below to get connected with our ERP expert to
                experience the Live Demo.
              </h3>
              <div className="tw-flex tw-gap-10 tw-text-white tw-px-8 mt-4">
                <div className="tw-w-1/2">
                  <ThemeProvider theme={darkTheme}>
                    <DarkTextField
                      id="standard-basic"
                      label="First Name"
                      variant="standard"
                      fullWidth
                      required
                    />
                  </ThemeProvider>
                </div>
                <div className="tw-w-1/2">
                  <ThemeProvider theme={darkTheme}>
                    <DarkTextField
                      id="standard-basic"
                      label="Last Name"
                      variant="standard"
                      fullWidth
                      required
                    />
                  </ThemeProvider>
                </div>
              </div>
              <div className="tw-flex tw-gap-10 tw-text-white tw-px-8 mt-2">
                <div className="tw-w-1/2">
                  <ThemeProvider theme={darkTheme}>
                    <DarkTextField
                      id="standard-basic"
                      label="Company Name"
                      variant="standard"
                      fullWidth
                      required
                    />
                  </ThemeProvider>
                </div>
                <div className="tw-w-1/2">
                  <ThemeProvider theme={darkTheme}>
                    <DarkTextField
                      id="standard-basic"
                      label="Work Email"
                      variant="standard"
                      fullWidth
                      required
                    />
                  </ThemeProvider>
                </div>
              </div>
              <div className="tw-flex tw-gap-10 tw-text-white tw-px-8 mt-2">
                <div className="tw-w-1/2">
                  <ThemeProvider theme={darkTheme}>
                    <DarkTextField
                      id="standard-basic"
                      label="Phone No"
                      variant="standard"
                      fullWidth
                      required
                    />
                  </ThemeProvider>
                </div>
                <div className="tw-w-1/2">
                  <ThemeProvider theme={darkTheme}>
                    <DarkTextField
                      id="standard-basic"
                      label="Country"
                      variant="standard"
                      fullWidth
                      required
                    />
                  </ThemeProvider>
                </div>
              </div>
              <div className="tw-flex tw-gap-10 tw-text-white tw-px-8 mt-2">
                <div className="tw-w-1/2">
                  <ThemeProvider theme={darkTheme}>
                    <DarkTextField
                      id="standard-basic"
                      label="P.O Box / Postal Code"
                      variant="standard"
                      fullWidth
                      required
                    />
                  </ThemeProvider>
                </div>
                <div className="tw-w-1/2">
                  <ThemeProvider theme={darkTheme}>
                    <DarkTextField
                      id="standard-basic"
                      label="Designation"
                      variant="standard"
                      fullWidth
                      required
                    />
                  </ThemeProvider>
                </div>
              </div>
              <div className="tw-flex tw-gap-10 tw-text-white tw-px-8 mt-2">
                <div className="tw-w-1/2">
                  <ThemeProvider theme={darkTheme}>
                    <DarkTextField
                      id="standard-basic"
                      label="How did you hear about us ?"
                      variant="standard"
                      fullWidth
                      required
                    />
                  </ThemeProvider>
                </div>
                <div className="tw-w-1/2">
                  <ThemeProvider theme={darkTheme}>
                    <DarkTextField
                      id="standard-basic"
                      label="Number of User's Required"
                      variant="standard"
                      fullWidth
                      required
                    />
                  </ThemeProvider>
                </div>
              </div>
              <div className="ttform mt-5">
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="By filling and submitting this form you understand and agree that the use of Finanzz web site is 
subject to the Finanaz.in . Additional details regarding Finanzz collection and use of 
your personal information, including information about access, retention, rectification, deletion, 
security, cross-border transfers and other topics, is available in the Finanzz Privacy Policy"
                  />
                </FormGroup>
              </div>
              <div className="mt-5 tw-flex tw-justify-center">
                <button
                  className="tw-px-16 tw-py-3 tw-text-xl tw-bg-yellow-400 tw-shadow-xl tw-font-medium tw-text-black
              tw-transition tw-ease-in-out tw-delay-150  hover:tw-translate-y-1 hover:tw-scale-110 hover:tw-bg-yellow-500 tw-duration-300"
                >
                  Request Demo
                </button>
              </div>
            </div>
          </div>
          <div className="tw-hidden md:tw-h-96"></div>
        </div>
        <div className="tw-absolute tw-z-10 tw-mx-auto tw-w-full">
          <div className="tw-relative  relative-edit tw-bg-white md:tw-bg-transparent">
            <h3 className="tw-text-5xl mb-5 tw-ml-5 desfont">
              Voyage Ease / Travel Suite
            </h3>
            <p className="tw-text-center tw-text-2xl">
              Its a comprehensive travel suite that fulfils all your travel
              technology needs. We cater to all types of your customer needs be
              it
            </p>
            <p className="tw-text-center tw-text-2xl tw-mb-8">
              B2C/B2B/Walkin or yourcorporates professionals.we have lined
              products to meet your futuristic requirements.{" "}
            </p>
            <div className="tw-w-full">
              <h1 className="tw-text-4xl tw-font-bold tw-text-center tw-w-fit tw-mx-auto p-2 ">
                B2C | B2B | SBT | POS | Finance{" "}
              </h1>
            </div>
          </div>
        </div>
        <div className="tw-flex tw-flex-wrap md:tw-flex-nowrap tw-bg-violet-200 tw-w-full tw-h-auto tw-gap-x-5 tw-p-4 lg:tw-px-12 lg:tw-py-16">
          <div className="tw-w-full md:tw-w-1/2 bgtraveldesktop"></div>
          <div className=" tw-flex md:tw-w-1/2 mx-1 md:mx-0   tw-space-x-5">
            <h1 className="tw-text-6xl tw-font-bold tw-text-right tw-text-slate-500 rotate-text tw-underline-offset-4 tw-underline">
              B2C MAIN FEAUTURES
            </h1>
            <div className="tw-pl-5">
              <h2 className="tw-text-6xl tw-mb-4 tw-text-indigo-900">
                B2C OFFERINGS
              </h2>
              <ul className="tw-text-xl  tw-text-indigo-900">
                <li className="tw-border-l-2 tw-pl-3 tw-mb-1 tw-border-indigo-900"> Connected with Multiple Suppliers (Flight & Hotel)</li>
                <li className="tw-border-l-2 tw-pl-3 tw-mb-1 tw-border-indigo-900">
                   Result Aggregation for having cheaper fare from Multiple
                  Suppliers plus Direct connects
                </li>
                <li className="tw-border-l-2 tw-pl-3 tw-mb-1 tw-border-indigo-900">
                   Dynamic Packaging (Flight + Hotel + Sightseeing + CAR)
                </li>
                <li className="tw-border-l-2 tw-pl-3 tw-mb-1 tw-border-indigo-900"> Multi office ID (For Flight)</li>
                <li className="tw-border-l-2 tw-pl-3 tw-mb-1 tw-border-indigo-900"> Multilingual</li>
                <li className="tw-border-l-2 tw-pl-3 tw-mb-1 tw-border-indigo-900"> Multicurrency</li>
                <li className="tw-border-l-2 tw-pl-3 tw-mb-1 tw-border-indigo-900"> Affiliate Search Implementation</li>
                <li className="tw-border-l-2 tw-pl-3 tw-mb-1 tw-border-indigo-900"> CMS based Application</li>
                <li className="tw-border-l-2 tw-pl-3 tw-mb-1 tw-border-indigo-900"> Booking Dashboard and Statistics</li>
                <li className="tw-border-l-2 tw-pl-3 tw-mb-1 tw-border-indigo-900"> Monitoring Tool</li>
                <li className="tw-border-l-2 tw-pl-3 tw-mb-1 tw-border-indigo-900"> Destination Pages for Airline/Hotel</li>
                <li className="tw-border-l-2 tw-pl-3 tw-mb-1 tw-border-indigo-900"> Mobile Responsive</li>
                <li className="tw-border-l-2 tw-pl-3 tw-mb-1 tw-border-indigo-900"> Deals codes & coupon management</li>
                <li className="tw-border-l-2 tw-pl-3 tw-mb-1 tw-border-indigo-900">
                   Fraud Check Score based Implementation (For Flight)
                </li>
                <li className="tw-border-l-2 tw-pl-3 tw-mb-1 tw-border-indigo-900"> Auto Ticketing Implementation</li>
                <li className="tw-border-l-2 tw-pl-3 tw-mb-1 tw-border-indigo-900"> Multiple Payment Gateway Configuration</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="tw-flex tw-gap-12 tw-my-16 tw-pb-8 tw-ml-2 tw-overflow-x-scroll tw-overflow-y-hidden tw-scroll-smooth scroll-travel">
          <div className="w-travel-700 tw-bg-slate-200  tw-rounded-3xl  tw-h-full tw-bg-opacity-75 gcardBorder tw-relative tw-transition tw-duration-500 travelbhover  hover:tw-shadow-[6.0px_8.0px_18px_rgba(0,0,0,0.42)] tw-group/item">
            <div className="tw-h-36 bg-travel-plane"></div>
            <div className="tw-flex tw-py-1 tw-px-6 tw-rounded-3xl tw-my-2 mx-1 tw-bg-pink-600 p-3 tw-shadow-[6.0px_8.0px_18px_rgba(0,0,0,0.42)] group-hover/item:tw-shadow-none">
              <h1 className="rotate-text tw-text-end tw-w-1/12 tw-text-6xl tw-text-gray-600 tw-font-bold">
                FLIGHT FEATURES
              </h1>
              <div className="tw-w-11/12 tw-pl-4">
                <ul className=" tw-text-lg tw-text-white">
                  <li className="tw-border-l-2 tw-pl-3 tw-mb-1"> Multi GDS, LCC & Direct Connect Integrated</li>
                  <li className="tw-border-l-2 tw-pl-3 tw-mb-1"> Content Aggregation from multiple suppliers</li>
                  <li className="tw-border-l-2 tw-pl-3 tw-mb-1"> Trip Type: One-way trip, Round Trip, Multi City</li>
                  <li className="tw-border-l-2 tw-pl-3 tw-mb-1"> Search, Modify Search, Recent Search</li>
                  <li className="tw-border-l-2 tw-pl-3 tw-mb-1"> Advance filters & Sorting</li>
                  <li className="tw-border-l-2 tw-pl-3 tw-mb-1"> Fare Calendar (+3/-3) days</li>
                  <li className="tw-border-l-2 tw-pl-3 tw-mb-1"> Display Fare Rules/Mini Rules</li>
                  <li className="tw-border-l-2 tw-pl-3 tw-mb-1"> Show baggage information</li>
                  <li className="tw-border-l-2 tw-pl-3 tw-mb-1"> Show Inflight Services</li>
                  <li className="tw-border-l-2 tw-pl-3 tw-mb-1"> Seat & Meal Request</li>
                  <li className="tw-border-l-2 tw-pl-3 tw-mb-1"> Passport Active/De-active from Backend</li>
                  <li className="tw-border-l-2 tw-pl-3 tw-mb-1"> Multi office ID</li>
                  <li className="tw-border-l-2 tw-pl-3 tw-mb-1"> On Hold Booking and PNR Cancellation</li>
                  <li className="tw-border-l-2 tw-pl-3 tw-mb-1"> Import and Sync PNR</li>
                  <li className="tw-border-l-2 tw-pl-3 tw-mb-1"> Markup/Discount, Service Fee implementation</li>
                  <li className="tw-border-l-2 tw-pl-3 tw-mb-1"> Manage Deal Code & Corporate Code</li>
                  <li className="tw-border-l-2 tw-pl-3 tw-mb-1"> Auto Ticketing</li>
                  <li className="tw-border-l-2 tw-pl-3 tw-mb-1"> Recent Searches</li>
                  <li className="tw-border-l-2 tw-pl-3 tw-mb-1"> My Profile and Login As Guest</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="w-travel-700 tw-bg-slate-200 tw-rounded-3xl  tw-h-full tw-bg-opacity-75  gcardBorder tw-relative tw-transition tw-duration-500 travelthover  hover:tw-shadow-[6.0px_8.0px_18px_rgba(0,0,0,0.42)] tw-group/item">
            <div className="tw-h-32 ">
              <FontAwesomeIcon
                className="tw-text-8xl tw-text-yellow-400 tw-shadow-2xl tw-mt-3 tw-ml-3"
                icon="fa-solid fa-hotel"
              />
            </div>
            <div className=" mx-1 tw-flex tw-py-1 tw-px-6 tw-rounded-3xl tw-my-2 mx-1 tw-bg-teal-600 p-3 tw-shadow-[6.0px_8.0px_18px_rgba(0,0,0,0.42)] group-hover/item:tw-shadow-none">
              <h1 className="rotate-text tw-text-end tw-w-1/12 tw-text-6xl tw-text-gray-600 tw-font-bold">
                HOTEL FEATURES
              </h1>
              <div className="tw-w-11/12 tw-pl-4">
                <ul className=" tw-text-lg tw-text-white">
                  <li className="tw-border-l-2 tw-pl-3 tw-mb-1">
                     Multiple Supplier Integration (Expedia, Hotel Beds,
                    TBO, LOH, DOWT)
                  </li>
                  <li className="tw-border-l-2 tw-pl-3 tw-mb-1"> Hotel search by- Hotel Name, City Name</li>
                  <li className="tw-border-l-2 tw-pl-3 tw-mb-1">
                     Advance Search by- Hotel Price, Star Rating, Location,
                    Suppliers, Availability
                  </li>
                  <li className="tw-border-l-2 tw-pl-3 tw-mb-1"> Modify and recent search</li>
                  <li className="tw-border-l-2 tw-pl-3 tw-mb-1">
                     Response show with map view, move me option (like drag
                    drop marker and show all hotels nearby), distance wise
                    filter, street view.
                  </li>
                  <li className="tw-border-l-2 tw-pl-3 tw-mb-1"> Filter property by landmarks</li>
                  <li className="tw-border-l-2 tw-pl-3 tw-mb-1"> Display Rooms from Multiple Suppliers</li>
                  <li className="tw-border-l-2 tw-pl-3 tw-mb-1">
                     Display Hotel description, Cancellation policy, Hotel
                    Amenities
                  </li>
               
                <li className="tw-border-l-2 tw-pl-3 tw-mb-1"> Hotel Mapping via GIATA</li>
                  <li className="tw-border-l-2 tw-pl-3 tw-mb-1"> Static Data from Inventory Management System</li>
                  <li className="tw-border-l-2 tw-pl-3 tw-mb-1"> Show suppliers based on supplier switch module</li>
                  <li className="tw-border-l-2 tw-pl-3 tw-mb-1"> De-Duplication of Hotels from Mapping Data</li>
                  <li className="tw-border-l-2 tw-pl-3 tw-mb-1">
                     Hotel Booking confirmation Email & SMS to customers &
                    admin
                  </li>
                  <li className="tw-border-l-2 tw-pl-3 tw-mb-1"> Markup/discount, Service Fee Implementation</li>
                  <li className="tw-border-l-2 tw-pl-3 tw-mb-1">
                     Response with lazy loading (First come first serve)
                  </li>
                  <li className="tw-border-l-2 tw-pl-3 tw-mb-1"> API Cancellation, Hotel Rules, Image Gallery</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="w-travel-700 tw-bg-slate-200 tw-rounded-3xl tw-h-full tw-bg-opacity-75  gcardBorder tw-relative tw-transition tw-duration-500 travelghover  hover:tw-shadow-[6.0px_8.0px_18px_rgba(0,0,0,0.42)] tw-group/item">
            <div className="tw-h-36 bg-travel-hotel">
              {/* <FontAwesomeIcon
                className="tw-text-8xl tw-text-yellow-400"
                icon="fa-solid fa-cart-flatbed-suitcase"
              /> */}
            </div>
            <div className="tw-flex tw-py-1 tw-px-6 tw-rounded-3xl tw-my-2 mx-1  p-3 tw-shadow-[6.0px_8.0px_18px_rgba(0,0,0,0.42)] group-hover/item:tw-shadow-none tw-bg-slate-400">
              <h1 className="rotate-text tw-text-end tw-w-1/12 tw-text-6xl tw-text-gray-700 tw-font-bold">
                ACTIVITY FEATURES
              </h1>
              <div className="tw-w-11/12 tw-pl-4">
                <ul className=" tw-text-lg tw-text-white">
                  <li className="tw-border-l-2 tw-pl-3 tw-mb-1"> Multi GDS, LCC & Direct Connect Integrated</li>
                  <li className="tw-border-l-2 tw-pl-3 tw-mb-1"> Content Aggregation from multiple suppliers</li>
                  <li className="tw-border-l-2 tw-pl-3 tw-mb-1"> Trip Type: One-way trip, Round Trip, Multi City</li>
                  <li className="tw-border-l-2 tw-pl-3 tw-mb-1"> Search, Modify Search, Recent Search</li>
                  <li className="tw-border-l-2 tw-pl-3 tw-mb-1"> Advance filters & Sorting</li>
                  <li className="tw-border-l-2 tw-pl-3 tw-mb-1"> Fare Calendar (+3/-3) days</li>
                  <li className="tw-border-l-2 tw-pl-3 tw-mb-1"> Display Fare Rules/Mini Rules</li>
                  <li className="tw-border-l-2 tw-pl-3 tw-mb-1"> Show baggage information</li>
                  <li className="tw-border-l-2 tw-pl-3 tw-mb-1"> Show Inflight Services</li>
                  <li className="tw-border-l-2 tw-pl-3 tw-mb-1"> Seat & Meal Request</li>
                  <li className="tw-border-l-2 tw-pl-3 tw-mb-1"> Passport Active/De-active from Backend</li>
                  <li className="tw-border-l-2 tw-pl-3 tw-mb-1"> Multi office ID</li>
                  <li className="tw-border-l-2 tw-pl-3 tw-mb-1"> On Hold Booking and PNR Cancellation</li>
                  <li className="tw-border-l-2 tw-pl-3 tw-mb-1"> Import and Sync PNR</li>
                  <li className="tw-border-l-2 tw-pl-3 tw-mb-1"> Markup/Discount, Service Fee implementation</li>
                  <li className="tw-border-l-2 tw-pl-3 tw-mb-1"> Manage Deal Code & Corporate Code</li>
                  <li className="tw-border-l-2 tw-pl-3 tw-mb-1"> Auto Ticketing</li>
                  <li className="tw-border-l-2 tw-pl-3 tw-mb-1"> Recent Searches</li>
                  <li className="tw-border-l-2 tw-pl-3 tw-mb-1"> My Profile and Login As Guest</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="tw-hidden md:tw-block tw-container-2xl carouselContainer tw-h-5/6">
          <div
            id="carouselExampleControls"
            class="carousel slide"
            data-bs-ride="carousel"
          >
            <div class="carousel-inner">
              <div class="carousel-item active">
                <div className="tw-flex tw-flex-wrap md:tw-flex-nowrap tw-h-full tw-bg-slate-300 ">
                  <div className="tw-w-full md:tw-w-3/12 tw-flex tw-flex-col tw-justify-center tw-items-center tw-p-24 tw-bg-blue-400 rounded-br-150 tw-mb-12 lg:tw-mb-36">
                    <h4 className="px-4 py-2 tw-border bg-white tw-w-full tw-text-center tw-text-2xl tw-border-b-2 tw-border-t-0 tw-border-l-0 tw-border-r-0 tw-border-black">
                      Fly Happie
                    </h4>
                    <p className="tw-pl-4 bg-white tw-w-full tw-pt-4">
                      Project : B2C & B2B
                    </p>
                    <p className="tw-pl-4 bg-white tw-w-full">
                      Technologies : Dot net core
                    </p>
                    <p className="tw-pl-4 bg-white tw-w-full">React js</p>
                    <p className="tw-pl-4 bg-white tw-w-full">Tailwind</p>
                    <p className="tw-pl-4 bg-white tw-w-full"> PWA</p>
                    <p className="tw-pl-4 bg-white tw-w-full tw-pb-4">
                      Time Period : 2 Months
                    </p>
                  </div>
                  <div className="h-400 tw-w-full tw-flex tw-flex-col md:tw-w-9/12 tw-pr-4 ">
                    <h3 className="tw-text-4xl lg:tw-relative r-travel-12 tw-w-full h-10 tw-text-indigo-900 tw-text-center tw-pt-5 tw-mb-6 tw-font-bold">
                      OUR WORK
                    </h3>
                    <div className="tw-w-full h-80 bgtraveldesktopandmobile"></div>
                    <div className="tw-w-full r-travel-12 lg:tw-relative h-10 tw-text-2xl tw-text-center tw-text-blue-600">
                      Flyhappie.com
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item ">
                <div className="tw-flex tw-flex-wrap md:tw-flex-nowrap tw-h-full tw-bg-slate-300 ">
                  <div className="tw-w-full md:tw-w-3/12 tw-flex tw-flex-col tw-justify-center tw-items-center tw-p-24 tw-bg-blue-400 rounded-br-150 tw-mb-12 lg:tw-mb-36">
                    <h4 className="px-4 py-2 tw-border bg-white tw-w-full tw-text-center tw-text-2xl tw-border-b-2 tw-border-t-0 tw-border-l-0 tw-border-r-0 tw-border-black">
                      Plan My Booking
                    </h4>
                    <p className="tw-pl-4 bg-white tw-w-full tw-pt-4">
                      Project : B2C & B2B
                    </p>
                    <p className="tw-pl-4 bg-white tw-w-full">
                      Technologies : Dot net core
                    </p>
                    <p className="tw-pl-4 bg-white tw-w-full">React js</p>
                    <p className="tw-pl-4 bg-white tw-w-full">Tailwind</p>
                    <p className="tw-pl-4 bg-white tw-w-full"> PWA</p>
                    <p className="tw-pl-4 bg-white tw-w-full tw-pb-4">
                      Time Period : 2 Months
                    </p>
                  </div>
                  <div className="h-400 tw-w-full tw-flex tw-flex-col md:tw-w-9/12 tw-pr-4 ">
                    <h3 className="tw-text-4xl r-travel-12 lg:tw-relative tw-w-full h-10 tw-text-indigo-900 tw-text-center tw-pt-5 tw-mb-6 tw-font-bold">
                      OUR WORK
                    </h3>
                    <div className="tw-w-full h-80 bgtraveldesktopandmobile"></div>
                    <div className="tw-w-full h-10 r-travel-12 lg:tw-relative tw-text-2xl tw-text-center tw-text-blue-600">
                      Planmybooking.com
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button
              class="carousel-control-prev  tw-z-20"
              type="button"
              data-bs-target="#carouselExampleControls"
              data-bs-slide="prev"
            >
              <span class="carousel-control-prev-icon tw-self-end tw-mb-3"></span>
              {/* <span class="carousel-control-prev-icon" aria-hidden="true"></span> */}
              <span class="visually-hidden">Previous</span>
            </button>
            <button
              class="carousel-control-next  tw-z-20"
              type="button"
              data-bs-target="#carouselExampleControls"
              data-bs-slide="next"
            >
              <span class="carousel-control-next-icon tw-self-end tw-mb-3"></span>
              {/* <span class="carousel-control-next-icon" aria-hidden="true"></span> */}
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>

        <div className="tw-flex tw-h-full tw-bg-sky-300">
          <div className="container tw-flex tw-flex-col justify-content-center  ">
            <h4 className=" tw-text-white tw-text-3xl tw-text-center tw-font-bold tw-my-5">
              OUR TECHNOLOGY SOLUTIONS
            </h4>
            <p className="tw-text-lg tw-mb-8">
              "At the heart of our travel endeavors lies a wealth of domain
              expertise and a talented pool of technical resources. With this
              winning combination, we envision and craft exceptional travel
              products. Our commitment to delivering top-notch solutions is
              reflected in our 'out of the box' approach, offering our customers
              a diverse array of travel technology frameworks and solutions
              tailored to market demands and customer behavior."
            </p>
            <div className="tw-flex tw-flex-wrap lg:tw-flex-nowrap tw-justify-content-between tw-mb-4 md:tw-mb-0 tw-space-x-0 lg:tw-space-x-4">
              <div className="tw-w-full md:tw-w-1/2 lg:tw-w-3/12 tw-mb-4 md:tw-mb-0  tw-p-7 tw-bg-cyan-600">
                <div className="tw-text-center">
                  <FontAwesomeIcon
                    className="iconzize tw-text-white tw-text-center"
                    icon="fa-solid fa-handshake"
                  />
                </div>
                <h4 className="tw-text-3xl tw-text-white tw-mt-5  tw-font-bold">
                  CONSULTING
                </h4>
              </div>
              <div className="tw-w-full md:tw-w-1/2 lg:tw-w-3/12 tw-mb-4 md:tw-mb-0  tw-p-7 tw-bg-sky-800">
                <div className="tw-text-center">
                  <FontAwesomeIcon
                    className="iconzize tw-text-white tw-text-center"
                    icon="fa-solid fa-computer"
                  />
                </div>
                <h4 className="tw-text-3xl tw-text-white tw-mt-5  tw-font-bold">
                  BOOKING ENGINE
                </h4>
              </div>
              <div className="tw-w-full md:tw-w-1/2 lg:tw-w-3/12 tw-mb-4 md:tw-mb-0  tw-p-7 tw-bg-sky-600">
                <div className="tw-text-center">
                  <FontAwesomeIcon
                    className="iconzize tw-text-white tw-text-center"
                    icon="fa-solid fa-print"
                  />
                </div>
                <h4 className="tw-text-3xl tw-text-white tw-mt-5  tw-font-bold">
                  POS APPLICATION
                </h4>
              </div>
              <div className="tw-w-full md:tw-w-1/2 lg:tw-w-3/12 tw-mb-4 md:tw-mb-0  tw-p-7 tw-bg-sky-400">
                <div className="tw-text-center">
                  <FontAwesomeIcon
                    className="iconzize tw-text-white tw-text-center"
                    icon="fa-solid fa-users"
                  />
                </div>
                <h4 className="tw-text-3xl tw-text-white tw-mt-5  tw-font-bold">
                  B2B APPS
                </h4>
              </div>
            </div>
            <div className="tw-flex tw-flex-wrap lg:tw-flex-nowrap tw-justify-content-between  tw-space-x-0 lg:tw-space-x-4 tw-mt-5 tw-mb-10">
              <div className="tw-w-full md:tw-w-1/2 lg:tw-w-3/12 tw-mb-4 md:tw-mb-0  tw-p-7 tw-bg-cyan-300">
                <div className="tw-text-center">
                  <FontAwesomeIcon
                    className="iconzize tw-text-white tw-text-center"
                    icon="fa-solid fa-building"
                  />
                </div>
                <h4 className="tw-text-3xl tw-text-white tw-mt-5  tw-font-bold">
                  MID OFFICE
                </h4>
              </div>

              <div className="tw-w-full md:tw-w-1/2 lg:tw-w-3/12 tw-mb-4 md:tw-mb-0  tw-p-7 tw-bg-sky-700">
                <div className="tw-text-center">
                  <FontAwesomeIcon
                    className="iconzize tw-text-white tw-text-center"
                    icon="fa-solid fa-chart-simple"
                  />
                </div>
                <h4 className="tw-text-3xl tw-text-white tw-mt-5  tw-font-bold">
                  TAILORED SOLUTION
                </h4>
              </div>
              <div className="tw-w-full md:tw-w-1/2 lg:tw-w-3/12 tw-mb-4 md:tw-mb-0  tw-p-7 tw-bg-sky-700">
                <div className="tw-text-center">
                  <FontAwesomeIcon
                    className="iconzize tw-text-white tw-text-center"
                    icon="fa-solid fa-square-h"
                  />
                </div>
                <h4 className="tw-text-3xl tw-text-white tw-mt-5  tw-font-bold">
                  HOTEL INVENTORY SYSTEM
                </h4>
              </div>
              <div className="tw-w-full md:tw-w-1/2 lg:tw-w-3/12 tw-mb-4 md:tw-mb-0  tw-p-7 tw-bg-sky-700">
                <div className="tw-text-center">
                  <FontAwesomeIcon
                    className="iconzize tw-text-white tw-text-center"
                    icon="fa-solid fa-mobile"
                  />
                </div>
                <h4 className="tw-text-3xl tw-text-white tw-mt-5  tw-font-bold">
                  MOBILE APPS
                </h4>
              </div>
            </div>
          </div>
        </div>

        <div className="tw-w-full tw-h-full bg-travel-map   ">
          <div className="tw-px-16 tw-py-8 tw-text-center ">
            <h4 className=" tw-text-blue-900 tw-text-3xl tw-text-center tw-font-bold tw-mb-5">
              OUR APPROACH
            </h4>
            <div className="tw-flex justify-content-around tw-mt-5">
              <div>
                <h4 className="tw-text-3xl tw-font-semibold tw-text-green-400">
                  01 . Stategic Planning
                </h4>
                <ul className="tw-text-left tw-ml-0 md:tw-ml-10">
                  <li>&gt; Requirememt</li>
                  <li>&gt; Scope of work</li>
                  <li>&gt; Stakeholder Management</li>
                  <li>&gt; Information Archietecture</li>
                  <li>&gt; Wireframing</li>
                </ul>
              </div>
              <div>
                <h4 className="tw-text-3xl tw-font-semibold tw-text-cyan-500">
                  03 . Development
                </h4>
                <ul className="tw-text-left tw-ml-0 lg:tw-ml-10">
                  <li>&gt; Coding</li>
                  <li>&gt; Unit Testing</li>
                  <li>&gt; Database Desgning</li>
                  <li>&gt; CMS</li>
                  <li>&gt; Data Protection & security</li>
                  <li>&gt; Perfomance testing</li>
                </ul>
              </div>
              <div>
                <h4 className="tw-text-3xl tw-font-semibold tw-text-purple-800">
                  05 . Marketing
                </h4>
                <ul className="tw-text-left tw-ml-0 lg:tw-ml-10">
                  <li>&gt; Content Optimising </li>
                  <li>&gt; SEO & SEM</li>
                  <li>&gt; Email Marketing</li>
                  <li>&gt; Affiliate Marketing</li>
                  <li>&gt; Digtal Marketing </li>
                </ul>
              </div>
            </div>
            <div className="tw-h-64 bg-travel-lane my-3"></div>
            <div className="tw-flex justify-content-around tw-mb-5">
              <div>
                <h4 className="tw-text-3xl tw-font-semibold tw-text-emerald-400">
                  02 . Design
                </h4>
                <ul className="tw-text-left tw-ml-0 lg:tw-ml-20">
                  <li>&gt; UI & UX</li>
                  <li>&gt; Branding Guidlines</li>
                  <li>&gt; Design Guidlines</li>
                  <li>&gt; Responsive designs</li>
                  <li>&gt; Mobile App designs</li>
                </ul>
              </div>
              <div>
                <h4 className="tw-text-3xl tw-font-semibold tw-text-indigo-500">
                  04 . Delievery
                </h4>
                <ul className="tw-text-left tw-ml-0 lg:tw-ml-44">
                  <li>&gt; Testing</li>
                  <li>&gt; Functional, Regression & Automation</li>
                  <li>&gt; bug fixing</li>
                  <li>&gt; Prelaunch Testing</li>
                  <li>&gt; Go Live</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="bgtraveldarkpurple tw-p-10">
          <h3 className=" tw-text-xl tw-text-slate-300 text-center tw-mb-5">
            {" "}
            Certainly you have landed to a right place ! Click below to donwload
            system broucher to know more detials about our application
          </h3>
          <div className="tw-bg-gradient-to-r tw-shadow-xl tw-from-blue-900 tw-to-cyan-600  tw-text-slate-300 tw-mt-5 tw-py-2 tw-px-6 tw-w-fit text-center tw-m-auto rounded-pill">
            {" "}
            Click Here Download Broucher
          </div>
        </div>
        <div className="tw-w-full tw-h-full ">
          <div className="tw-px-6 tw-py-4 lg:tw-px-16 lg:tw-py-8 tw-text-center bgtravelpurple">
            <h4 className=" tw-text-white tw-text-3xl tw-text-center tw-font-bold tw-mb-5">
              OUR TECHNOLOGY STACK
            </h4>
            <div className="tw-flex tw-flex-wrap lg:tw-flex-nowrap  tw-mt-5 tw-space-x-0 lg:tw-space-x-10">
              <div className="tw-flex tw-w-full lg:tw-w-1/2 tw-space-x-3 bgtravellast"></div>
              <div className="tw-flex tw-w-full lg:tw-w-1/2 tw-space-x-3 bgtravellastright"></div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Traveltechnology;
