import React from "react";
import Aboutmeeting from "../Components/Aboutmeeting";
import Discuss from "../Components/Discuss";
import Footer from "../Components/Footer";
import Header from "../Components/Header";


const Itsolutions = () => {
  return (
    <div>
      <Header />
      <section className=" tw-h-screen tw-w-full ">
        <div className="bg-itblueimage  tw-z-10">
          <div className="tw-flex tw-mt-52">
            <div className="tw-flex-auto tw-w-1/5 md:tw-w-2/5 tw-mt-2 md:tw-mt-24 tw-ml-1 md:tw-ml-12 tw-pl-4.5 2xl:tw-mt-44 2xl:tw-ml-28">
              <h4 className="tw-text-white poppins tw-font-extrabold 2xl:tw-text-8xl tw-text-2xl md:tw-text-6xl tracking-wide tw-underline tw-underline-offset-8 tw-ml-4">
                SOLUTIONS
              </h4>
              <p className="tw-mt-2 md:tw-mt-8 tw-ml-4 md:tw-ml-8 poppins tw-text-yellow-300 tw-text-md md:tw-text-xl 2xl:tw-text-3xl">
                Create. Collaborate. Lead.
              </p>
              {/* <button className="tw-text-lg tw-border-2 tw-border-zinc-600 tw-text-zinc-500 tw-rounded-full tw-py-4 tw-px-12 tw-mt-14 tw-cursor-pointer tw-invisible">
                KNOW MORE
              </button> */}
            </div>
            <div className="tw-flex-auto tw-w-3/5 tw-relative">
              <div className="Itsolutionbg"></div>
            </div>
          </div>
        </div>
      </section>
      <section className="tw-h-screen tw-w-full tw-flex ">
       
        <div className="tw-w-3/12 md:tw-w-5/12  tw-relative">
          <div className="itimage1"></div>
        </div>
        <div className="tw-w-9/12 md:tw-w-6/12  tw-mt-20">
          <h4 className="txt-blue poppins tw-font-bold 2xl:tw-text-5xl  md:tw-text-4xl tw-text-2xl ">
            ENTERPRISE RESOURCE PLANNING
          </h4>
          <p className="tw-mt-10 2xl:tw-text-2xl  md:tw-text-xl tw-text-sm tw-text-slate-500 rubik">
            In the race to maintain competitive advantage, organizations need to
            embrace digital transformation of their processes to enhance growth
            and value.
          </p>
          <p className="tw-mt-4 2xl:tw-text-2xl  md:tw-text-xl tw-text-sm tw-text-slate-500 rubik">
            Understanding the incumbent and thereafter collaborating with the
            organisation to apply technology thrust to bring about business
            enablers and transform towards upwards progression is the key
            objective of our offering to your enterprise-wide resource planning
            and management.
          </p>
          <p className="tw-mt-4 2xl:tw-text-2xl  md:tw-text-xl tw-text-sm tw-text-slate-500 rubik">
            ur enterprise resource planning services support and enable
            transformation to maximize business value and increase customer
            satisfaction. We offer expertise in designing, developing and
            managing enterprise applications for all the processes that drive
            your business, including supply chain, finance, human resources and
            other business processes. We will bring to you a very Dependable,
            Trusted and Tested solution to your door-step.
          </p>
        </div>
      </section>
      <section className="tw-h-screen tw-w-full tw-flex tw-bg-slate-200 tw-text-right tw-overflow-hidden bleft">
        <div className="tw-w-1/12"></div>
        <div className="tw-w-9/12 md:tw-w-6/12  tw-mt-20">
          <h4 className="txt-blue poppins tw-font-bold 2xl:tw-text-5xl  md:tw-text-4xl tw-text-2xl ">
            DIGITAL ECOMMERCE
          </h4>
          <p className="tw-mt-10 2xl:tw-text-2xl  md:tw-text-xl tw-text-sm tw-text-slate-500 rubik">
            any organizations are still to exploit the full potential of digital
            commerce in their operations. We offer next-generation digital
            commerce systems that integrate multi-channel customer experiences
            and your organization’s operational processes to facilitate rich
            customer relationships in real time. Our systems take full advantage
            of personalization and engagement techniques to deliver proven
            excellence and revenue. We also assist companies in improving their
            marketing function through sophisticated systems and processes that
            enable more efficient acquisition, conversion and retention of
            customers.
          </p>
        </div>
        <div className="tw-w-3/12 md:tw-w-5/12  tw-relative">
          <div className="itimage2"></div>
        </div>
      </section>
      <section className="tw-h-screen tw-w-full tw-flex ">
      <div className="tw-w-3/12 md:tw-w-5/12  tw-relative">
          <div className="itimage3"></div>
        </div>
        <div className="tw-w-9/12 md:tw-w-6/12  tw-mt-20">
          <h4 className="txt-blue poppins tw-font-bold 2xl:tw-text-5xl  md:tw-text-4xl tw-text-2xl ">
            USER EXPERIENCE DESIGN
          </h4>
          <p className="tw-mt-10 2xl:tw-text-2xl  md:tw-text-xl tw-text-sm tw-text-slate-500 rubik">
            Optimized design of user experiences is a great tool to deliver
            customer delight, enhance customer loyalty and increase customer
            value. Based on best-of-breed business performance processes and
            systems, our expert design solutions create positive multi-channel
            customer experiences that are personalized and engaging. These
            solutions are aimed at improving the efficiency of marketing
            operations through better acquisition, conversion and retention of
            customers.
          </p>
        </div>
      </section>
      <section className="tw-h-screen tw-w-full tw-flex tw-bg-slate-200 tw-text-right tw-overflow-hidden bleft">
        <div className="tw-w-1/12"></div>
        <div className="tw-w-9/12 md:tw-w-6/12  tw-mt-20">
          <h4 className="txt-blue poppins tw-font-bold 2xl:tw-text-5xl  md:tw-text-4xl tw-text-2xl ">
            MOBILITY
          </h4>
          <p className="tw-mt-10 2xl:tw-text-2xl  md:tw-text-xl tw-text-sm tw-text-slate-500 rubik">
            There is tremendous potential in enterprise mobile solutions to
            enhance value and manage costs across the value chain. We can help
            design, develop and deploy mobile solutions for information
            management, enterprise applications and mobile computing. Our domain
            expertise and our mobility platform’s processes and apps can deliver
            intelligence and insight at your fingertips to empower
            decision-making in real time.
          </p>
        </div>
        <div className="tw-w-3/12 md:tw-w-5/12  tw-relative">
          <div className="itimage4"></div>
        </div>
      </section>
      <section className="tw-h-screen tw-w-full tw-flex ">
      <div className="tw-w-3/12 md:tw-w-5/12  tw-relative">
          <div className="itimage5"></div>
        </div>
        <div className="tw-w-9/12 md:tw-w-6/12  tw-mt-20">
          <h4 className="txt-blue poppins tw-font-bold 2xl:tw-text-5xl  md:tw-text-4xl tw-text-2xl ">
            COLLABORATION SERVICES
          </h4>
          <p className="tw-mt-10 2xl:tw-text-2xl  md:tw-text-xl tw-text-sm tw-text-slate-500 rubik">
            Collaboration is key to succeeding in today’s business environment.
            Our collaboration services will help you develop the right strategy,
            implement it across the enterprise and enhance your organization’s
            innovation, effectiveness and efficiency. Our team’s solid
            knowledge, technical skills and delivery expertise will help you
            achieve extraordinary results and business value. We also offer Open
            Collaboration Services (OCS) which is vendor-independent modern
            REST-based API for integration of web communities and web-based
            services into desktop and mobile applications.
          </p>
        </div>
      </section>
         <Aboutmeeting/>
          <Discuss/>
          <Footer />
    </div>
  );
};

export default Itsolutions;
