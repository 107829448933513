import React  from "react";
import Aboutmeeting from "../Components/Aboutmeeting";
import Discuss from "../Components/Discuss";
import Footer from "../Components/Footer";
import Header from "../Components/Header";


const Itsupport = () => {
  return (
    <div>
      <Header />
      <section className=" tw-h-screen tw-w-full ">
        <div className="bg-itblueimage  tw-z-10">
          <div className="tw-flex tw-mt-52">
            <div className="tw-flex-auto tw-w-1/5 md:tw-w-2/5 tw-mt-2 md:tw-mt-24 tw-ml-1 md:tw-ml-12 tw-pl-4.5 2xl:tw-mt-44 2xl:tw-ml-28">
              <h4 className="tw-text-white poppins tw-font-extrabold 2xl:tw-text-8xl tw-text-2xl md:tw-text-6xl tracking-wide tw-underline tw-underline-offset-8 tw-ml-4">
                SUPPORT
              </h4>
              <p className="tw-mt-2 md:tw-mt-8 tw-ml-4 md:tw-ml-8 poppins tw-text-yellow-300 tw-text-md md:tw-text-xl 2xl:tw-text-3xl">
                Assert. Assist. Assure.
              </p>
              {/* <button className="tw-text-lg tw-border-2 tw-border-zinc-600 tw-text-zinc-500 tw-rounded-full tw-py-4 tw-px-12 tw-mt-14 tw-cursor-pointer tw-invisible">
                KNOW MORE
              </button> */}
            </div>
            <div className="tw-flex-auto tw-w-3/5 tw-relative">
              <div className="Itsupportbg"></div>
            </div>
          </div>
        </div>
      </section>
      <section className="tw-h-screen tw-w-full tw-flex ">
      <div className="tw-w-3/12 md:tw-w-5/12  tw-relative">
          <div className="itimage1"></div>
        </div>
        <div className="tw-w-9/12 md:tw-w-6/12  tw-mt-20">
          <h4 className="txt-blue poppins tw-font-bold 2xl:tw-text-5xl  md:tw-text-4xl tw-text-2xl ">
            CLOUD HOSTING
          </h4>
          <p className="tw-mt-10 2xl:tw-text-2xl  md:tw-text-xl tw-text-sm tw-text-slate-500 rubik">
            Cloud hosting refers to a type of web hosting service that allows
            businesses and individuals to host their websites or applications on
            a network of virtual servers that are accessed through the internet.
            Unlike traditional hosting services, which rely on physical servers
            located in a specific data center, cloud hosting offers greater
            flexibility, scalability, and reliability.
          </p>
          <p className="tw-mt-4 2xl:tw-text-2xl  md:tw-text-xl tw-text-sm tw-text-slate-500 rubik">
            Key features are Scalability, Reliability, Flexibility,
            Cost-effectiveness and Security. Overall, cloud hosting is a
            powerful and flexible hosting solution that offers many benefits to
            businesses and individuals looking to host their websites or
            applications on the internet. By leveraging the power of cloud
            computing, businesses can enjoy greater scalability, reliability,
            and cost-effectiveness, while minimizing the need for hardware
            investments and IT maintenance.
          </p>
        </div>
      </section>
      <section className="tw-h-screen tw-w-full tw-flex tw-bg-slate-200 tw-text-right tw-overflow-hidden bleft">
        <div className="tw-w-1/12"></div>
        <div className="tw-w-9/12 md:tw-w-6/12  tw-mt-20">
          <h4 className="txt-blue poppins tw-font-bold 2xl:tw-text-5xl  md:tw-text-4xl tw-text-2xl ">
            INFRASTRUCTURE MANAGEMENT
          </h4>
          <p className="tw-mt-10 2xl:tw-text-2xl  md:tw-text-xl tw-text-sm tw-text-slate-500 rubik">
            Infrastructure management is the process of managing the physical
            and virtual components of an organization's IT infrastructure,
            including hardware, software, networks, and data centers. The goal
            of infrastructure management is to ensure that IT resources are used
            effectively and efficiently, and that they support the
            organization's strategic objectives.
          </p>
          <p className="tw-mt-4 2xl:tw-text-2xl  md:tw-text-xl tw-text-sm tw-text-slate-500 rubik">
            Key components include Capacity Planning, Network Management, Server
            Management, Application Management and Data Center Management.
            Overall, infrastructure management is critical to the success of any
            organization's IT operations. By effectively managing their IT
            infrastructure, organizations can ensure that their IT resources are
            used effectively and efficiently, and that they can support the
            organization's strategic objectives. This requires a combination of
            technical expertise, strategic planning, and effective communication
            and collaboration between IT teams and other stakeholders within the
            organization.
          </p>
        </div>
        <div className="tw-w-3/12 md:tw-w-5/12  tw-relative">
          <div className="itimage2"></div>
        </div>
      </section>
      <section className="tw-h-screen tw-w-full tw-flex ">
      <div className="tw-w-3/12 md:tw-w-5/12  tw-relative">
          <div className="itimage3"></div>
        </div>
        <div className="tw-w-9/12 md:tw-w-6/12  tw-mt-20">
          <h4 className="txt-blue poppins tw-font-bold 2xl:tw-text-5xl  md:tw-text-4xl tw-text-2xl ">
            REAL TIME APPLICATION MONITORING
          </h4>
          <p className="tw-mt-10 2xl:tw-text-2xl  md:tw-text-xl tw-text-sm tw-text-slate-500 rubik">
            Real-time application monitoring is the process of tracking and
            analyzing the performance and behavior of software applications in
            real-time. This involves monitoring various metrics and events such
            as response times, error rates, resource utilization, and user
            interactions to identify and diagnose issues that may impact
            application performance or user experience.
          </p>
          <p className="tw-mt-4 2xl:tw-text-2xl  md:tw-text-xl tw-text-sm tw-text-slate-500 rubik">
            Key Features include Automated Monitoring, Real-time Alerts,
            Performance Analytics, User Experience Monitoring and Integration
            with Other Tools. Overall, real-time application monitoring is
            critical to ensuring that software applications are performing
            optimally and delivering a great user experience. By using real-time
            monitoring tools, IT teams can quickly identify and resolve issues,
            optimize performance, and ensure that applications are meeting the
            organization's business needs.
          </p>
        </div>
      </section>
      <section className="tw-h-screen tw-w-full tw-flex tw-bg-slate-200 tw-text-right tw-overflow-hidden bleft">
        <div className="tw-w-1/12"></div>
        <div className="tw-w-9/12 md:tw-w-6/12  tw-mt-20">
          <h4 className="txt-blue poppins tw-font-bold 2xl:tw-text-5xl  md:tw-text-4xl tw-text-2xl ">
            PRODUCTION ENVIRONMENT SUPPORT
          </h4>
          <p className="tw-mt-10 2xl:tw-text-2xl  md:tw-text-xl tw-text-sm tw-text-slate-500 rubik">
            Production environment support refers to the ongoing maintenance and
            support of an organization's production systems and applications.
            The goal of production environment support is to ensure that these
            systems and applications are running smoothly, securely, and
            efficiently, and that they are meeting the organization's business
            needs.
          </p>
          <p className="tw-mt-4 2xl:tw-text-2xl  md:tw-text-xl tw-text-sm tw-text-slate-500 rubik">
            Key components include Capacity System Monitoring, Incident
            Management, Change Management, Security Management and Disaster
            recovery. Overall, production environment support is critical to the
            success of an organization's IT operations. By effectively managing
            production systems and applications, organizations can ensure that
            their IT resources are running smoothly, securely, and efficiently,
            and that they are meeting the organization's business needs. This
            requires a combination of technical expertise, strategic planning,
            and effective communication and collaboration between IT teams and
            other stakeholders within the organization.
          </p>
        </div>
        <div className="tw-w-3/12 md:tw-w-5/12  tw-relative">
          <div className="itimage4"></div>
        </div>
      </section>
      <section className="tw-h-screen tw-w-full tw-flex ">
      <div className="tw-w-3/12 md:tw-w-5/12  tw-relative">
          <div className="itimage5"></div>
        </div>
        <div className="tw-w-9/12 md:tw-w-6/12  tw-mt-20">
          <h4 className="txt-blue poppins tw-font-bold 2xl:tw-text-5xl  md:tw-text-4xl tw-text-2xl ">
            TIERED APPLICATION SUPPORT SERVICES (L1/L2/L3)
          </h4>
          <p className="tw-mt-10 2xl:tw-text-2xl  md:tw-text-xl tw-text-sm tw-text-slate-500 rubik">
            tiered application support services are critical to ensuring that
            applications are running smoothly and that users are receiving the
            support they need. By providing a tiered approach to support,
            organizations can ensure that issues are being resolved quickly and
            efficiently, and that their applications are meeting the needs of
            the business. This requires effective communication and
            collaboration between support teams, as well as a clear
            understanding of the roles and responsibilities of each tier of
            support.
          </p>
        </div>
      </section>
      <section className="tw-h-screen tw-w-full tw-flex tw-bg-slate-200 tw-text-right tw-overflow-hidden bleft">
        <div className="tw-w-1/12"></div>
        <div className="tw-w-9/12 md:tw-w-6/12  tw-mt-20">
          <h4 className="txt-blue poppins tw-font-bold 2xl:tw-text-5xl  md:tw-text-4xl tw-text-2xl ">
            QA/QC AND TESTING
          </h4>
          <p className="tw-mt-10 2xl:tw-text-2xl  md:tw-text-xl tw-text-sm tw-text-slate-500 rubik">
            QA/QC (Quality Assurance/Quality Control) and testing are two
            critical processes in software development that ensure that
            applications are of high quality, reliable, and meet user
            requirements.
          </p>
          <p className="tw-mt-4 2xl:tw-text-2xl  md:tw-text-xl tw-text-sm tw-text-slate-500 rubik">
            Together, QA/QC and testing are critical to ensuring that software
            applications are of high quality and meet user requirements. These
            processes help identify and correct defects early in the development
            cycle, which can save time and costs associated with fixing issues
            later in the development cycle or after the application has been
            released. Additionally, by ensuring that the application meets the
            necessary quality standards, organizations can reduce the risk of
            issues and negative impacts on end-users, reputation, and financial
            stability.
          </p>
        </div>
        <div className="tw-w-3/12 md:tw-w-5/12  tw-relative">
          <div className="itimage6"></div>
        </div>
      </section>
      
       
       <Aboutmeeting/>

       <Discuss/>

      <Footer />
    </div>
  );
};

export default Itsupport;
