import React  from "react";
import logo from "../Images/logo.png";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import Discuss from "../Components/Discuss";
import Aboutmeeting from "../Components/Aboutmeeting";

const Careers = () => {
  return (
    <div>
      <Header />
      <section className=" tw-h-screen carrierbgimgcolor tw-w-full ">
        <div className="bg-itblueimage   tw-z-10">
          <div className="tw-flex tw-mt-52">
            <div className="tw-flex-auto tw-w-1/5 md:tw-w-2/5 tw-mt-2 md:tw-mt-24 tw-ml-1 md:tw-ml-12 tw-pl-4.5 2xl:tw-mt-44 2xl:tw-ml-28">
              <h4 className="tw-text-white poppins tw-font-extrabold 2xl:tw-text-8xl tw-text-2xl md:tw-text-6xl tracking-wide tw-underline tw-underline-offset-8 tw-ml-4">
                CAREERS
              </h4>
              <p className="tw-mt-2 md:tw-mt-8 tw-ml-4 md:tw-ml-8 poppins tw-text-yellow-300 tw-text-md md:tw-text-xl 2xl:tw-text-3xl ">
                Learn. Build. Excel.
              </p>
              {/* <button className="tw-text-lg tw-border-2 tw-border-zinc-600 tw-text-zinc-500 tw-rounded-full tw-py-4 tw-px-12 tw-mt-14 tw-cursor-pointer tw-invisible">
                KNOW MORE
              </button> */}
            </div>
            <div className="tw-flex-auto tw-w-3/5 tw-relative">
              <div className="Itcareerbg "></div>
            </div>
          </div>
        </div>
      </section>
      <section className="tw-h-max tw-w-full carrierbgimgcolor carrierbgheight">
        <div className="careerbgimg">
          <div className="tw-flex tw-justify-end tw-mr-16 2xl:tw-mr-36 ">
            <img
              src={logo}
              alt="Logo..."
              className=" tw-hidden md:tw-block tw-mt-10 logoholder carrierlogo"
            />
          </div>
          <div className="tw-flex tw-mt-48 2xl:tw-mt-36">
            <div className="tw-w-5/12"></div>
            <div className="tw-w-7/12">
              <p className="tw-text-xl md:tw-text-3xl 2xl:tw-text-4xl tw-font-extrabold poppins txt-blue tw-mb-10 rubik">
                {" "}
                5 REASONS{" "}
                <span className="tw-text-slate-500">
                  WHY YOU SHOULD WORK AT
                </span>{" "}
                JST
              </p>
              <p className="tw-text-slate-700 tw-text-base md:tw-text-xl 2xl:text-2xl tw-mb-2 rubik">
                Get to work on the latest technologies as they develop.
              </p>
              <p className="tw-text-slate-700 tw-text-base md:tw-text-xl 2xl:text-2xl tw-mb-2 rubik">
                Work with people who love what they do.
              </p>
              <p className="tw-text-slate-700 tw-text-base md:tw-text-xl 2xl:text-2xl tw-mb-2 rubik">
                Interact daily with people from diverse backgrounds and
                cultures.
              </p>
              <p className="tw-text-slate-700 tw-text-base md:tw-text-xl 2xl:text-2xl tw-mb-2 rubik">
                Keep learning on the job so you never grow stale.
              </p>
              <p className="tw-text-slate-700 tw-text-base md:tw-text-xl 2xl:text-2xl tw-mb-2 rubik">
                Get a chance to give back to the community.
              </p>
            </div>
          </div>
          <div className="tw-flex tw-mt-8 md:tw-mt-20 2xl:tw-mt-36">
            <div className="tw-w-3/12"></div>
            <div className="tw-w-8/12">
              <p className="tw-text-slate-700 tw-text-base md:tw-text-xl 2xl:text-2xl tw-mb-3 rubik">
                We have openings at our India office for qualified and
                experienced professionals in consulting and services. Send your
                CV to <span className="txt-blue">info@jnanasoft.com</span> and
                let’s explore how we can work together.
              </p>
              <p className="tw-text-slate-700 tw-text-base md:tw-text-xl 2xl:text-2xl tw-mb-2 rubik">
                We offer internship opportunities to students and fresh
                graduates throughout the year. Since these places fill up very
                quickly, interested students are advised to submit an internship
                request at least three months before their planned date of
                commencement of internship. Submitting a request is simple: just
                send an email to{" "}
                <span className="txt-blue">info@jnanasoft.com</span> with the
                word ‘Intern’ in the subject line.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-baseblue tw-pt-10 tw-pb-14 tw-px-16  tw-flex tw-flex-wrap md:tw-flex-nowrap px tw-gap-5">
        <div className="tw-w-full md:tw-w-4/12">
          <label className="tw-block tw-mb-3 tw-text-white te-text-xl 2xl:tw-text-2xl tw-ml-2">
            Search by keyword
          </label>
          <input className="tw-w-full tw-rounded-lg p-3" type="text"></input>
        </div>
        <div className="tw-w-full md:tw-w-4/12">
          <label className="tw-block tw-mb-3 tw-text-white te-text-xl 2xl:tw-text-2xl tw-ml-2">
            Search by Location
          </label>
          <input className="tw-w-full tw-rounded-lg p-3" type="text"></input>
        </div>
        <div className="tw-w-full md:tw-w-4/12 tw-self-end tw-mb-1">
          <button className="rubik tw-p-2 tw-border-none tw-text-xl 2xl:tw-text-2xl tw-text-white tw-rounded-xl hover:tw-bg-sky-900">
            SEARCH ALL JOBS
          </button>
        </div>
      </section>
      <section className="tw-bg-slate-100 tw-p-4  md:tw-p-16">
        <div className="tw-flex tw-mb-16">
          <div className="tw-w-8/12 md:tw-w-9/12 tw-text-xl md:tw-text-2xl 2xl:tw-text-3xl tw-font-semibold tw-text-slate-600 rubik">
            {" "}
            TECHNICAL LEAD APPLICATIONS
          </div>
          <div className="tw-w-3/12">
            <button className="rubik tw-p-2 md:tw-p-3 tw-px-8 tw-border-none tw-text-base md:tw-text-xl 2xl:tw-text-2xl bg-baseblue tw-text-white tw-rounded-xl hover:tw-bg-sky-900 tw-shadow-xl tw-shadow-black ">
              APPLY NOW
            </button>
          </div>
        </div>
        <p className="tw-text-base md:tw-text-xl 2xl:tw-text-2xl tw-text-slate-500 tw-mb-2 md:tw-mb-6 rubik">
          {" "}
          Date: Jan 24, 2023
        </p>
        <p className="tw-text-base md:tw-text-xl 2xl:tw-text-2xl tw-text-slate-500 tw-mb-2 md:tw-mb-6 rubik">
          Location: BANGALORE, KA, IN
        </p>
        <p className="tw-text-base md:tw-text-xl 2xl:tw-text-2xl tw-text-slate-500 tw-mb-2 md:tw-mb-6 rubik">
          {" "}
          Company: LTIMindtree
        </p>
        <p className="tw-text-base md:tw-text-xl 2xl:tw-text-2xl tw-text-slate-500 tw-mb-2 md:tw-mb-6 rubik">
          {" "}
          Tech Lead Specialist - API - DotNet core Azure Role Location:
          Bangalored Desired Candidate Profile 6 years of experience on DotNet
          core with Azure Cloud application development and design Must have
          strong knowledge in C , SQL server Must have hands on experience in
          writing Stored procedures. Must have experience in .Net Core and
          WebApi. Good to have knowledge in Azure functions. Good to have
          knowledge on Azure. Problem solving skills learning attitude Key
          Responsibilities Work closely with business analyst, lead to
          understand and deliver on requirements Coding and unit testing and
          review Deployment and Production support Understand the business
          impact of project requirements and ensure adherence to the
          architecture by the technical team Drive technical interactions with
          the customer and team for effective requirement analysis and accurate
          estimation Possess domain knowledge and facilitate creation of
          conceptual prototypes for POCs Follow and improve technical processes,
          guidelines and best practices for the project Ownership of the
          understanding of architecture and non-functional requirements by the
          team Identification and communication of risks and issues
        </p>
        <p className="tw-text-base md:tw-text-xl 2xl:tw-text-2xl tw-text-slate-500 tw-mb-2 md:tw-mb-6 rubik">
          {" "}
          Job Segment: Business Analyst, Database, SQL, Developer, Application
          Developer, Technology
        </p>
      </section>

      <Aboutmeeting />
      <Discuss />
      <Footer />
    </div>
  );
};

export default Careers;
