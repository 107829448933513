import React from "react";

const Aboutmeeting = () => {
  return (
    <section className="aboutmeetimg">
      <div className="tw-flex tw-flex-col">
        <div className="tw-flex-auto tw-flex tw-flex-col tw-p-7">
          <p className="tw-text-white tw-font-semibold tw-text-3xl md:tw-text-6xl  2xl:tw-text-8xl  tw-mb-4 2xl:tw-mb-8">
            Delivery Team
          </p>{" "}
          <p className="txt-pink tw-font-semibold tw-text-base md:tw-text-xl lg:tw-text-2xl 2xl:tw-text-3xl tw-mb-2 2xl:tw-mb-6">
            Global Experience in various Technology Stacks
          </p>{" "}
          <p className="tw-text-white tw-font-semibold tw-text-base md:tw-text-xl lg:tw-text-2xl 2xl:tw-text-3xl tw-mb-2 2xl:tw-mb-6">
            Infrastructure Services, Application Development and Information and
            Cyber Security
          </p>{" "}
          <p className="txt-pink tw-font-semibold tw-text-base md:tw-text-xl lg:tw-text-2xl 2xl:tw-text-3xl tw-mb-2 2xl:tw-mb-6">
            Enterprise Consulting and Build-Deploy-Deliver of Services and
            Solutions
          </p>{" "}
          <p className="txt-pink tw-font-semibold tw-text-base md:tw-text-xl lg:tw-text-2xl 2xl:tw-text-3xl tw-mb-2 2xl:tw-mb-6">
            Experience in Disruptive Technologies
          </p>{" "}
          <p className="tw-text-white tw-font-semibold tw-text-base md:tw-text-xl lg:tw-text-2xl 2xl:tw-text-3xl tw-mb-2 2xl:tw-mb-6">
            Artificial Intelligence, Machine Learning, Robotic Process
            Automation and IoT
          </p>{" "}
        </div>
        <div className="tw-flex-auto tw-flex tw-justify-end ">
          <div class=" tw-px-5 tw-w-full md:tw-w-6/12 tw-h-2/6">
            <div class="tw-flex tw-flex-wrap tw-mt-6 ">
              <div class="tw-py-2   tw-px-6 2xl:tw-p-6 lg:tw-w-1/3 md:tw-w-1/3 tw-w-full ">
                <div class=" tw-bg-opacity-75 wcardBorder tw-rounded-3xl tw-text-center tw-relative">
                  <div className=" tw-flex tw-justify-start tw-ml-8">
                    <span className=" tw-text-pink-500 tw-text-4xl md:tw-text-6xl 2xl:tw-text-8xl tw-font-bold">
                      10
                    </span>
                    <span className="tw-text-4xl md:tw-text-6xl tw-font-bold tw-text-white">
                      +
                    </span>
                  </div>
                  <div className="tw-text-lg 2xl:tw-text-3xl  tw-flex tw-justify-start tw-font-semibold  tw-text-white tw-ml-8">
                    In-House
                  </div>
                  <div className=" tw-flex tw-justify-center tw-mx-4 tw-font-semibold tw-mt-4 2xl:tw-mt-12  tw-text-white tw-mb-10">
                    Technology Leader and Domain Experts
                  </div>
                </div>
              </div>
              <div class="tw-py-2   tw-px-6 2xl:tw-p-6 lg:tw-w-1/3 md:tw-w-1/3 tw-w-full ">
                <div class=" tw-bg-opacity-75 wcardBorder tw-rounded-3xl tw-text-center tw-relative">
                  <div className=" tw-flex tw-justify-start tw-ml-8">
                    <span className=" tw-text-pink-500 tw-text-4xl md:tw-text-6xl 2xl:tw-text-8xl tw-font-bold">
                      20
                    </span>
                    <span className="tw-text-4xl md:tw-text-6xl tw-font-bold tw-text-white">
                      +
                    </span>
                  </div>
                  <div className="tw-text-lg 2xl:tw-text-3xl  tw-flex tw-justify-start tw-font-semibold  tw-text-white tw-ml-8">
                    Partners
                  </div>
                  <div className=" tw-flex tw-justify-center tw-mx-4 tw-font-semibold xl:tw-text-sm tw-text-white tw-mt-4 2xl:tw-mt-12 xl:tw-mb-8 tw-mb-4">
                    Technology Architects, Subject MatterExperts and Support
                    Specialists
                  </div>
                </div>
              </div>
              <div class="tw-pt-2 tw-pb-4   tw-px-6 2xl:tw-p-6 lg:tw-w-1/3 md:tw-w-1/3 tw-w-full ">
                <div class=" tw-bg-opacity-75 wcardBorder tw-rounded-3xl tw-text-center tw-relative">
                  <div className=" tw-flex tw-justify-start tw-ml-8">
                    <span className=" tw-text-pink-500 tw-text-4xl md:tw-text-6xl 2xl:tw-text-8xl tw-font-bold">
                      30
                    </span>
                    <span className="tw-text-4xl md:tw-text-6xl tw-font-bold tw-text-white">
                      +
                    </span>
                  </div>
                  <div className="tw-text-lg 2xl:tw-text-3xl  tw-flex tw-justify-start tw-font-semibold  tw-text-white tw-ml-2">
                    Vendors & OEM’S
                  </div>
                  <div className=" tw-flex tw-justify-center tw-mx-4 tw-font-semibold  tw-text-white tw-mt-4 2xl:tw-mt-12 tw-mb-10">
                    Product Specialist and Deployment Experts
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Aboutmeeting;
