import React from "react";
import Aboutmeeting from "../Components/Aboutmeeting";
import Discuss from "../Components/Discuss";
import Footer from "../Components/Footer";
import Header from "../Components/Header";


const Itconsulting = () => {
  return (
    <div>
      <Header />
      <section className=" tw-h-screen tw-w-full ">
      <div className="bg-itblueimage  tw-z-10">
          <div className="tw-flex tw-mt-52">
            <div className="tw-flex-auto tw-w-1/5 md:tw-w-2/5 tw-mt-2 md:tw-mt-24 tw-ml-1 md:tw-ml-12 tw-pl-4.5 2xl:tw-mt-44 2xl:tw-ml-28">
              <h4 className="tw-text-white poppins tw-font-extrabold 2xl:tw-text-8xl tw-text-2xl md:tw-text-6xl tracking-wide tw-underline tw-underline-offset-8 tw-ml-4">
                CONSULTING
              </h4>
              <p className="tw-mt-2 md:tw-mt-8 tw-ml-4 md:tw-ml-8 poppins tw-text-yellow-300 tw-text-md md:tw-text-xl 2xl:tw-text-3xl ">
                Innovate. Transform. Succeed.
              </p>
            </div>
            <div className="tw-flex-auto tw-w-3/5 tw-relative">
              <div className="consultinglandingpic"></div>
            </div>
          </div>
        </div>
      </section>
      <section className="tw-h-screen tw-w-full tw-flex ">
        <div className="tw-w-3/12 md:tw-w-5/12  tw-relative">
          <div className="itimage1"></div>
        </div>
        <div className="tw-w-9/12 md:tw-w-6/12  tw-mt-20">
          <h4 className="txt-blue poppins tw-font-bold 2xl:tw-text-5xl  md:tw-text-4xl tw-text-2xl ">
            PROGRAM MANAGEMENT
          </h4>
          <p className="tw-mt-10 2xl:tw-text-2xl  md:tw-text-xl tw-text-sm tw-text-slate-500 rubik">
            When your business is ready for technology-enabled transformation,
            it helps to have experience consultants on board to define and
            deliver program management strategies that empower your business
            ambitions. Ensuring and bringing a blend of industry best practices,
            creative approach and innovation in planning, organizing, directing,
            and controlling of resources to achieve strategic and tactical goals
            for Technology Transformation. Ensure absolute success and business
            direction. Our consultants will help design transformation roadmaps,
            implement strategies, and provide both program and project
            management. Where required, we will also work with other
            implementation partners to ensure quality assurance and total
            effectiveness of your transformation program.
          </p>
        </div>
      </section>
      <section className="tw-h-screen tw-w-full tw-flex tw-bg-slate-200 tw-text-right tw-overflow-hidden bleft">
        <div className="tw-w-1/12"></div>
        <div className="tw-w-9/12 md:tw-w-6/12  tw-mt-20">
          <h4 className="txt-blue poppins tw-font-bold 2xl:tw-text-5xl  md:tw-text-4xl tw-text-2xl ">
            TRANSFORMATION
          </h4>
          <p className="tw-mt-10 2xl:tw-text-2xl  md:tw-text-xl tw-text-sm tw-text-slate-500 rubik">
            Today’s business environment is constantly changing, which makes
            business transformation an imperative rather than an option. Our
            services begin with identifying changes that could impact your
            business. Then we chart out strategies and programs to help your
            organization take advantage of the evolving environment in a focused
            and sustainable way. Our services include establishing change
            management programs to implement complex transformation strategies
            and providing advice in managing programs implemented by other
            partners.
          </p>
        </div>
        <div className="tw-w-3/12 md:tw-w-5/12  tw-relative">
          <div className="itimage2"></div>
        </div>
      </section>
      <section className="tw-h-screen tw-w-full tw-flex ">
      
        <div className="tw-w-3/12 md:tw-w-5/12  tw-relative">
          <div className="itimage3"></div>
        </div>
        <div className="tw-w-9/12 md:tw-w-6/12  tw-mt-20">
          <h4 className="txt-blue poppins tw-font-bold 2xl:tw-text-5xl  md:tw-text-4xl tw-text-2xl ">
            BUSINESS PROCESS AUTOMATION AND OPTIMIZATION
          </h4>
          <p className="tw-mt-10 2xl:tw-text-2xl  md:tw-text-xl tw-text-sm tw-text-slate-500 rubik">
            There is a shift change in the way modern business are operating.
            One of most common things which is widely seen is the cost cutting,
            which includes humans as well as the legacy processes which are
            outdated and don’t coup up with the change. Many companies who adapt
            modern techniques such as process automation and resource
            optimization survive the situations like pandemic. We at Jnana
            provides expertise in process optimization by using Robotic Process
            Automation (RPA), a modern technology that utilises pre-defined
            business (processes) logics, set of established rules and structure
            the data to automate business processes.
          </p>
          <p className="tw-mt-4 2xl:tw-text-2xl  md:tw-text-xl tw-text-sm tw-text-slate-500 rubik">
            Software robots built on RPA are capable to capture and interpret
            applications for processing a transaction, manipulating and updating
            the data, triggering responses and communicating with other digital
            systems. We think that with RPA the labor-intensive knowledge work
            will be done within a fraction of a second.
          </p>
        </div>
      </section>
      <section className="tw-h-screen tw-w-full tw-flex tw-bg-slate-200 tw-text-right tw-overflow-hidden bleft">
        <div className="tw-w-1/12"></div>
        <div className="tw-w-9/12 md:tw-w-6/12  tw-mt-20">
          <h4 className="txt-blue poppins tw-font-bold 2xl:tw-text-5xl  md:tw-text-4xl tw-text-2xl ">
            SERVICE MANAGEMENT
          </h4>
          <p className="tw-mt-10 2xl:tw-text-2xl  md:tw-text-xl tw-text-sm tw-text-slate-500 rubik">
            In the evolving technological world, we can build holistic framework
            and thereby enable and support you in building an efficient,
            interrelated and integrated process for delivery and support of IT
            services for your organization. The goal is to transform your
            organization agile. Align your tools, people and processes to
            maximize the value of your IT operations. Refine existing or select
            and implement new service management tools, establish practical
            configuration and asset management capabilities. Enhance your
            agility to deliver and support valued services, and ensure your
            governance structure drives continual improvement.
          </p>
        </div>
      
        <div className="tw-w-3/12 md:tw-w-5/12  tw-relative">
          <div className="itimage4"></div>
        </div>
      </section>
      <section className="tw-h-screen tw-w-full tw-flex ">
     
        <div className="tw-w-3/12 md:tw-w-5/12  tw-relative">
          <div className="itimage5"></div>
        </div>
        <div className="tw-w-9/12 md:tw-w-6/12  tw-mt-20">
          <h4 className="txt-blue poppins tw-font-bold 2xl:tw-text-5xl  md:tw-text-4xl tw-text-2xl ">
            QUALITY ASSURANCE
          </h4>
          <p className="tw-mt-10 2xl:tw-text-2xl  md:tw-text-xl tw-text-sm tw-text-slate-500 rubik">
            There is a growing emphasis on the need for quality assurance
            systems to add value and integrate with business objectives. Quality
            assurance and customer satisfaction are inextricably interconnected.
            This means that QA must go beyond testing for functional and
            business requirements and take into account impact on customer
            needs, behaviors and relationships. We understand best practices and
            standards and thereby can craft your QA program to achieve a host of
            benefits, including reduced costs, dramatically increased
            efficiencies and, ultimately, greatly enhanced customer satisfaction
            index. Our QA consultants can offer an enterprise-wide review of
            quality assurance strategies and processes to establish standards of
            excellence and identify areas for improvement. This helps in
            ensuring seamless, value-driven delivery across channels, besides
            reducing costs and increasing customer delight
          </p>
        </div>
      </section>
      <section className="tw-h-screen tw-w-full tw-flex tw-bg-slate-200 tw-text-right tw-overflow-hidden bleft">
        <div className="tw-w-1/12"></div>
        <div className="tw-w-9/12 md:tw-w-6/12  tw-mt-20">
          <h4 className="txt-blue poppins tw-font-bold 2xl:tw-text-5xl  md:tw-text-4xl tw-text-2xl ">
            GOVERNANCE, RISK & COMPLIANCE
          </h4>
          <p className="tw-mt-10 2xl:tw-text-2xl  md:tw-text-xl tw-text-sm tw-text-slate-500 rubik">
            Developing an integrated cycle of governance, risk and compliance
            (GRC) is critical to any large organization. Our GRC consulting
            services assist companies in establishing governance frameworks,
            evaluating risk and ensuring compliance.
          </p>
          <p className="tw-mt-4 2xl:tw-text-2xl  md:tw-text-xl tw-text-sm tw-text-slate-500 rubik">
            Through strategic partnerships, we are able to offer the world’s
            leading security, compliance and risk assessment solutions. The
            result is improved understanding of risk-prone aspects of business
            and proactive measures to minimize vulnerabilities and improve
            operational efficiencies.
          </p>
        </div>
        <div className="tw-w-3/12 md:tw-w-5/12  tw-relative">
          <div className="itimage6"></div>
        </div>
      
      </section>
      <section className="tw-h-screen tw-w-full tw-flex ">
        
        <div className="tw-w-3/12 md:tw-w-5/12  tw-relative">
          <div className="itimage7"></div>
        </div>
        <div className="tw-w-9/12 md:tw-w-6/12  tw-mt-20">
          <h4 className="txt-blue poppins tw-font-bold 2xl:tw-text-5xl  md:tw-text-4xl tw-text-2xl ">
            INFORMATION STRATEGY & GOVERNANCE
          </h4>
          <p className="tw-mt-10 2xl:tw-text-2xl  md:tw-text-xl tw-text-sm tw-text-slate-500 rubik">
            Recent years have seen a growing appreciation by companies of the
            value of data and knowledge that reside within their organizations
            and value chains.
          </p>
          <p className="tw-mt-4 2xl:tw-text-2xl  md:tw-text-xl tw-text-sm tw-text-slate-500 rubik">
            We help companies develop information strategies that utilize their
            data and insights for more effective decision-making. We specialize
            in developing holistic strategies that align information strategy to
            business objectives, and creating roadmaps that enable streamlined
            governance of information architecture
          </p>
        </div>
      </section>
      <Aboutmeeting />
      <Discuss/>
      <Footer />
    </div>
  );
};

export default Itconsulting;
