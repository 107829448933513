import React from "react";

const Discuss = () => {
  return (
    <div className="tw-bg-white">
      <div className="tw-flex-auto tw-self-center tw-text-center tw-text-slate-500 tw-poppins tw-text-4xl md:tw-text-7xl  tw-font-extrabold tw-poppins tw-tracking-wide">
        Lets Discuss Your Project
      </div>

      <div class="tw-flex tw-flex-wrap tw-mt-8 tw-mx-6  md:tw-mx-32">
        <div class="tw-p-4 lg:tw-w-1/3 md:tw-w-1/3 tw-w-full ">
          <input
            type="email"
            id="helper-text"
            aria-describedby="helper-text-explanation"
            class="tw-bg-stone-white tw-border-2 tw-border-slate-500 tw-text-gray-900 tw-text-sm focus:tw-ring-stone-500 focus:tw-border-stone-700 tw-block tw-w-full tw-p-4 dark:tw-bg-stone-300 dark:tw-border-slate-500 dark:tw-placeholder-gray-500 dark:tw-text-gray-900 dark:focus:tw-ring-stone-500 dark:focus:tw-border-stone-700 tw-rounded-2xl"
            placeholder="Name"
          />
        </div>
        <div class="tw-p-4 lg:tw-w-1/3 md:tw-w-1/3 tw-w-full ">
          <input
            type="email"
            id="helper-text"
            aria-describedby="helper-text-explanation"
            class="tw-bg-stone-white tw-border-2 tw-border-slate-500 tw-text-gray-900 tw-text-sm focus:tw-ring-stone-500 focus:tw-border-stone-700 tw-block tw-w-full tw-p-4 dark:tw-bg-stone-300 dark:tw-border-slate-500 dark:tw-placeholder-gray-500 dark:tw-text-gray-900 dark:focus:tw-ring-stone-500 dark:focus:tw-border-stone-700 tw-rounded-2xl"
            placeholder="Email"
          />
        </div>
        <div class="tw-p-4 lg:tw-w-1/3 md:tw-w-1/3 tw-w-full ">
          <input
            type="email"
            id="helper-text"
            aria-describedby="helper-text-explanation"
            class="tw-bg-stone-white tw-border-2 tw-border-slate-500 tw-text-gray-900 tw-text-sm focus:tw-ring-stone-500 focus:tw-border-stone-700 tw-block tw-w-full tw-p-4 dark:tw-bg-stone-300 dark:tw-border-slate-500 dark:tw-placeholder-gray-500 dark:tw-text-gray-900 dark:focus:tw-ring-stone-500 dark:focus:tw-border-stone-700 tw-rounded-2xl"
            placeholder="Mobile"
          />
        </div>
      </div>

      <div class="tw-flex tw-flex-wrap tw-mt-2 tw-mx-6 md:tw-mx-60">
        <div class="tw-p-4 tw-w-full">
          <textarea
            id="message"
            rows="4"
            class=" tw-border-2 tw-border-slate-500 tw-text-gray-900 tw-text-sm focus:tw-ring-stone-500 focus:tw-border-stone-700 tw-block tw-w-full tw-p-4 dark:tw-bg-stone-300 dark:tw-border-slate-500 dark:tw-placeholder-gray-500 dark:tw-text-gray-900 dark:focus:tw-ring-stone-500 dark:focus:tw-border-stone-700 tw-rounded-2xl"
            placeholder="Write your thoughts here..."
          ></textarea>
        </div>
      </div>

      <div className="tw-flex justify-content-center tw-w-full ">
        <button className="2xl:tw-text-xl  tw-text-md tw-border-2 tw-border-zinc-600 tw-text-zinc-500 tw-rounded-2xl tw-py-3 tw-px-8 tw-mt-4 tw-mb-12 tw-cursor-pointer ">
          Send Your Message
        </button>
      </div>
    </div>
  );
};

export default Discuss;
