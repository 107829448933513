import React  from "react";
import Aboutmeeting from "../Components/Aboutmeeting";
import Discuss from "../Components/Discuss";
import Footer from "../Components/Footer";
import Header from "../Components/Header";


const Itservices = () => {
  return (
    <div>
      <Header />
      <section className=" tw-h-screen tw-w-full ">
        <div className="bg-itblueimage  tw-z-10">
          <div className="tw-flex tw-mt-52">
            <div className="tw-flex-auto tw-w-1/5 md:tw-w-2/5 tw-mt-2 md:tw-mt-24 tw-ml-1 md:tw-ml-12 tw-pl-4.5 2xl:tw-mt-44 2xl:tw-ml-28">
              <h4 className="tw-text-white poppins tw-font-extrabold 2xl:tw-text-8xl tw-text-2xl md:tw-text-6xl tracking-wide tw-underline tw-underline-offset-8 tw-ml-4">
                SERVICES
              </h4>
              <p className="tw-mt-2 md:tw-mt-8 tw-ml-4 md:tw-ml-8 poppins tw-text-yellow-300 tw-text-md md:tw-text-xl 2xl:tw-text-3xl ">
                Integrate. Consolidate. Transcend.
              </p>
              {/* <button className="tw-text-lg tw-border-2 tw-border-zinc-600 tw-text-zinc-500 tw-rounded-full tw-py-4 tw-px-12 tw-mt-14 tw-cursor-pointer tw-invisible">
                KNOW MORE
              </button> */}
            </div>
            <div className="tw-flex-auto tw-w-3/5 tw-relative">
              <div className="Itservicebg"></div>
            </div>
          </div>
        </div>
      </section>
      <section className="tw-h-screen tw-w-full tw-flex tw-bg-slate-200 bright">
      <div className="tw-w-3/12 md:tw-w-5/12  tw-relative">
          <div className="itimage1"></div>
        </div>
        <div className="tw-w-9/12 md:tw-w-6/12  tw-mt-20">
          <h4 className="txt-blue poppins tw-font-bold 2xl:tw-text-5xl  md:tw-text-4xl tw-text-2xl ">
            APPLICATION DEVELOPMENT
          </h4>
          <p className="tw-mt-10 2xl:tw-text-2xl  md:tw-text-xl tw-text-sm tw-text-slate-500 rubik">
            Organizations worldwide are turning to enterprise applications to
            transform the way they do business with their customers. These
            applications help to transform customer relationships, create
            additional sources of revenue and control operational costs.
            Application failure or unexpected downtime can frustrate end users
            and devastate bottom line. As budgets shrink and application
            environments grow more complex, it pays to have an experienced IT
            partner.
          </p>
          <p className="tw-mt-4 2xl:tw-text-2xl  md:tw-text-xl tw-text-sm tw-text-slate-500 rubik">
            Our application development teams offer high-end expertise in
            developing, deploying and managing enterprise applications that
            achieve your business goals. These applications can also be built on
            your legacy business platforms, to reduce costs, improve time to
            market and achieve a smoother transition.
          </p>
        </div>
      </section>
      <section className="tw-h-screen tw-w-full tw-flex tw-text-left tw-overflow-hidden ">
        <div className="tw-w-1/12"></div>
        <div className="tw-w-9/12 md:tw-w-6/12  tw-mt-20">
          <h4 className="txt-blue poppins tw-font-bold 2xl:tw-text-5xl  md:tw-text-4xl tw-text-2xl ">
            PRODUCT/TECHNOLOGY EVALUATION
          </h4>
          <p className="tw-mt-10 2xl:tw-text-2xl  md:tw-text-xl tw-text-sm tw-text-slate-500 rubik">
            The importance of product/technology evaluation cannot be
            overstated, as it can help businesses and organizations make better
            decisions about how to allocate their resources, which
            products/technologies to pursue, and how to improve their existing
            offerings. Additionally, it can help to identify potential risks or
            shortcomings of a product/technology, allowing companies to address
            them before they become major issues.
          </p>
          <p className="tw-mt-4 2xl:tw-text-2xl  md:tw-text-xl tw-text-sm tw-text-slate-500 rubik">
            Overall, product/technology evaluation is a critical step in the
            development and implementation of any new product or technology, and
            it requires a thorough and systematic approach to ensure that all
            aspects of the product/technology are thoroughly analyzed and
            evaluated.
          </p>
        </div>
        <div className="tw-w-3/12 md:tw-w-5/12  tw-relative">
          <div className="itimage2"></div>
        </div>
      </section>
      <section className="tw-h-screen tw-w-full tw-flex  tw-bg-slate-200 bright">
      <div className="tw-w-3/12 md:tw-w-5/12  tw-relative">
          <div className="itimage3"></div>
        </div>
        <div className="tw-w-9/12 md:tw-w-6/12  tw-mt-20">
          <h4 className="txt-blue poppins tw-font-bold 2xl:tw-text-5xl  md:tw-text-4xl tw-text-2xl ">
            SYSTEM INTEGRATION
          </h4>
          <p className="tw-mt-10 2xl:tw-text-2xl  md:tw-text-xl tw-text-sm tw-text-slate-500 rubik">
            he typical enterprise is a heterogeneous and multi-vendor
            environment, where the challenge is to ensure availability and adapt
            to evolving technologies. Our system integration services help you
            reduce complexity and costs, making sure that your IT infrastructure
            is modernized, scalable, future-ready and cost-efficient in
            achieving your goals of simplicity, agility and reliability. Our
            services include streamlining technical architecture, formulating
            security, simplifying end-user computing, optimizing data storage
            and access, and enterprise infrastructure services.
          </p>
        </div>
      </section>
      <section className="tw-h-screen tw-w-full tw-flex tw-text-left tw-overflow-hidden ">
        <div className="tw-w-1/12"></div>
        <div className="tw-w-9/12 md:tw-w-6/12  tw-mt-20">
          <h4 className="txt-blue poppins tw-font-bold 2xl:tw-text-5xl  md:tw-text-4xl tw-text-2xl ">
            API INTEGRATION
          </h4>
          <p className="tw-mt-10 2xl:tw-text-2xl  md:tw-text-xl tw-text-sm tw-text-slate-500 rubik">
            API integration refers to the process of connecting two or more
            software systems through the use of Application Programming
            Interfaces (APIs). APIs are sets of protocols, routines, and tools
            for building software applications that define how different
            software components should interact with each other.
          </p>
          <p className="tw-mt-4 2xl:tw-text-2xl  md:tw-text-xl tw-text-sm tw-text-slate-500 rubik">
            API integration offers several benefits, including improved
            efficiency, reduced errors, and increased scalability. By leveraging
            existing APIs, developers can save time and resources that would
            otherwise be spent on building new functionality from scratch.
            Additionally, API integration can help organizations streamline
            their workflows, automate tasks, and improve data accuracy and
            consistency.
          </p>
        </div>
        <div className="tw-w-3/12 md:tw-w-5/12  tw-relative">
          <div className="itimage4"></div>
        </div>
      </section>
      <section className="tw-h-screen tw-w-full tw-flex tw-bg-slate-200 bright">
      <div className="tw-w-3/12 md:tw-w-5/12  tw-relative">
          <div className="itimage5"></div>
        </div>
        <div className="tw-w-9/12 md:tw-w-6/12  tw-mt-20">
          <h4 className="txt-blue poppins tw-font-bold 2xl:tw-text-5xl  md:tw-text-4xl tw-text-2xl ">
            IT STRATEGY & ROAD MAP
          </h4>
          <p className="tw-mt-10 2xl:tw-text-2xl  md:tw-text-xl tw-text-sm tw-text-slate-500 rubik">
            An IT strategy and road map is a comprehensive plan that outlines
            how an organization will use information technology (IT) to achieve
            its business goals and objectives. The strategy and road map
            typically include a long-term vision for IT, as well as a detailed
            plan for how to achieve that vision over time.
          </p>
          <p className="tw-mt-4 2xl:tw-text-2xl  md:tw-text-xl tw-text-sm tw-text-slate-500 rubik">
            Business Alignment: The first step is to align the IT strategy and
            road map with the overall business strategy and goals of the
            organization. This involves identifying how IT can support the
            business, identifying opportunities for IT to create value, and
            determining how to measure the success of IT initiatives.
            Assessment: The next step is to assess the current state of the
            organization's IT systems, infrastructure, and processes. This
            involves identifying strengths, weaknesses, opportunities, and
            threats in the organization's IT environment. Overall, an IT
            strategy and road map is a critical component of modern business
            planning, helping organizations to align their IT investments with
            their business goals and achieve greater efficiency, effectiveness,
            and competitiveness.
          </p>
        </div>
      </section>
      <section className="tw-h-screen tw-w-full tw-flex tw-text-left tw-overflow-hidden ">
        <div className="tw-w-1/12"></div>
        <div className="tw-w-9/12 md:tw-w-6/12  tw-mt-20">
          <h4 className="txt-blue poppins tw-font-bold 2xl:tw-text-5xl  md:tw-text-4xl tw-text-2xl ">
            MIGRATION PORTING
          </h4>
          <p className="tw-mt-10 2xl:tw-text-2xl  md:tw-text-xl tw-text-sm tw-text-slate-500 rubik">
            Migration porting is the process of moving an application, software
            system, or IT infrastructure from one platform, operating system, or
            environment to another. This can include moving from on-premises
            systems to the cloud, upgrading to a newer version of an operating
            system, or switching from one programming language or framework to
            another.
          </p>
          <p className="tw-mt-4 2xl:tw-text-2xl  md:tw-text-xl tw-text-sm tw-text-slate-500 rubik">
            Steps involved are Planning,Analysis,Development,Testing and
            Deployment . Overall, migration porting is a complex and challenging
            process that requires careful planning, analysis, and execution. It
            is critical to ensure that the new system or application works as
            expected and that any risks or compatibility issues are addressed
            before deployment. When done correctly, migration porting can help
            organizations take advantage of new technologies, improve
            performance, and reduce costs.
          </p>
        </div>
        <div className="tw-w-3/12 md:tw-w-5/12  tw-relative">
          <div className="itimage6"></div>
        </div>
      </section>
      <section className="tw-h-screen tw-w-full tw-flex tw-bg-slate-200 ">
      <div className="tw-w-3/12 md:tw-w-5/12  tw-relative">
          <div className="itimage7"></div>
        </div>
        <div className="tw-w-9/12 md:tw-w-6/12  tw-mt-20">
          <h4 className="txt-blue poppins tw-font-bold 2xl:tw-text-5xl  md:tw-text-4xl tw-text-2xl ">
            RESOURCE DEPLOYMENT
          </h4>
          <p className="tw-mt-10 2xl:tw-text-2xl  md:tw-text-xl tw-text-sm tw-text-slate-500 rubik">
            Resource deployment refers to the process of assigning and
            allocating resources, such as personnel, equipment, and funding, to
            various projects or tasks within an organization. The goal of
            resource deployment is to ensure that resources are used effectively
            and efficiently, and that the organization can achieve its strategic
            objectives.
          </p>
          <p className="tw-mt-4 2xl:tw-text-2xl  md:tw-text-xl tw-text-sm tw-text-slate-500 rubik">
            Steps involved are Resource Planning, Resource Allocation, Resource
            Monitoring and Resource Optimization. Overall, resource deployment
            is a critical component of effective project management and
            organizational success. By effectively managing and deploying
            resources, organizations can improve their efficiency, productivity,
            and performance, and ensure that they are able to achieve their
            goals in a timely and cost-effective manner.
          </p>
        </div>
      </section>

      <Aboutmeeting/>

      <Discuss/>

      <Footer />
    </div>
  );
};

export default Itservices;
