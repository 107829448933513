import React  from "react";
import Footer from "../Components/Footer";
import Header from "../Components/Header";

const PrivacyPolicy = () => {
  return (
    <div className="tw-overflow-hidden ">
      <Header />
      <div className="tw-mt-36 tw-mx-5">
        <p className="tw-text-bold tw-text-4xl tw-mb-5">Privacy Policy</p>
        <p className="rubik tw-mb-4">
          This Privacy Policy describes how Jnana Software Technologies, Pvt Ltd
          and its affiliates ("Jnana Software Technologies," "we," "our" or
          "us") collect, use, and share information in connection with your use
          of our websites (including www.Jnanasoft.com), services, and
          applications (collectively, the "Services"). This Privacy Policy (the
          "Privacy Policy") does not apply to information our customers may
          process when using our Services.
        </p>
        <p className="rubik tw-mb-4">
          We may collect and receive information about users of our Services
          ("users," "you," or "your") from various sources, including: (i)
          information you provide through your user account on the Services
          (your "Account") if you register for the Services; (ii) your use of
          the Services; and (iii) from third-party websites, services, and
          partners.
        </p>
        <p className="rubik tw-mb-4">
          We recommend that you read this Privacy Policy in full, including the
          Additional Disclosures referenced at the bottom of this document, to
          ensure you are fully informed. If you have any questions about this
          Privacy Policy or Jnana software’s data collection, use, and
          disclosure practices, please contact us at{" "}
          <span className="tw-font-semibold">infosec@Jnanasoft.com</span>
        </p>

        <p className="tw-text-bold tw-text-3xl tw-mb-4">
          1. Information We Collect
        </p>
        <p className="tw-text-semibold tw-text-xl tw-mb-2 rubik">
          1. Information You Provide
        </p>
        <p className="tw-text-semibold rubik tw-mb-2">
          <span className="tw-font-semibold">I. Account Registration.</span>{" "}
          When you register for an Account, we may ask for your contact
          information, including items such as name, company name, address,
          email address, and telephone number. If you choose to refer a friend
          to our Services, we may also collect your friend’s email address so
          that we may send them a referral or promotional code to sign up for
          our Services.
        </p>
        <p className="tw-text-semibold rubik tw-mb-2">
          <span className="tw-font-semibold">II. Payment Information.</span>{" "}
          When you add your financial account information to your Account, that
          information is directed to our third-party payment processor. We do
          not store your financial account information on our systems; however,
          we have access to, and may retain, subscriber information through our
          third-party payment processor.
        </p>
        <p className="tw-text-semibold rubik tw-mb-2">
          <span className="tw-font-semibold">III. User Content.</span> Our
          "Community" feature allows you to publicly post content on our
          Services. By registering for our Community, you agree that your
          profile information and the content you post may be viewed and used by
          other users and third parties we do not control.
        </p>
        <p className="tw-text-semibold rubik tw-mb-2">
          <span className="tw-font-semibold">IV. Communications.</span>If you
          contact us directly, we may receive additional information about you
          such as your name, email address, phone number, and the contents of
          the message and/or attachments you may send us, and any other
          information you may choose to provide. We may also receive a
          confirmation when you open an email from us.
        </p>
        <p className="tw-text-semibold rubik tw-mb-4">
          The personal information that you are asked to provide, and the
          reasons why you are asked to provide it, will be made clear to you at
          the point we ask you to provide your personal information.
        </p>
        <p className="tw-text-semibold tw-text-xl tw-mb-2 rubik">
          2. Information We Collect When You Use Our Services.
        </p>
        <p className="tw-text-semibold rubik tw-mb-2">
          <span className="tw-font-semibold">
            I. Cookies and Other Tracking Technologies.
          </span>
          As is true of most websites, we gather certain information
          automatically and store it in log files. In addition, when you use our
          Services, we may collect certain information automatically from your
          device. This information may include internet protocol (IP) addresses,
          browser type, internet service provider (ISP), referring/exit pages,
          operating system, date/time stamp, clickstream data, landing page, and
          referring URL. To collect this information, a cookie may be set on
          your computer or device when you visit our Services. Cookies contain a
          small amount of information that allows our web servers to recognize
          you. We store information that we collect through cookies, log files,
          and/or clear gifs to record your preferences. We may also
          automatically collect information about your use of features of our
          Services, about the functionality of our Services, frequency of
          visits, and other information related to your interactions with the
          Services. We may track your use across different websites and
          services. In some countries, including countries in the European
          Economic Area ("EEA"), the information referenced above in this
          paragraph may be considered personal information under applicable data
          protection laws.
        </p>
        <p className="tw-text-semibold rubik tw-mb-4">
          <span className="tw-font-semibold">II. Usage of our Services.</span>
          When you use our Services, we may collect information about your
          engagement with and utilization of our Services, such as processor and
          memory usage, storage capacity, navigation of our Services, and
          system-level metrics. We use this data to operate the Services,
          maintain and improve the performance and utilization of the Services,
          develop new features, protect the security and safety of our Services
          and our customers, and provide customer support. We also use this data
          to develop aggregate analysis and business intelligence that enable us
          to operate, protect, make informed decisions, and report on the
          performance of our business.
        </p>
        <p className="tw-text-semibold tw-text-xl tw-mb-2 rubik">
          3. Information We Receive from Third Parties.
        </p>
        <p className="tw-text-semibold rubik tw-mb-4">
          <span className="tw-font-semibold">
            I. Cookies and Other Tracking Technologies.
          </span>
          As is true of most websites, we gather certain information
          automatically and store it in log files. In addition, when you use our
          Services, we may collect certain information automatically from your
          device. This information may include internet protocol (IP) addresses,
          browser type, internet service provider (ISP), referring/exit pages,
          operating system, date/time stamp, clickstream data, landing page, and
          referring URL. To collect this information, a cookie may be set on
          your computer or device when you visit our Services. Cookies contain a
          small amount of information that allows our web servers to recognize
          you. We store information that we collect through cookies, log files,
          and/or clear gifs to record your preferences. We may also
          automatically collect information about your use of features of our
          Services, about the functionality of our Services, frequency of
          visits, and other information related to your interactions with the
          Services. We may track your use across different websites and
          services. In some countries, including countries in the European
          Economic Area ("EEA"), the information referenced above in this
          paragraph may be considered personal information under applicable data
          protection laws.
        </p>
        <p className="tw-text-bold tw-text-3xl tw-mb-2">
          2. How We Use Information
        </p>
        <p className="  tw-mb-2">
          We use the information we collect in various ways, including to:
        </p>
        <ul className="tw-mb-4">
          <li className="rubik tw-list-disc tw-ml-3">
            Provide, operate, and maintain our Services;
          </li>
          <li className="rubik tw-list-disc tw-ml-3">
            Improve, personalize, and expand our Services;
          </li>
          <li className="rubik tw-list-disc tw-ml-3">
            Understand and analyze how you use our Services;
          </li>
          <li className="rubik tw-list-disc tw-ml-3">
            Develop new products, services, features, and functionality;
          </li>
          <li className="rubik tw-list-disc tw-ml-3">
            Communicate with you, either directly or through one of our
            partners, including for customer service, to provide you with
            updates and other information relating to the Service, and for
            marketing and promotional purposes;
          </li>
          <li className="rubik tw-list-disc tw-ml-3">
            Process your transactions;
          </li>
          <li className="rubik tw-list-disc tw-ml-3">
            Send you text messages and push notifications;
          </li>
          <li className="rubik tw-list-disc tw-ml-3">
            Find and prevent fraud; and
          </li>
          <li className="rubik tw-list-disc tw-ml-3">
            For compliance purposes, including enforcing our Terms of Service,
            or other legal rights, or as may be required by applicable laws and
            regulations or requested by any judicial process or governmental
            agency.
          </li>
        </ul>
        <p className="tw-text-bold tw-text-3xl tw-mb-2">
          3. How We Share Information
        </p>
        <p className="rubik tw-mb-2">
          We may share the information we collect in various ways, including the
          following:
        </p>
        <p className="tw-text-semibold rubik tw-mb-2">
          <span className="tw-font-semibold">
            1. Vendors and Service Providers.
          </span>
          We may share information with third-party vendors and service
          providers that provide services on our behalf, such as helping to
          provide our Services, for promotional and/or marketing purposes, and
          to provide you with information relevant to you such as product
          announcements, software updates, special offers, or other information.
        </p>
        <p className="tw-text-semibold rubik tw-mb-2">
          <span className="tw-font-semibold">2. Aggregate Information.</span>
          Where legally permissible, we may use and share information about
          users with our partners in aggregated or de-identified form that can’t
          reasonably be used to identify you.
        </p>
        <p className="tw-text-semibold rubik tw-mb-2">
          <span className="tw-font-semibold">3. Third-Party Partners.</span>
          We also share information about users with third-party partners in
          order to receive additional publicly available information about you.
        </p>
        <p className="tw-text-semibold rubik tw-mb-2">
          <span className="tw-font-semibold">
            4. Information We Share When You Sign Up Through a Referral.
          </span>
          If you sign up for our Services through a referral from a friend, we
          may share information with your referrer to let them know that you
          used their referral to sign up for our Services.
        </p>
        <p className="tw-text-semibold rubik tw-mb-2">
          <span className="tw-font-semibold">
            5. Analytics. We use analytics providers such as Google Analytics
          </span>
          Google Analytics uses cookies to collect non-identifying information.
          Google provides some additional privacy options regarding its
          Analytics cookies at
          <span className="tw-font-semibold">
            http://www.google.com/policies/privacy/partners/
          </span>
        </p>
        <p className="tw-text-semibold rubik tw-mb-2">
          <span className="tw-font-semibold">6. Business Transfers.</span>
          Information may be disclosed and otherwise transferred to any
          potential acquirer, successor, or assignee as part of any proposed
          merger, acquisition, debt financing, sale of assets, or similar
          transaction, or in the event of insolvency, bankruptcy, or
          receivership in which information is transferred to one or more third
          parties as one of our business assets.
        </p>
        <p className="tw-text-semibold rubik tw-mb-2">
          <span className="tw-font-semibold">
            7. As Required By Law and Similar Disclosures.
          </span>
          We may also share information to (i) satisfy any applicable law,
          regulation, legal process, or governmental request; (ii) enforce this
          Privacy Policy and our Terms of Service, including investigation of
          potential violations hereof; (iii) detect, prevent, or otherwise
          address fraud, security, or technical issues; (iv) respond to your
          requests; or (v) protect our rights, property or safety, our users and
          the public. This includes exchanging information with other companies
          and organizations for fraud protection and spam/malware prevention.
        </p>
        <p className="tw-text-semibold rubik tw-mb-4">
          <span className="tw-font-semibold">8. With Your Consent.</span>
          We may share information with your consent.
        </p>
        <p className="tw-text-bold tw-text-3xl tw-mb-2">
          4. Legal Basis for Processing Personal Information
        </p>
        <p className=" rubik tw-mb-2">
          Our legal basis for collecting and using the personal information
          described above will depend on the personal information concerned and
          the specific context in which we collect it.
        </p>
        <p className=" rubik tw-mb-2">
          However, we will normally collect personal information from you only
          (i) where we need the personal information to perform a contract with
          you; (ii) where the processing is in our legitimate interests and not
          overridden by your rights; or (iii) where we have your consent to do
          so. We have a legitimate interest in operating our Services and
          communicating with you as necessary to provide these Services, for
          example when responding to your queries, improving our platform,
          undertaking marketing, or for the purposes of detecting or preventing
          illegal activities.
        </p>
        <p className=" rubik tw-mb-2">
          In some cases, we may also have a legal obligation to collect personal
          information from you or may otherwise need the personal information to
          protect your vital interests or those of another person.
        </p>
        <p className=" rubik tw-mb-4">
          If we ask you to provide personal information to comply with a legal
          requirement or to perform a contract with you, we will make this clear
          at the relevant time and advise you whether the provision of your
          personal information is mandatory or not (as well as of the possible
          consequences if you do not provide your personal information).
        </p>
        <p className="tw-text-bold tw-text-3xl tw-mb-2">
          5. Third-party Services
        </p>
        <p className=" rubik tw-mb-4">
          You may access other third-party services through the Services, for
          example by clicking on links to those third-party services from within
          the Services. We are not responsible for the privacy policies and/or
          practices of these third-party services, and we encourage you to
          carefully review their privacy policies.
        </p>
        <p className="tw-text-bold tw-text-3xl tw-mb-2">6. Security</p>
        <p className=" rubik tw-mb-4">
          Jnana Software Technologies is committed to protecting your
          information. To do so, we employ a variety of security technologies
          and measures designed to protect information from unauthorized access,
          use, or disclosure. The measures we use are designed to provide a
          level of security appropriate to the risk of processing your personal
          information. However, please bear in mind that the Internet cannot be
          guaranteed to be 100% secure.
        </p>
        <p className="tw-text-bold tw-text-3xl tw-mb-2">7. Data Retention</p>
        <p className=" rubik tw-mb-2">
          We retain personal information we collect from you where we have an
          ongoing legitimate business need to do so (for example, to provide you
          with a service you have requested or to comply with applicable legal,
          tax, or accounting requirements).
        </p>
        <p className=" rubik tw-mb-4">
          When we have no ongoing legitimate business need to process your
          personal information, we will either delete or anonymize it or, if
          this is not possible (for example, because your personal information
          has been stored in backup archives), then we will securely store your
          personal information and isolate it from any further processing until
          deletion is possible.
        </p>
        <p className="tw-text-bold tw-text-3xl tw-mb-2">8. Access</p>
        <p className=" rubik tw-mb-2">
          If you are a registered user, you may access certain information
          associated with your Account by logging into our Services or emailing
          infosec@jnanasoft.com. If you terminate your Account, any public
          activity on your Account prior to deletion may remain stored on our
          servers and may remain accessible to the public.
        </p>
        <p className=" rubik tw-mb-4">
          To protect your privacy and security, we may also take reasonable
          steps to verify your identity before updating or removing your
          information. The information you provide us may be archived or stored
          periodically by us according to backup processes conducted in the
          ordinary course of business for disaster recovery purposes. Your
          ability to access and correct your information may be temporarily
          limited where access and correction could: inhibit Jnana software’s
          ability to comply with a legal obligation; inhibit Jnana Software’s
          ability to investigate, make or defend legal claims; result in
          disclosure of personal information about a third party; or result in
          breach of a contract or disclosure of trade secrets or other
          proprietary business information belonging to Jnana Software
          Technologies or a third party.
        </p>
        <p className="tw-text-bold tw-text-3xl tw-mb-2">
          9. Your Data Protection Rights under the General Data Protection
          Regulation (GDPR)
        </p>
        <p className=" tw-mb-2">
          If you are a resident of the EEA, you have the following data
          protection rights:
        </p>
        <ul className="tw-mb-4">
          <li className="rubik tw-list-disc tw-ml-3">
            If you wish to access, correct, update, or request deletion of your
            personal information, you can do so at any time by emailing
            infosec@Jnanasoft.com
          </li>
          <li className="rubik tw-list-disc tw-ml-3">
            In addition, you can object to the processing of your personal
            information, ask us to restrict the processing of your personal
            information, or request portability of your personal information.
            Again, you can exercise these rights by emailing
            infosec@Jnanasoft.com.
          </li>
          <li className="rubik tw-list-disc tw-ml-3">
            You have the right to opt-out of marketing communications we send
            you at any time. You can exercise this right by clicking on the
            "unsubscribe" or "opt-out" link in the marketing emails we send you.
            To opt-out of other forms of marketing, please contact us by
            emailing infosec@Jnanasoft.com.
          </li>
          <li className="rubik tw-list-disc tw-ml-3">
            Similarly, if we have collected and process your personal
            information with your consent, then you can withdraw your consent at
            any time. Withdrawing your consent will not affect the lawfulness of
            any processing we conducted prior to your withdrawal, nor will it
            affect the processing of your personal information conducted in
            reliance on lawful processing grounds other than consent.
          </li>
          <li className="rubik tw-list-disc tw-ml-3">
            You have the right to complain to a data protection authority about
            our collection and use of your personal information. For more
            information, please contact your local data protection authority.
          </li>
          <li className="rubik  tw-ml-3">
            We respond to all requests we receive from individuals wishing to
            exercise their data protection rights in accordance with applicable
            data protection laws.
          </li>
        </ul>
        <p className="tw-text-bold tw-text-3xl tw-mb-2">10. Your Choices</p>
        <p className=" rubik tw-mb-2">
          You can use some of the features of the Services without registering,
          thereby limiting the type of information that we collect.
        </p>
        <p className=" rubik tw-mb-2">
          You may unsubscribe from receiving certain promotional emails from us.
          If you wish to do so, simply follow the instructions found at the end
          of the email. Even if you unsubscribe, we may still contact you for
          informational, transactional, account-related, or similar purposes.
        </p>
        <p className=" rubik tw-mb-4">
          Many browsers have an option for disabling cookies, which may prevent
          your browser from accepting new cookies or enable selective use of
          cookies. Please note that, if you choose not to accept cookies, some
          features and the personalization of our Services may no longer work
          for you. You will continue to receive advertising material but it will
          not be tailored to your interests.
        </p>
        <p className="tw-text-bold tw-text-3xl tw-mb-2">
          11. Children's Privacy
        </p>
        <p className=" rubik tw-mb-4">
          Jnana Software Technologies does not knowingly collect information
          from children under the age of 13, and children under 13 are
          prohibited from using our Services. If you learn that a child has
          provided us with personal information in violation of this Privacy
          Policy, you can alert us at infosec@Jnanasoft.com.
        </p>
        <p className="tw-text-bold tw-text-3xl tw-mb-2">
          12. Changes to this Privacy Policy
        </p>
        <p className=" rubik tw-mb-6">
          This Privacy Policy may be modified from time to time, so please
          review it frequently. Changes to this Privacy Policy will be posted on
          our websites. If we materially change the ways in which we use or
          share personal information previously collected from you through our
          Services, we will notify you through our Services, by email, or other
          communication.
        </p>
        <p className="tw-text-bold tw-text-4xl tw-mb-2">Contact Us</p>
        <p className=" rubik tw-mb-2">
          If you have any questions or concerns about this Privacy Policy,
          please feel free to email us at{" "}
          <span className="tw-font-semibold">infosec@Jnanasoft.com</span>
        </p>
        <p className=" rubik tw-mb-2">
          The data controller of your personal information is Jnana Software
          Technologies Pvt Ltd.
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
