import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Discuss from "../Components/Discuss";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import logo from "../Images/logo.png";

const Homie = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Header />
      <div class="tw-hidden md:tw-block tw-container-2xl carouselContainer tw-h-5/6">
        <div
          id="carouselExampleControls"
          class="carousel slide"
          data-bs-ride="carousel"
        >
          <div class="carousel-inner">
            <div class="carousel-item active">
              <section className=" tw-h-screen tw-w-full ">
                <div className="bg-blueimage  tw-z-10">
                  <div className="tw-flex tw-mt-52">
                    <div className="tw-flex-auto tw-w-2/5 tw-ml-12 tw-pl-4.5 2xl:tw-mt-44 2xl:tw-ml-28">
                      <h4 className="txt-blue poppins tw-font-extrabold tw-text-6xl tracking-wide">
                        UI/UX
                      </h4>
                      <h4 className="txt-blue poppins tw-font-extrabold tw-text-6xl tracking-wide">
                        DESIGN
                      </h4>
                      <p className="tw-mt-8 poppins tw-text-zinc-500 tw-text-lg">
                        We Convert Your Imagination into Reality.
                      </p>
                      <button className="tw-text-lg tw-border-2 tw-border-zinc-600 tw-text-zinc-500 tw-rounded-full tw-py-4 tw-px-12 tw-mt-14 tw-cursor-pointer">
                        KNOW MORE
                      </button>
                    </div>
                    <div className="tw-flex-auto tw-w-3/5 tw-relative">
                      <div className="wings"></div>
                      {/* <div className="orange-circle"></div> */}
                      <div className="blue-circle"></div>
                      <div className="blue-half-circle"></div>
                      <div className="red-circle-bpiece"></div>
                      <div className="red-circle-spiece "></div>
                      <h4 className="tw-text-2xl tw-text-white poppins main-page-text">
                        Done. Delivered. Applaud
                      </h4>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <div class="carousel-item ">
              <section className=" tw-h-screen tw-w-full ">
                <div className=" homebanner2 tw-z-10">
                  <div className="tw-flex tw-mt-44">
                    <div className="tw-flex-auto tw-w-2/5 tw-ml-12 tw-pl-4.5 2xl:tw-mt-44 2xl:tw-ml-28">
                      <h4 className="txt-blue poppins tw-font-extrabold tw-text-5xl tracking-wide tw-mb-3">
                        INNOVATE{" "}
                        <span className="tw-text-yellow-200 rubik"> &gt; </span>
                      </h4>
                      <h4 className="txt-blue poppins tw-font-extrabold tw-text-5xl tracking-wide tw-mb-3">
                        TRANSFORM{" "}
                        <span className="tw-text-yellow-200 rubik"> &gt; </span>
                      </h4>
                      <h4 className="txt-blue poppins tw-font-extrabold tw-text-5xl tracking-wide tw-mb-3">
                        SUCCEED{" "}
                        <span className="tw-text-yellow-200 rubik"> &gt; </span>
                      </h4>
                      <p className="tw-mt-7 poppins tw-text-white tw-font-thin tw-text-lg">
                        We come highly commended and recommeded for the
                        solutions that we have consistently delivered accross
                        regions
                      </p>
                      <button className="tw-text-lg tw-border-2 tw-border-blue-900 tw-text-blue-900 tw-rounded-full tw-py-4 tw-px-12 tw-mt-10 tw-cursor-pointer">
                        KNOW MORE
                      </button>
                    </div>
                    <div className="tw-flex-auto tw-w-3/5 tw-relative">
                      <div className="bulb"></div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <div class="carousel-item ">
              <section className=" tw-h-screen tw-w-full ">
                <div className=" homebanner3 tw-z-10">
                  <div className="tw-flex tw-mt-44">
                    <div className="tw-flex-auto tw-w-2/5 tw-ml-12 tw-pl-4.5 2xl:tw-mt-44 2xl:tw-ml-28">
                      <h4 className="txt-blue poppins tw-font-extrabold tw-text-5xl tracking-wide tw-mb-3">
                        <NavLink to="/Itconsulting"> CONSULTING</NavLink>
                        <span className="tw-text-yellow-200 rubik"> &gt; </span>
                      </h4>
                      <h4 className="txt-blue poppins tw-font-extrabold tw-text-5xl tracking-wide tw-mb-3">
                        <NavLink to="/Itsolutions"> SOLUTIONS </NavLink>
                        <span className="tw-text-yellow-200 rubik"> &gt; </span>
                      </h4>
                      <h4 className="txt-blue poppins tw-font-extrabold tw-text-5xl tracking-wide tw-mb-3">
                        <NavLink to="/Itservices"> SERVICES </NavLink>
                        <span className="tw-text-yellow-200 rubik"> &gt; </span>
                      </h4>
                      <h4 className="txt-blue poppins tw-font-extrabold tw-text-5xl tracking-wide tw-mb-3">
                        <NavLink to="/Itsupport"> SUPPORT </NavLink>
                        <span className="tw-text-yellow-200 rubik"> &gt; </span>
                      </h4>
                      <p className="tw-mt-4 poppins tw-text-white tw-font-thin tw-text-lg">
                        We guide & manage bussiness transformation for clients
                        through a broads suits of the solution & services
                      </p>
                      <button className="tw-text-lg tw-border-2 tw-border-blue-900 tw-text-blue-900 tw-rounded-full tw-py-4 tw-px-12 tw-mt-6 tw-cursor-pointer ">
                        KNOW MORE
                      </button>
                    </div>
                    <div className="tw-flex-auto tw-w-3/5 tw-relative">
                      <div className="cube"></div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
          <button
            class="carousel-control-prev  tw-z-20"
            type="button"
            data-bs-target="#carouselExampleControls"
            data-bs-slide="prev"
          >
            <span class="carousel-control-prev-icon tw-self-end tw-mb-3"></span>
            {/* <span class="carousel-control-prev-icon" aria-hidden="true"></span> */}
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            class="carousel-control-next  tw-z-20"
            type="button"
            data-bs-target="#carouselExampleControls"
            data-bs-slide="next"
          >
            <span class="carousel-control-next-icon tw-self-end tw-mb-3"></span>
            {/* <span class="carousel-control-next-icon" aria-hidden="true"></span> */}
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>

      <section className="md:tw-hidden vh-85 tw-w-full ">
        <div className="bg-mobblueimage  tw-z-10">
          <div>
            <img
              src={logo}
              alt="Logo..."
              width="120"
              height="50"
              className="d-inline-block logoholder m-2 tw-z-40"
            />
          </div>
          <div className="tw-flex tw-mt-16">
            <div className="tw-flex-auto tw-w-1/5 tw-ml-3  tw-pl-4.5 2xl:tw-mt-44 ">
              <h4 className="txt-blue poppins tw-font-extrabold tw-text-2xl tracking-wide">
                UI/UX
              </h4>
              <h4 className="txt-blue poppins tw-font-extrabold tw-text-2xl tracking-wide">
                DESIGN
              </h4>
              <p className="tw-mt-4 poppins tw-text-zinc-500 tw-text-md">
                We Convert Your Imagination into Reality.
              </p>
              <button className="tw-text-sm tw-border tw-border-zinc-600 tw-text-zinc-500 tw-rounded-full tw-px-2 tw-py-1 tw-mt-8 tw-cursor-pointer">
                KNOW MORE
              </button>
            </div>
            <div className="tw-flex-auto tw-w-3/5 tw-relative">
              <div className="wings"></div>
              <div className="blue-circle"></div>
              <h4 className="tw-text-xl tw-text-white poppins main-page-text">
                Done. Delivered. Applaud
              </h4>
            </div>
          </div>
        </div>
      </section>
      {/* ############## Our Service ############ */}
      <div className="tw-hidden md:tw-block tw-flex-auto tw-z-20 main-neuron-filled" />
      <div
        className="tw-flex-auto tw-self-center tw-text-center md:tw-text-end tw-text-white tw-poppins textOurservices text-outlineB
       tw-font-extrabold tw-poppins tw-mt-0 md:tw-mt-6 tw-tracking-wide"
      >
        Our Services
      </div>
      <div className="blue-across-bg tw-relative">
        <p className="tw-hidden md:tw-block tw-poppins tw-font-semibold tw-m-auto tw-absolute p-text-left  tw-leading-relaxed tw-text-white">
          Jnana Software Technologies enbales and empowers Organisations to
          innovate, transform and succeed through IT Consulting, Solutions and
          Services.
        </p>

        <section class="tw-text-gray-600 tw-body-font">
          <div class="tw-container tw-px-5 tw-pt-0 md:tw-pt-28 tw-mx-auto heightcontainer">
            <div class="tw-flex tw-flex-wrap  tw-mt-12  ">
              <div class="tw-p-6 lg:tw-w-1/4 md:tw-w-1/2 tw-w-full ">
                <div class="tw-h-full tw-bg-opacity-75 tw-pt-6 tw-pb-10 gcardBorder tw-rounded-3xl tw-text-center tw-relative tw-transition tw-duration-500 phover  hover:tw-shadow-[6.0px_8.0px_18px_rgba(0,0,0,0.42)] tw-group/item">
                  <h1 class="tw-title-font tw-sm:text-2xl tw-text-2xl tw-font-medium tw-text-gray-700 tw-mb-2 tw-underline tw-underline-offset-8">
                    <NavLink to="/Itconsulting"> IT CONSULTING</NavLink>
                  </h1>
                  <h2 class="tw-tracking-widest tw-text-xs tw-title-font tw-font-medium tw-text-gray-700">
                    Innovate. Transform. Succeed.
                  </h2>
                  <div class=" bg-pink tw-py-1 tw-px-6 tw-rounded-3xl tw-w-auto tw-my-4 tw-h-72  group-hover/item:tw-shadow-none tw-shadow-[6.0px_8.0px_18px_rgba(0,0,0,0.42)]">
                    <div class="tw-mt-8">
                      {/* <p class="tw-text-xl tw-font-semibold tw-my-2">App Development</p> */}
                      <div class="tw-flex tw-space-x-2 tw-text-white tw-text-sm tw-border-l-2 tw-mb-2">
                        <p className=" tw-pl-4 tw-text-start">
                          Program Management
                        </p>
                      </div>
                      <div class="tw-flex tw-space-x-2 tw-text-white tw-text-sm tw-border-l-2 tw-mb-2">
                        <p className=" tw-pl-4 tw-text-start">
                          Business Transformation{" "}
                        </p>
                      </div>
                      <div class="tw-flex tw-space-x-2 tw-text-white tw-text-sm tw-border-l-2 tw-mb-2">
                        <p className=" tw-pl-4 tw-text-start">
                          Business Process Automation{" "}
                        </p>
                      </div>
                      <div class="tw-flex tw-space-x-2 tw-text-white tw-text-sm tw-border-l-2 tw-mb-2">
                        <p className=" tw-pl-4 tw-text-start">
                          Service Management{" "}
                        </p>
                      </div>
                      <div class="tw-flex tw-space-x-2 tw-text-white tw-text-sm tw-border-l-2 tw-mb-2">
                        <p className=" tw-pl-4 tw-text-start">
                          Quality Assurance{" "}
                        </p>
                      </div>
                      <div class="tw-flex tw-space-x-2 tw-text-white tw-text-sm tw-border-l-2 tw-mb-2">
                        <p className=" tw-pl-4 tw-text-start">
                          Governance, Risk & Complaince{" "}
                        </p>
                      </div>
                      <div class="tw-flex tw-space-x-2 tw-text-white tw-text-sm tw-border-l-2 tw-mb-2">
                        <p className=" tw-pl-4 tw-text-start ">
                          Information Strategy & Governance
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="tw-text-center tw-leading-none tw-flex tw-justify-start tw-absolute tw-bottom-0 tw-left-0 tw-w-full tw-py-4  tw-text-gray-700 tw-pl-4 tw-font-semibold tw-text-xl">
                    <NavLink to="/Itconsulting"> Know More</NavLink>
                  </div>
                </div>
              </div>
              <div class="tw-p-6 lg:tw-w-1/4 md:tw-w-1/2 tw-w-full ">
                <div class="tw-h-full tw-bg-opacity-75 tw-pt-6 tw-pb-10 gcardBorder tw-rounded-3xl tw-text-center tw-relative tw-transition tw-duration-500 bhover  hover:tw-shadow-[6.0px_8.0px_18px_rgba(0,0,0,0.42)] tw-group/item">
                  <h1 class="tw-title-font tw-sm:text-2xl tw-text-2xl tw-font-medium tw-text-gray-700 tw-mb-2 tw-underline tw-underline-offset-8">
                    <NavLink to="/Itsolutions"> IT SOLUTIONS</NavLink>
                  </h1>
                  <h2 class="tw-tracking-widest tw-text-xs tw-title-font tw-font-medium tw-text-gray-700">
                    Create. Collaborate. Lead.
                  </h2>
                  <div class=" bg-blue  tw-py-1 tw-px-6 tw-rounded-3xl tw-w-auto tw-my-4 tw-h-72 tw-shadow-[6.0px_8.0px_18px_rgba(0,0,0,0.42)] group-hover/item:tw-shadow-none">
                    <div class="tw-mt-8">
                      {/* <p class="tw-text-xl tw-font-semibold tw-my-2">App Development</p> */}
                      <div class="tw-flex tw-space-x-2 tw-text-white tw-text-sm tw-border-l-2 tw-mb-2">
                        <p className=" tw-pl-4 tw-text-start">
                          Enterprise Resource Planning (ERP)
                        </p>
                      </div>
                      <div class="tw-flex tw-space-x-2 tw-text-white tw-text-sm tw-border-l-2 tw-mb-2">
                        <p className=" tw-pl-4 tw-text-start">
                          Digital Commerce{" "}
                        </p>
                      </div>
                      <div class="tw-flex tw-space-x-2 tw-text-white tw-text-sm tw-border-l-2 tw-mb-2">
                        <p className=" tw-pl-4 tw-text-start">
                          User Experience Design{" "}
                        </p>
                      </div>
                      <div class="tw-flex tw-space-x-2 tw-text-white tw-text-sm tw-border-l-2 tw-mb-2">
                        <p className=" tw-pl-4 tw-text-start">Mobility </p>
                      </div>
                      <div class="tw-flex tw-space-x-2 tw-text-white tw-text-sm tw-border-l-2 tw-mb-2">
                        <p className=" tw-pl-4 tw-text-start">
                          Collaboration Services{" "}
                        </p>
                      </div>
                      <div class="tw-flex tw-space-x-2 tw-text-white tw-text-sm tw-border-l-2 tw-mb-2">
                        <p className=" tw-pl-4 tw-text-start">
                          Governance, Risk & Complaince{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="tw-text-center tw-leading-none tw-flex tw-justify-start tw-absolute tw-bottom-0 tw-left-0 tw-w-full tw-py-4  tw-text-gray-700 tw-pl-4 tw-font-semibold tw-text-xl">
                    <NavLink to="/Itsolutions"> Know More </NavLink>
                  </div>
                </div>
              </div>
              <div class="tw-p-6 lg:tw-w-1/4 md:tw-w-1/2 tw-w-full ">
                <div class="tw-h-full tw-bg-opacity-75 tw-pt-6 tw-pb-10 gcardBorder tw-rounded-3xl tw-text-center tw-relative tw-transition tw-duration-500 yhover  hover:tw-shadow-[6.0px_8.0px_18px_rgba(0,0,0,0.42)] tw-group/item">
                  <h1 class="tw-title-font tw-sm:text-2xl tw-text-2xl tw-font-medium tw-text-gray-700 tw-mb-2 tw-underline tw-underline-offset-8">
                    <NavLink to="/Itservices"> IT SERVICES</NavLink>
                  </h1>
                  <h2 class="tw-tracking-widest tw-text-xs tw-title-font tw-font-medium tw-text-gray-700">
                    Integrate. Consolidate. Transcend.
                  </h2>
                  <div class=" bg-yellow tw-py-1 tw-px-6 tw-rounded-3xl tw-w-auto tw-my-4 tw-h-72 tw-shadow-[6.0px_8.0px_18px_rgba(0,0,0,0.42)] group-hover/item:tw-shadow-none">
                    <div class="tw-mt-8">
                      {/* <p class="tw-text-xl tw-font-semibold tw-my-2">App Development</p> */}
                      <div class="tw-flex tw-space-x-2 tw-text-white tw-text-sm tw-border-l-2 tw-mb-2">
                        <p className=" tw-pl-4 tw-text-start">
                          Application Development
                        </p>
                      </div>
                      <div class="tw-flex tw-space-x-2 tw-text-white tw-text-sm tw-border-l-2 tw-mb-2">
                        <p className=" tw-pl-4 tw-text-start">
                          Product/Technology Evaluation{" "}
                        </p>
                      </div>
                      <div class="tw-flex tw-space-x-2 tw-text-white tw-text-sm tw-border-l-2 tw-mb-2">
                        <p className=" tw-pl-4 tw-text-start">
                          System Integration{" "}
                        </p>
                      </div>
                      <div class="tw-flex tw-space-x-2 tw-text-white tw-text-sm tw-border-l-2 tw-mb-2">
                        <p className=" tw-pl-4 tw-text-start">
                          API Integration{" "}
                        </p>
                      </div>
                      <div class="tw-flex tw-space-x-2 tw-text-white tw-text-sm tw-border-l-2 tw-mb-2">
                        <p className=" tw-pl-4 tw-text-start">
                          IT strategy & Road Map{" "}
                        </p>
                      </div>
                      <div class="tw-flex tw-space-x-2 tw-text-white tw-text-sm tw-border-l-2 tw-mb-2">
                        <p className=" tw-pl-4 tw-text-start">
                          Migration Porting{" "}
                        </p>
                      </div>
                      <div class="tw-flex tw-space-x-2 tw-text-white tw-text-sm tw-border-l-2 tw-mb-2">
                        <p className=" tw-pl-4 tw-text-start ">
                          Resource deployment
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="tw-text-center tw-leading-none tw-flex tw-justify-start tw-absolute tw-bottom-0 tw-left-0 tw-w-full tw-py-4  tw-text-gray-700 tw-pl-4 tw-font-semibold tw-text-xl">
                    <NavLink to="/Itservices"> Know More </NavLink>
                  </div>
                </div>
              </div>
              <div class="tw-p-6 lg:tw-w-1/4 md:tw-w-1/2 tw-w-full ">
                <div class="tw-h-full tw-bg-opacity-75 tw-pt-6 tw-pb-10 gcardBorder tw-rounded-3xl tw-text-center tw-relative tw-transition tw-duration-500 puhover  hover:tw-shadow-[6.0px_8.0px_18px_rgba(0,0,0,0.42)] tw-group/item">
                  <h1 class="tw-title-font tw-sm:text-2xl tw-text-2xl tw-font-medium tw-text-gray-700 tw-mb-2 tw-underline tw-underline-offset-8">
                    <NavLink to="/Itsupport"> IT SUPPORT</NavLink>
                  </h1>
                  <h2 class="tw-tracking-widest tw-text-xs tw-title-font tw-font-medium tw-text-gray-700">
                    Create. Collaborate. Lead.
                  </h2>
                  <div class=" bg-purple tw-py-1 tw-px-6 tw-rounded-3xl tw-w-auto tw-my-4 tw-h-72 tw-shadow-[6.0px_8.0px_18px_rgba(0,0,0,0.42)] group-hover/item:tw-shadow-none">
                    <div class="tw-mt-8">
                      {/* <p class="tw-text-xl tw-font-semibold tw-my-2">App Development</p> */}
                      <div class="tw-flex tw-space-x-2 tw-text-white tw-text-sm tw-border-l-2 tw-mb-2">
                        <p className=" tw-pl-4 tw-text-start">Cloud Hosting </p>
                      </div>
                      <div class="tw-flex tw-space-x-2 tw-text-white tw-text-sm tw-border-l-2 tw-mb-2">
                        <p className=" tw-pl-4 tw-text-start">
                          Infrastcructure management{" "}
                        </p>
                      </div>
                      <div class="tw-flex tw-space-x-2 tw-text-white tw-text-sm tw-border-l-2 tw-mb-2">
                        <p className=" tw-pl-4 tw-text-start">
                          Real-time Application Monitoring{" "}
                        </p>
                      </div>
                      <div class="tw-flex tw-space-x-2 tw-text-white tw-text-sm tw-border-l-2 tw-mb-2">
                        <p className=" tw-pl-4 tw-text-start">
                          Production Environment Support
                        </p>
                      </div>
                      <div class="tw-flex tw-space-x-2 tw-text-white tw-text-sm tw-border-l-2 tw-mb-2">
                        <p className=" tw-pl-4 tw-text-start">
                          Quality Assurance{" "}
                        </p>
                      </div>
                      <div class="tw-flex tw-space-x-2 tw-text-white tw-text-sm tw-border-l-2 tw-mb-2">
                        <p className=" tw-pl-4 tw-text-start">
                          Tiered Application support services (L1/L2/L3)
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="tw-text-center tw-leading-none tw-flex tw-justify-start tw-absolute tw-bottom-0 tw-left-0 tw-w-full tw-py-4  tw-text-gray-900 tw-pl-4 tw-font-semibold tw-text-xl">
                    <NavLink to="/Itsupport"> Know More </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/* ############ About Us ################ */}
      <div className=" tw-container-2xl tw-h-auto tw-bg-gradient-to-r tw-from-sky-300 tw-to-cyan-200 borderRightCurve tw-relative">
        <div className="tw-hidden md:tw-block tw-flex-auto tw-z-20 main-neuron-empty" />
        <div className="tw-flex-auto tw-self-center tw-text-center md:tw-text-start tw-text-sky-300 poppins textOurservices text-outlineB tw-font-bold tw:mb-8 md:tw-mb-14">
          About US
        </div>
        <div className="tw-flex-auto tw-self-center tw-full md:tw-w-4/6 tw-p-2 md:tw-pl-40">
          <div className="tw-flex-auto tw-self-center tw-text-center tw-text-white poppins tw-text-3xl tw-font-semibold tw-mb-14">
            Jnana Software Technologies
          </div>
          <p className=" tw-mb-8">
            A 100% Indian IT firm, Jnana Software Technologies Pvt. Ltd. seeks
            to harness the world’s best ICT knowledge and resources to delivery
            world class technology solutions to help our clients to achieve
            their business goals.
          </p>
          <p className=" tw-pb-16">
            We are strategic provider of IT consulting, solutions and services.
            Our information technology services empower clients to achieve their
            organizational goals. We guide and manage business transformation
            for our clients through a broad suite of solutions and services. We
            use the latest technologies and learnings from around the world to
            enhance the value of our advice and services.
          </p>
        </div>
      </div>

      {/* ############## Partnership ##########  */}

      <div class="tw-container tw-px-5 tw-mx-auto">
        <div class="tw-flex tw-flex-wrap tw-mt-6 md:tw-mx-36">
          <div class="tw-p-6 lg:tw-w-1/3 md:tw-w-1/3 tw-w-full ">
            <div class="tw-h-full tw-bg-opacity-75 cardBorder tw-rounded-3xl tw-text-center tw-relative scalehover  tw-transition-all">
              <div className=" tw-flex tw-justify-start tw-ml-8">
                <span className=" tw-text-pink-500 tw-text-8xl tw-font-bold">
                  10
                </span>
                <span className="tw-text-6xl tw-font-bold">+</span>
              </div>
              <div className="tw-text-3xl  tw-flex tw-justify-start tw-font-semibold tw-ml-8">
                In-House
              </div>
              <div className=" tw-flex tw-justify-center tw-mx-4 tw-font-semibold tw-mt-12 tw-mb-10">
                Technology Leader and Domain Experts
              </div>
            </div>
          </div>
          <div class="tw-p-6 lg:tw-w-1/3 md:tw-w-1/3 tw-w-full ">
            <div class="tw-h-full tw-bg-opacity-75 cardBorder tw-rounded-3xl tw-text-center tw-relative scalehover tw-transition-all tw-transition-all">
              <div className=" tw-flex tw-justify-start tw-ml-8">
                <span className=" tw-text-pink-500 tw-text-8xl tw-font-bold">
                  20
                </span>
                <span className="tw-text-6xl tw-font-bold">+</span>
              </div>
              <div className="tw-text-3xl  tw-flex tw-justify-start tw-font-semibold tw-ml-8">
                Partners
              </div>
              <div className=" tw-flex tw-justify-center tw-mx-4 tw-font-semibold tw-mt-12 tw-mb-10">
                Technology Architects, Subject MatterExperts and Support
                Specialists
              </div>
            </div>
          </div>
          <div class="tw-p-6 lg:tw-w-1/3 md:tw-w-1/3 tw-w-full ">
            <div class="tw-h-full tw-bg-opacity-75 cardBorder tw-rounded-3xl tw-text-center tw-relative scalehover tw-transition-all ">
              <div className=" tw-flex tw-justify-start tw-ml-8">
                <span className=" tw-text-pink-500 tw-text-8xl tw-font-bold">
                  30
                </span>
                <span className="tw-text-6xl tw-font-bold">+</span>
              </div>
              <div className="tw-text-3xl  tw-flex tw-justify-start tw-font-semibold tw-ml-2">
                Vendors & OEM’S
              </div>
              <div className=" tw-flex tw-justify-center tw-mx-4 tw-font-semibold tw-mt-12 tw-mb-10">
                Product Specialist and Deployment Experts
              </div>
            </div>
          </div>
          <div className="tw-flex justify-content-center tw-w-full">
            <button className="tw-text-xl tw-border-2 tw-border-zinc-600 tw-text-zinc-500 tw-rounded-full tw-py-4 tw-px-12 tw-my-14 tw-cursor-pointer">
              KNOW MORE
            </button>
          </div>
        </div>
      </div>

      <div
        className="tw-p-2 tw-flex-auto tw-self-center tw-text-center md:tw-text-start tw-text-yellow-300 tw-poppins tw-text-5xl md:tw-text-7xl tw-bg-yellow-300 text-outlineB
       tw-font-extrabold tw-poppins tw-tracking-wide"
      >
        Our Recent Work
      </div>
      <div className="tw-flex tw-flex-wrap tw-w-full tw-h-screen tw-overflow-hidden">
        <div className="  tw-flex-auto tw-flex tw-flex-col tw-full  md:tw-w-8/12">
          <div className="tw-flex-auto tw-flex tw-justify-end tw-text-end tw-items-end ERPimg tw-overflow-hidden">
            <span className="tw-text-slate-100 tw-text-4xl md:tw-text-6xl tw-font-bold  tw-p-4 ">
              {" "}
              ERP
            </span>
          </div>
          <div
            className="tw-flex-auto tw-flex tw-justify-center tw-text-end tw-items-end airbookingimg tw-cursor-pointer"
            onClick={() => navigate("Traveltechnology")}
          >
            <span className="tw-text-slate-100 tw-text-4xl md:tw-text-6xl tw-font-bold  tw-p-8 ">
              {" "}
              TRAVEL SYSTEM
            </span>
          </div>
        </div>
        <div className="tw-flex-auto tw-full  md:tw-w-4/12 tw-justify-end tw-flex tw-flex-col mobileimg ">
          <span className="tw-text-slate-100 tw-text-4xl md:tw-text-6xl tw-font-bold tw-px-4 tw-py-1">
            {" "}
            MOBILE
          </span>
          <span className="tw-text-slate-100 tw-text-4xl md:tw-text-6xl tw-font-bold tw-pb-6 tw-px-4">
            {" "}
            APP
          </span>
        </div>
      </div>

      <div className="tw-flex justify-content-center tw-w-full tw-bg-yellow-300">
        <button className="tw-text-xl tw-border-2 tw-border-zinc-600 tw-text-zinc-500 tw-rounded-full tw-py-1 md:tw-py-4 tw-px-12 tw-my-8 md:tw-my-16 tw-cursor-pointer ">
          KNOW MORE
        </button>
      </div>
      {/* <div className="tw-bg-stone-300">
        <div className="tw-flex-auto tw-self-center tw-text-center md:tw-text-start tw-text-stone-300 tw-poppins tw-text-5xl md:tw-text-7xl text-outlineB tw-font-extrabold tw-poppins tw-tracking-wide">
          Lets Discuss Your Project
        </div>

        <div class="tw-flex tw-flex-wrap tw-mt-8 tw-mx-8">
          <div class="tw-p-4 lg:tw-w-1/3 md:tw-w-1/3 tw-w-full">
            <input
              type="email"
              id="helper-text"
              aria-describedby="helper-text-explanation"
              class="tw-bg-stone-300 tw-border tw-border-stone-500 tw-text-gray-900 tw-text-sm focus:tw-ring-stone-500 focus:tw-border-stone-700 tw-block tw-w-full tw-p-2.5  dark:tw-bg-stone-300 dark:tw-border-stone-500 dark:tw-placeholder-gray-500 dark:tw-text-gray-900 dark:focus:tw-ring-stone-500 dark:focus:tw-border-stone-700"
              placeholder="Name"
            />
          </div>
          <div class="tw-p-4 lg:tw-w-1/3 md:tw-w-1/3 tw-w-full">
            <input
              type="email"
              id="helper-text"
              aria-describedby="helper-text-explanation"
              class="tw-bg-stone-300 tw-border tw-border-stone-500 tw-text-gray-900 tw-text-sm focus:tw-ring-stone-500 focus:tw-border-stone-700 tw-block tw-w-full tw-p-2.5  dark:tw-bg-stone-300 dark:tw-border-stone-500 dark:tw-placeholder-gray-500 dark:tw-text-gray-900 dark:focus:tw-ring-stone-500 dark:focus:tw-border-stone-700"
              placeholder="Email"
            />
          </div>
          <div class="tw-p-4 lg:tw-w-1/3 md:tw-w-1/3 tw-w-full">
            <input
              type="email"
              id="helper-text"
              aria-describedby="helper-text-explanation"
              class="tw-bg-stone-300 tw-border tw-border-stone-500 tw-text-gray-900 tw-text-sm focus:tw-ring-stone-500 focus:tw-border-stone-700 tw-block tw-w-full tw-p-2.5  dark:tw-bg-stone-300 dark:tw-border-stone-500 dark:tw-placeholder-gray-500 dark:tw-text-gray-900 dark:focus:tw-ring-stone-500 dark:focus:tw-border-stone-700"
              placeholder="Mobile"
            />
          </div>
        </div>

        <div class="tw-flex tw-flex-wrap tw-mt-2 tw-mx-8">
          <div class="tw-p-4 tw-w-full">
            <textarea
              id="message"
              rows="4"
              class=" tw-bg-stone-300 tw-border tw-border-stone-500 tw-text-gray-900 tw-text-sm focus:tw-ring-stone-500 focus:tw-border-stone-700 tw-block tw-w-full tw-p-2.5  dark:tw-bg-stone-300 dark:tw-border-stone-500 dark:tw-placeholder-gray-500 dark:tw-text-gray-900 dark:focus:tw-ring-stone-500 dark:focus:tw-border-stone-700"
              placeholder="Write your thoughts here..."
            ></textarea>
          </div>
        </div>

        <div className="tw-flex justify-content-center tw-w-full tw-bg-stone-300">
          <button className="tw:text-sm md:tw-text-xl tw-border-2 tw-border-zinc-600 tw-text-zinc-500 tw-rounded-full tw-py-2 md:tw-py-4 tw-px-6 md:tw-px-12 tw-mt-4 tw-mb-12 tw-cursor-pointer ">
            Send Your Message
          </button>
        </div>
      </div> */}

      <Discuss />
      <Footer />
    </div>
  );
};

export default Homie;
