import React from "react";
import Aboutmeeting from "../Components/Aboutmeeting";
import Discuss from "../Components/Discuss";
import Footer from "../Components/Footer";
import Header from "../Components/Header";

const AboutUs = () => {
  return (
    <div className="tw-overflow-hidden">
      <Header />
      <div className="bgaboutus tw-flex tw-flex-wrap md:tw-flex-nowrap">
        <div className="tw-flex-auto tw-w-full md:tw-w-5/12 tw-mt-24 md:tw-mt-48 md:tw-ml-12 tw-ml-3">
          <h4 className="txt-blue poppins tw-font-extrabold tw-text-3xl md:tw-text-6xl tracking-wide">
            ABOUT US
          </h4>
          <h5 className="tw-text-slate-100 poppins tw-text-md md:tw-text-2xl tw-font-normal tw-mt-5 ">
            WE ARE YOUNG AND ENERGETIC TEAM.
          </h5>
        </div>
        <div className="tw-flex-auto tw-w-full md:tw-w-7/12 boxemployees"></div>
      </div>
      <div className="tw-flex tw-flex-col tw-container-2xl h1000 tw-mt-8 ">
        <div className="tw-flex-auto tw-relative ml-35 ">
          <span className="aboutusneuron tw-z-0"></span>

          <div className="tw-flex tw-flex-col ">
            <span className="tw-text-xl md:tw-text-5xl tw-text-slate-600 tw-font-bold tw-z-10 tw-mb-8 md:tw-mb-16">
              About Jnana Software Technologies
            </span>
            <span className="tw-text-base md:tw-text-xl tw-text-slate-500 tw-z-10 tw-mb-4">
              A 100% Indian IT firm, Jnana Software Technologies Pvt. Ltd. seeks
              to harness the world’s best ICT knowledge and resources to
              delivery world class technology solutions to help our clients to
              achieve their business goals.
            </span>
            <span className="tw-text-base md:tw-text-xl tw-text-slate-500 tw-z-10 tw-mb-4">
              Our information technology services empower clients to achieve
              their organizational goals. We guide and manage business
              transformation for our clients through a broad suite of solutions
              and services. We use the latest technologies and learnings from
              around the world to enhance the value of our advice and services.
            </span>
            <span className="tw-text-base md:tw-text-xl tw-text-slate-500 tw-z-10 tw-mb-4">
              Our information technology services empower clients to achieve
              their organizational goals. We guide and manage business
              transformation for our clients through a broad suite of solutions
              and services. We use the latest technologies and learnings from
              around the world to enhance the value of our advice and services.
            </span>
          </div>
        </div>
        <div className="tw-flex tw-flex-wrap tw-flex-auto tw-justify-center  tw-mt-8 tw-mx-4 md:tw-mx-36">
          <div class="tw-p-6 lg:tw-w-2/6 md:tw-w-3/6 tw-w-full ">
            <div class=" tw-bg-opacity-75 tw-pt-6 tw-pb-10 gcardBorder tw-rounded-3xl tw-text-center tw-relative tw-transition tw-duration-500 phover hover:tw-shadow-[6.0px_8.0px_18px_rgba(0,0,0,0.42)] tw-group/item">
              <h1 class="tw-title-font tw-sm:text-2xl tw-text-2xl tw-font-medium tw-text-gray-700 tw-mb-2 ">
                VISION
              </h1>
              <div class=" bg-pink tw-py-1 tw-px-6 tw-rounded-3xl tw-w-auto tw-my-4 tw-h-72 tw-shadow-[6.0px_8.0px_18px_rgba(0,0,0,0.42)] group-hover/item:tw-shadow-none">
                <div class="tw-mt-8">
                  {/* <p class="tw-text-xl tw-font-semibold tw-my-2">App Development</p> */}
                  <div class="tw-flex tw-space-x-2 tw-text-slate-50 tw-text-xl  tw-mb-2">
                    <p className=" tw-pl-4 tw-text-start">
                      Our vision is to be a globally respected corporate that
                      provides business solutions leveraging best-in-class
                      technology and delivered by best-of-breed people.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tw-p-6 lg:tw-w-2/6 md:tw-w-3/6 tw-w-full ">
            <div class=" tw-bg-opacity-75 tw-pt-6 tw-pb-10 gcardBorder tw-rounded-3xl tw-text-center tw-relative tw-transition tw-duration-500 bhover hover:tw-shadow-[6.0px_8.0px_18px_rgba(0,0,0,0.42)] tw-group/item">
              <h1 class="tw-title-font tw-sm:text-2xl tw-text-2xl tw-font-medium tw-text-gray-700 tw-mb-2 ">
                MISSION
              </h1>
              <div class=" bg-blue tw-py-1 tw-px-6 tw-rounded-3xl tw-w-auto tw-my-4 tw-h-72 tw-shadow-[6.0px_8.0px_18px_rgba(0,0,0,0.42)] group-hover/item:tw-shadow-none">
                <div class="tw-mt-8">
                  {/* <p class="tw-text-xl tw-font-semibold tw-my-2">App Development</p> */}
                  <div class="tw-flex tw-space-x-2 tw-text-slate-50 tw-text-xl  tw-mb-2">
                    <p className=" tw-pl-4 tw-text-start">
                      Our mission is to achieve our objectives in an environment
                      of fairness, honesty, and courtesy towards our clients,
                      employees, vendors and society at large.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tw-p-6 lg:tw-w-2/6 md:tw-w-3/6 tw-w-full ">
            <div class=" tw-bg-opacity-75 tw-pt-6 tw-pb-10 gcardBorder tw-rounded-3xl tw-text-center tw-relative tw-transition tw-duration-500 yhover hover:tw-shadow-[6.0px_8.0px_18px_rgba(0,0,0,0.42)] tw-group/item">
              <h1 class="tw-title-font tw-sm:text-2xl tw-text-2xl tw-font-medium tw-text-gray-700 tw-mb-2 ">
                VALUES
              </h1>

              <div class=" bg-yellow tw-py-1 tw-px-6 tw-rounded-3xl tw-w-auto tw-my-4 tw-h-72 tw-shadow-[6.0px_8.0px_18px_rgba(0,0,0,0.42)] group-hover/item:tw-shadow-none">
                <div class="tw-mt-12">
                  {/* <p class="tw-text-xl tw-font-semibold tw-my-2">App Development</p> */}
                  <div class="tw-flex tw-space-x-2 tw-text-white tw-text-2xl tw-mb-1">
                    <p className=" tw-pl-4 tw-text-start">Innovation,</p>
                  </div>
                  <div class="tw-flex tw-space-x-2 tw-text-white tw-text-2xl tw-mb-1">
                    <p className=" tw-pl-4 tw-text-start">Integrity,</p>
                  </div>
                  <div class="tw-flex tw-space-x-2 tw-text-white tw-text-2xl tw-mb-1">
                    <p className=" tw-pl-4 tw-text-start">Transparency,</p>
                  </div>
                  <div class="tw-flex tw-space-x-2 tw-text-white tw-text-2xl tw-mb-1">
                    <p className=" tw-pl-4 tw-text-start">Collaboration </p>
                  </div>
                  <div class="tw-flex tw-space-x-2 tw-text-white tw-text-2xl tw-mb-1">
                    <p className=" tw-pl-4 tw-text-start">and Excellence.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="tw-text-2xl md:tw-text-5xl tw-font-bold tw-ml-8 tw-mb-10">
        BUSINESS OBJECTIVE{" "}
      </div>
      <div className="tw-flex tw-flex-wrap md:tw-flex-nowrap">
        <div className="tw-flex-auto tw-w-8/12 md:tw-w-7/12 tw-mx-2 md:tw-ml-5 tw-bg-slate-300 tw-rounded-t-3xl">
          <div className="tw-flex tw-flex-col tw-p-16">
            <p className="tw-text-slate-700 tw-text-lg md:tw-text-2xl tw-mb-4 md:tw-mb-12">
              <span className="tw-text-black tw-font-semibold tw-text-xl md:tw-text-3xl ">
                Jnana Software Technologies
              </span>{" "}
              Main business objective is to focus on customer satisfaction,
              weather its clients or our employees. We belive in providing best
              ever solutions to our customers and build sustainability growth
              plan to help grow businesses.
            </p>
            <p className="tw-text-slate-700 tw-text-lg md:tw-text-2xl tw-mb-4 md:tw-mb-12">
              Our four main business objectives are as follows.
            </p>

            <p className="tw-text-slate-700 tw-text-lg md:tw-text-2xl tw-mb-2 md:tw-mb-4 tw-font-semibold">
              1. Innovation
            </p>
            <p className="tw-text-slate-700 tw-text-lg md:tw-text-2xl tw-mb-2 md:tw-mb-4 tw-font-semibold">
              2. Customer Focused
            </p>
            <p className="tw-text-slate-700 tw-text-lg md:tw-text-2xl tw-mb-2 md:tw-mb-4 tw-font-semibold">
              3. Best in CLass Service
            </p>
            <p className="tw-text-slate-700 tw-text-lg md:tw-text-2xl tw-mb-2 md:tw-mb-4 tw-font-semibold">
              4. Global Presence
            </p>
          </div>
        </div>
        <div className="tw-flex-auto tw-w-4/12 md:tw-w-5/12 diagonalsquare"></div>
      </div>
          
      <Aboutmeeting/>
      <Discuss/>
      <Footer />
    </div>
  );
};

export default AboutUs;
