import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";

const DarkTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-input": {
    color: theme.palette.common.white, // Change text color to white
  },
  "& .MuiInputLabel-root": {
    color: theme.palette.common.white, // Change label color to white
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: theme.palette.divider, // Keep other borders dark (e.g., grey)
      borderBottomColor: theme.palette.common.white, // Change only the bottom border color to white
    },
    "&:hover fieldset": {
      borderColor: theme.palette.divider, // Keep other borders dark (e.g., grey) on hover
      borderBottomColor: theme.palette.common.white, // Change only the bottom border color to white on hover
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.divider, // Keep other borders dark (e.g., grey) when focused
      borderBottomColor: theme.palette.common.white, // Change only the bottom border color to white when focused
    },
    "&:before": {
      borderBottomColor: theme.palette.common.white, // Change input:before border-bottom color to white
    },
  },
}));

export default DarkTextField;
