import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import logo from "../Images/logo.png";
import whitelogo from "../Images/JnanaWhiteLogo.png";
// import mobileLogo from '../Images/TranseMobile.png'

const Header = () => {
  const [collapsed, setcollapsed] = useState(true);
  const [showservices, setShowservices] = useState(false);
  const toggleButton = () => {
    setcollapsed((prev) => !prev);
  };
  const location = useLocation();
  useEffect(() => {
    if (collapsed) {
      document.body.style.overflow = "unset";
      document.body.style.paddingRight = "0px";
    }
  }, []);

  const dynamiclogo = () => {
    if (location.pathname === "/Itservices") {
      return (
        <img
          src={whitelogo}
          alt="Logo..."
          width="260"
          height="70"
          className="d-inline-block d-none d-sm-block logoholder m-3"
        />
      );
    } else if (location.pathname === "/Itsolutions") {
      return (
        <img
          src={whitelogo}
          alt="Logo..."
          width="260"
          height="70"
          className="d-inline-block d-none d-sm-block logoholder m-3"
        />
      );
    } else if (location.pathname === "/Itconsulting") {
      return (
        <img
          src={whitelogo}
          alt="Logo..."
          width="260"
          height="70"
          className="d-inline-block d-none d-sm-block logoholder m-3"
        />
      );
    } else if (location.pathname === "/Itsupport") {
      return (
        <img
          src={whitelogo}
          alt="Logo..."
          width="260"
          height="70"
          className="d-inline-block d-none d-sm-block logoholder m-3"
        />
      );
    } else if (location.pathname === "/Careers") {
      return (
        <img
          src={whitelogo}
          alt="Logo..."
          width="260"
          height="70"
          className="d-inline-block d-none d-sm-block logoholder m-3"
        />
      );
    } else if (location.pathname === "/Traveltechnology") {
      return (
        <img
          src={whitelogo}
          alt="Logo..."
          width="260"
          height="70"
          className="d-inline-block d-none d-sm-block logoholder m-3"
        />
      );
    } else {
      return (
        <img
          src={logo}
          alt="Logo..."
          width="260"
          height="70"
          className="d-inline-block d-none d-sm-block logoholder m-3"
        />
      );
    }
  };

  return (
    <>
      <div className="navbar p-0">
        <div className="container-fluid position-absolute top-50">
          <NavLink
            as="button"
            className="nav-link m-2 p-0 MobileHeader logo tw-z-20"
            aria-current="page"
            to="/"
          >
            {dynamiclogo}

            {/* <img
                src={mobileLogo}
                alt="Logo..."
                width="200"
                height="60"
                className="d-block d-sm-none small logoholder m-3 "
              /> */}
          </NavLink>

          <button
            className={`navbar-toggler ${
              !collapsed && "collapsed"
            } d-flex flex-column justify-content-around`}
            onClick={toggleButton}
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasTop"
            aria-controls="offcanvasTop"
          >
            <span
              className={`toggler-icon ${
                collapsed && "collapsed tw-mb-10"
              } top-bar`}
            />
            <span
              className={`toggler-icon  ${
                collapsed && "collapsed tw-mb-5 tw-mt-5"
              } middle-bar`}
            />
            <span
              className={`toggler-icon  ${
                collapsed && "collapsed mt-4"
              } bottom-bar`}
            />
          </button>
          {/* <button type="button" className="" data-bs-dismiss="offcanvas" aria-label="Close">
    </button> */}

          <div
            className="offcanvas offcanvas-top topHeaderbar"
            tabIndex="-1"
            id="offcanvasTop"
            aria-labelledby="offcanvasTopLabel"
          >
            <div className="offcanvas-header">
              {/* <h5 className="offcanvas-title" id="offcanvasTopLabel">Offcanvas top</h5> */}
            </div>
            <div className="offcanvas-body">
              <div className="tw-hidden md:tw-block collapseneuron"></div>
              <div className="d-flex justify-content-center text-center menuStyle">
                <ul>
                  <li>
                    <NavLink className="linkStyles" to="/">
                      HOME
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className="linkStyles"
                      onClick={() => setShowservices(!showservices)}
                    >
                      + SERVICES
                    </NavLink>
                  </li>
                  <li className={`${showservices ? "" : "tw-hidden"}`}>
                    <NavLink className="linkStyles tw-pl-3" to="/Itconsulting">
                      IT CONSULTING
                    </NavLink>
                  </li>
                  <li className={`${showservices ? "" : "tw-hidden"}`}>
                    <NavLink className="linkStyles tw-pl-3" to="/Itsolutions">
                      IT SOLUTIONS
                    </NavLink>
                  </li>
                  <li className={`${showservices ? "" : "tw-hidden"}`}>
                    <NavLink className="linkStyles tw-pl-3" to="/Itservices">
                      IT SERVICES
                    </NavLink>
                  </li>
                  <li className={`${showservices ? "" : "tw-hidden"}`}>
                    <NavLink className="linkStyles tw-pl-3" to="/Itsupport">
                      IT SUPPORT
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="linkStyles" to="/Aboutus">
                      ABOUT US
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="linkStyles" to="/Careers">
                      CAREERS
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="linkStyles" to="/Ourwork">
                      OUR WORK
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="linkStyles" to="/Traveltechnology">
                      TRAVEL TECHNOLOGY
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="linkStyles" to="/">
                      CONTACT US
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
