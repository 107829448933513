import React from "react";
import Header from "../Components/Header";

const Home = () => {
  return (
    <div className="element tw-container:xl">
      <div className=" tw-container tw-h-screen">
      <Header />
      <div className="bg-blueparent tw-w-screen">
        <div className="bg-blueimage" />
      </div>
      <div className="tw-flex tw-mt-52">
        <div className="tw-flex-auto tw-w-2/5 tw-ml-12 tw-pl-1.5">
          <h4 className="txt-blue poppins tw-font-extrabold tw-text-6xl tracking-wide">
            UI/UX
          </h4>
          <h4 className="txt-blue poppins tw-font-extrabold tw-text-6xl tracking-wide">
            DESIGN
          </h4>
          <p className="tw-mt-8 poppins tw-text-zinc-500 tw-text-lg">
            We Convert Your Imagination into Reality.
          </p>
          <button className="tw-text-1xl tw-border-2 tw-border-zinc-600 tw-text-zinc-500 tw-rounded-full tw-py-4 tw-px-12 tw-mt-14 tw-cursor-pointer">
            KNOW MORE
          </button>
        </div>
        <div className="tw-flex-auto tw-w-3/5 tw-relative">
          <div className="wings"></div>
          <div className="orange-circle"></div>
          <div className="blue-circle"></div>
          <div className="blue-half-circle"></div>
          <div className="red-circle-bpiece"></div>
          <div className="red-circle-spiece "></div>
          <h4 className="tw-text-2xl tw-text-white poppins main-page-text">
            Done. Delivered. Applaud
          </h4>
        </div>
      </div>
      </div>
      <div className=" tw-container tw-h-screen">
      {/* <div className="tw-flex tw-h-60 tw-relative">
        <div className="tw-flex-auto tw-w-3/5 tw-self-center tw-text-end tw-text-white poppins tw-text-8xl text-outline tw-font-bold tw-mt-28  ">
        Our Services
        </div>
      </div> */}
      <div className="tw-flex-auto  main-neuron-filled"></div>
      <div className="tw-flex-auto tw-self-center tw-text-end tw-text-white poppins tw-text-8xl text-outline tw-font-bold tw-mt-6  ">
          Our Services
        </div>
      <div className="blue-across-bg tw-relative">
        <p className=" poppins tw-m-auto tw-absolute p-text-left txt-brightblue tw-leading-relaxed">
          Jnana Software Technologies enbales and empowers Organisations to
          innovate, transform and succeed through IT Consulting, Solutions and
          Services.
        </p>
        <div class="tw-grid tw-grid-cols-4 tw-gap-4 tw-pt-52 tw-mr-24 tw-ml-12">
    <div class=" tw-mb-2 tw-rounded-3xl tw-border-2 tw-border-black">
      
        <div class=" tw-py-4">
            <h4 class="tw-mb-3 tw-text-xl tw-font-semibold tw-tracking-tight tw-text-gray-800">This is the title</h4>
            <div class="tw-bg-neutral-500 tw-py-6 tw-px-6 tw-rounded-3xl tw-w-68 tw-my-4 tw-shadow-xl">
           
            <div class="tw-mt-8">
                <p class="tw-text-xl tw-font-semibold tw-my-2">App Development</p>
                <div class="tw-flex tw-space-x-2 tw-text-gray-400 tw-text-sm">
                   
                     <p>Marketing Team</p> 
                </div>
                <div class="tw-flex tw-space-x-2 tw-text-gray-400 tw-text-sm tw-my-3">
                   
                     <p>1 Weeks Left</p> 
                </div>
                <div class="tw-border-t-2"></div>

                <div class="tw-flex tw-justify-between">
                    <div class="tw-my-2">
                        <p class="tw-font-semibold tw-text-base tw-mb-2">Team Member</p>
                        <div class="flex space-x-2">
                           
                        </div>
                    </div>
                     <div class="tw-my-2">
                        <p class="tw-font-semibold tw-text-base tw-mb-2">Progress</p>
                        <div class="tw-text-base tw-text-gray-400 tw-font-semibold">
                            <p>34%</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
            <p class="tw-leading-normal tw-text-gray-700">Know More</p>
        </div>
        </div>
    <div class=" tw-mb-2 tw-rounded-3xl tw-border-2 tw-border-black">
       
        <div class=" tw-py-4">
            <h4 class="tw-mb-3 tw-text-xl tw-font-semibold tw-tracking-tight tw-text-gray-800">This is the title</h4>
            <div class="tw-bg-neutral-500 tw-bg-red tw-py-6 tw-px-6 tw-rounded-3xl tw-w-68 tw-my-4 tw-shadow-xl">
           
            <div class="tw-mt-8">
                <p class="tw-text-xl tw-font-semibold tw-my-2">App Development</p>
                <div class="tw-flex tw-space-x-2 tw-text-gray-400 tw-text-sm">
                   
                     <p>Marketing Team</p> 
                </div>
                <div class="tw-flex tw-space-x-2 tw-text-gray-400 tw-text-sm tw-my-3">
                   
                     <p>1 Weeks Left</p> 
                </div>
                <div class="tw-border-t-2"></div>

                <div class="tw-flex tw-justify-between">
                    <div class="tw-my-2">
                        <p class="tw-font-semibold tw-text-base tw-mb-2">Team Member</p>
                        <div class="flex space-x-2">
                           
                        </div>
                    </div>
                     <div class="tw-my-2">
                        <p class="tw-font-semibold tw-text-base tw-mb-2">Progress</p>
                        <div class="tw-text-base tw-text-gray-400 tw-font-semibold">
                            <p>34%</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
            <p class="tw-leading-normal tw-text-gray-700">Know More</p>
        </div>
        </div>
    <div class=" tw-mb-2 tw-rounded-3xl tw-border-2 tw-border-black">
       
        <div class="tw-py-4">
            <h4 class="tw-mb-3 tw-text-xl tw-font-semibold tw-tracking-tight tw-text-gray-800">This is the title</h4>
            <div class="tw-bg-neutral-500 tw-py-6 tw-px-6 tw-rounded-3xl tw-w-68 tw-my-4 tw-shadow-xl">
           
            <div class="tw-mt-8">
                <p class="tw-text-xl tw-font-semibold tw-my-2">App Development</p>
                <div class="tw-flex tw-space-x-2 tw-text-gray-400 tw-text-sm">
                   
                     <p>Marketing Team</p> 
                </div>
                <div class="tw-flex tw-space-x-2 tw-text-gray-400 tw-text-sm tw-my-3">
                   
                     <p>1 Weeks Left</p> 
                </div>
                <div class="tw-border-t-2"></div>

                <div class="tw-flex tw-justify-between">
                    <div class="tw-my-2">
                        <p class="tw-font-semibold tw-text-base tw-mb-2">Team Member</p>
                        <div class="flex space-x-2">
                           
                        </div>
                    </div>
                     <div class="tw-my-2">
                        <p class="tw-font-semibold tw-text-base tw-mb-2">Progress</p>
                        <div class="tw-text-base tw-text-gray-400 tw-font-semibold">
                            <p>34%</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
            <p class="tw-leading-normal tw-text-gray-700">Know More</p>
        </div>
        </div>
    <div class=" tw-mb-2 tw-rounded-3xl tw-border-2 tw-border-black">
        
        <div class=" tw-py-4">
            <h4 class="tw-mb-3 tw-text-xl tw-font-semibold tw-tracking-tight tw-text-gray-800">This is the title</h4>
            <div class="tw-bg-neutral-500 tw-py-6 tw-px-6 tw-rounded-3xl tw-w-68 tw-my-4 tw-shadow-xl">
           
            <div class="tw-mt-8">
                <p class="tw-text-xl tw-font-semibold tw-my-2">App Development</p>
                <div class="tw-flex tw-space-x-2 tw-text-gray-400 tw-text-sm">
                   
                     <p>Marketing Team</p> 
                </div>
                <div class="tw-flex tw-space-x-2 tw-text-gray-400 tw-text-sm tw-my-3">
                   
                     <p>1 Weeks Left</p> 
                </div>
                <div class="tw-border-t-2"></div>

                <div class="tw-flex tw-justify-between">
                    <div class="tw-my-2">
                        <p class="tw-font-semibold tw-text-base tw-mb-2">Team Member</p>
                        <div class="flex space-x-2">
                           
                        </div>
                    </div>
                     <div class="tw-my-2">
                        <p class="tw-font-semibold tw-text-base tw-mb-2">Progress</p>
                        <div class="tw-text-base tw-text-gray-400 tw-font-semibold">
                            <p>34%</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
            <p class="tw-leading-normal tw-text-gray-700">Know More</p>
        </div>
        </div>
   
        </div>


{/* <div class="tw-flex tw-pt-52">
      <div class="tw-w-1/4">
      <div class="tw-flex tw-justify-center tw-px-10">
  <div class="tw-block tw-p-6 tw-rounded-lg tw-shadow-lg tw-bg-white tw-max-w-sm">
    <h5 class="tw-text-gray-900 tw-text-xl tw-leading-tight tw-font-medium tw-mb-2">Card title</h5>
    <p class="tw-text-gray-700 tw-text-base tw-mb-4">
      Some quick example text to build on the card title and make up the bulk of the card's
      content.
    </p>
    <button type="button" class=" tw-inline-block tw-px-6 tw-py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">Button</button>
  </div>
</div>
      </div>
      <div class="tw-w-1/4 ...">w-1/4</div>
      <div class="tw-w-1/4 ...">w-1/4</div>
      <div class="tw-w-1/4 ...">w-1/4</div>
    </div> */}



      </div>

      </div>
    </div>
  );
};

export default Home;
